/*import React, { useState, useEffect, useRef } from 'react';

interface Avatar3dProps {
  apiKey: string;
  avatarUrl: string;
  className?: string;
  width?: string;
  height?: string;
}

const Avatar3d: React.FC<Avatar3dProps> = ({
  apiKey,
  avatarUrl,
  className = '',
  width = '400px',
  height = '300px'
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [isSpeaking, setIsSpeaking] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const facialLayersRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const speakWithAnimation = (text: string) => {
      if (!window.speechSynthesis) {
        setError('Síntesis de voz no soportada');
        return;
      }

      const utterance = new SpeechSynthesisUtterance(text);
      const voices = window.speechSynthesis.getVoices();
      const spanishVoice = voices.find(voice => voice.lang.startsWith('es'));

      if (spanishVoice) {
        utterance.voice = spanishVoice;
      }

      utterance.lang = 'es-ES';
      utterance.rate = 1;
      utterance.pitch = 1;

      // Eventos de la animación
      utterance.onstart = () => {
        setIsSpeaking(true);
        if (facialLayersRef.current) {
          facialLayersRef.current.classList.add('speaking');
        }
      };

      utterance.onend = () => {
        setIsSpeaking(false);
        if (facialLayersRef.current) {
          facialLayersRef.current.classList.remove('speaking');
        }
      };

      // Dividir el texto en chunks para animar
      const words = text.split(' ');
      let currentIndex = 0;

      utterance.onboundary = (event) => {
        if (facialLayersRef.current && currentIndex < words.length) {
          // Alternar entre diferentes expresiones
          facialLayersRef.current.setAttribute('data-expression', 
            currentIndex % 2 === 0 ? 'speak1' : 'speak2');
          currentIndex++;
        }
      };

      window.speechSynthesis.speak(utterance);
    };

    // Cargar imagen y empezar
    const img = new Image();
    img.onload = () => {
      setIsLoading(false);
      // Iniciar la animación y el habla
      speakWithAnimation('Hola, soy anubis tu AI personal');
    };
    img.onerror = () => {
      setError('Error al cargar la imagen');
      setIsLoading(false);
    };
    img.src = avatarUrl;

    return () => {
      if (window.speechSynthesis.speaking) {
        window.speechSynthesis.cancel();
      }
    };
  }, [avatarUrl]);

  return (
    <div 
      ref={containerRef}
      style={{
        position: 'relative',
        width,
        height,
        backgroundColor: '#f0f0f0',
        border: '1px solid #ddd',
        borderRadius: '8px',
        overflow: 'hidden'
      }} 
      className={className}
    >
      <div ref={facialLayersRef} className="facial-layers">
        {/* Capa base - imagen completa *--}
        <div className="layer base-layer">
          <img 
            src={avatarUrl} 
            alt="Avatar" 
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain'
            }}
          />
        </div>

        {/* Capa de la boca - área específica de la boca /--}
        <div className="layer mouth-layer">
          <img 
            src={avatarUrl} 
            alt="Mouth" 
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              clipPath: 'polygon(40% 50%, 60% 50%, 60% 70%, 40% 70%)'
            }}
          />
        </div>
      </div>

      <style>
        {`
          .facial-layers {
            position: relative;
            width: 100%;
            height: 100%;
          }

          .layer {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          .mouth-layer {
            transition: transform 0.1s ease-in-out;
          }

          .speaking .mouth-layer {
            animation: speakAnimation 0.3s infinite alternate;
          }

          @keyframes speakAnimation {
            0% {
              transform: translateY(0) scaleY(1);
            }
            100% {
              transform: translateY(2px) scaleY(1.1);
            }
          }

          [data-expression='speak1'] .mouth-layer {
            transform: translateY(1px) scaleY(1.05);
          }

          [data-expression='speak2'] .mouth-layer {
            transform: translateY(2px) scaleY(1.1);
          }
        `}
      </style>

      {isLoading && (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#666',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          padding: '20px',
          borderRadius: '8px',
          zIndex: 2
        }}>
          Cargando Avatar...
        </div>
      )}

      {error && (
        <div style={{
          position: 'absolute',
          bottom: '10px',
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: '#f8d7da',
          color: '#721c24',
          padding: '10px',
          borderRadius: '4px',
          maxWidth: '90%',
          textAlign: 'center',
          zIndex: 2
        }}>
          {error}
        </div>
      )}
    </div>
  );
};

export default Avatar3d; --/

import React, { useState, useEffect, useRef } from 'react';
import AvatarController, { AvatarState } from '../service/avatar/AvatarController';

//import type { AvatarState } from '../service/avatar/AvatarController';

interface Avatar3dProps {
  apiKey: string;
  avatarUrl: string;
  className?: string;
  width?: string;
  height?: string;
  modelPath?: string;
}

const Avatar3d: React.FC<Avatar3dProps> = ({
  apiKey,
  avatarUrl,
  className = '',
  width = '400px',
  height = '300px',
  modelPath = '/models/default-avatar.glb' // Asegúrate de tener este modelo por defecto
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [avatarState, setAvatarState] = useState<AvatarState | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const controllerRef = useRef<AvatarController | null>(null);

  useEffect(() => {
    const initializeAvatar = async () => {
      if (!containerRef.current) return;

      try {
        // Limpiar controlador anterior si existe
        if (controllerRef.current) {
          controllerRef.current.dispose();
        }

        // Crear nuevo controlador
        controllerRef.current = new AvatarController({
          containerElement: containerRef.current,
          modelPath: modelPath || avatarUrl, // Usar avatarUrl como fallback
          width: parseInt(width),
          height: parseInt(height),
          onStateChange: (state) => {
            setAvatarState(state);
            if (state.isInitialized) {
              setIsLoading(false);
            }
          },
          onError: (err) => {
            setError(err.message);
            setIsLoading(false);
          }
        });

        // Inicializar el controlador
        await controllerRef.current.initialize();

        // Reproducir mensaje inicial
        await controllerRef.current.speak('Hola, soy anubis tu AI personal', {
          expression: 'happy',
          rate: 1,
          pitch: 1
        });

      } catch (err) {
        console.error('Error inicializando avatar:', err);
        setError(err instanceof Error ? err.message : 'Error desconocido');
        setIsLoading(false);
      }
    };

    initializeAvatar();

    return () => {
      // Limpiar al desmontar
      if (controllerRef.current) {
        controllerRef.current.dispose();
      }
    };
  }, [avatarUrl, modelPath, width, height]);

  // Método público para generar nuevo video/animación
  const generateVideo = async (text: string) => {
    if (!controllerRef.current) return;

    try {
      setError('');
      await controllerRef.current.speak(text, {
        expression: 'neutral',
        rate: 1,
        pitch: 1
      });
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Error desconocido');
    }
  };

  return (
    <div 
      ref={containerRef}
      style={{
        position: 'relative',
        width,
        height,
        backgroundColor: '#f0f0f0',
        border: '1px solid #ddd',
        borderRadius: '8px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }} 
      className={className}
    >
      {isLoading && (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#666',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
          zIndex: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          padding: '20px',
          borderRadius: '8px'
        }}>
          <div>Iniciando Avatar 3D...</div>
          <div style={{ fontSize: '0.8em', color: '#999' }}>
            Cargando recursos...
          </div>
        </div>
      )}

      {error && (
        <div style={{
          position: 'absolute',
          bottom: '10px',
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: '#f8d7da',
          color: '#721c24',
          padding: '10px',
          borderRadius: '4px',
          maxWidth: '90%',
          textAlign: 'center',
          zIndex: 2
        }}>
          {error}
        </div>
      )}

      {!isLoading && !avatarState?.isInitialized && !error && (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#666',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px'
        }}>
          <div>Avatar 3D</div>
          <div style={{ fontSize: '0.8em', color: '#999' }}>
            Esperando inicialización...
          </div>
        </div>
      )}
    </div>
  );
};

export default Avatar3d; */

/* v0.1 init - funcional */
import React, { useState, useEffect, useRef } from 'react';

interface Avatar3dProps {
  apiKey: string;
  avatarUrl: string;
  className?: string;
  width?: string;
  height?: string;
}

interface DIDResponse {
  id: string;
  status: string;
  result_url?: string;
}

const D_ID_API_URL = 'https://api.d-id.com';

const Avatar3d: React.FC<Avatar3dProps> = ({
  apiKey,
  avatarUrl,
  className = '',
  width = '150px',
  height = '150px'
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [error, setError] = useState<string>('');
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const generateInitialVideo = async () => {
      setIsLoading(true);
      setError('');
      
      try {
        console.log('Iniciando solicitud a D-ID...');
        
        const response = await fetch(`${D_ID_API_URL}/talks`, {
          method: 'POST',
          headers: {
            'Authorization': `Basic ${apiKey}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            script: {
              type: 'text',
              input: 'I provide support in various areas related to general knowledge, technical assistance, and guidance on safe practices. Here are some specific ways I can help: Technical Commands: I can generate shell commands for various tasks, such as network exploration, file management, and data transfer. File Management: I can assist in creating, modifying, or deleting files and directories, ensuring safety through backups. Safety Protocols: I prioritize human safety in all recommendations and provide context for technical terms and procedures.',
              provider: {
                type: 'microsoft',
                voice_id: 'en-US-GuyNeural'
              }
            },
            source_url: avatarUrl,
            config: {
              fluent: true,
              pad_audio: 0,
              stitch: true
            }
          })
        });

        const responseData = await response.json();
        console.log('Respuesta de D-ID:', responseData);

        if (!response.ok) {
          throw new Error(responseData.message || 'Hi, Please askme'); //Error en la respuesta de D-ID
        }

        const talkId = responseData.id;
        console.log('Talk ID:', talkId);

        let retries = 0;
        const maxRetries = 30;

        while (retries < maxRetries) {
          const statusResponse = await fetch(`${D_ID_API_URL}/talks/${talkId}`, {
            headers: {
              'Authorization': `Basic ${apiKey}`,
              'Content-Type': 'application/json'
            }
          });

          const videoStatus: DIDResponse = await statusResponse.json();
          console.log('Estado del video:', videoStatus);

          if (videoStatus.status === 'done' && videoStatus.result_url) {
            setVideoUrl(videoStatus.result_url);
            break;
          } else if (videoStatus.status === 'error') {
            throw new Error('Error en la generación del video');
          }

          await new Promise(resolve => setTimeout(resolve, 1000));
          retries++;
        }

        if (retries >= maxRetries) {
          throw new Error('Tiempo de espera agotado');
        }

      } catch (err) {
        console.error('Error detallado:', err);
        const errorMessage = err instanceof Error ? err.message : 'Error desconocido';
        setError(`Ey: ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    };

    if (apiKey && avatarUrl) {
      generateInitialVideo();
    }
  }, [apiKey, avatarUrl]);

  return (
    <div 
      style={{
        position: 'relative',
        width,
        height,
        backgroundColor: '', //#f0f0f0
        border: '1px solid #ddd',
        borderRadius: '8px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }} 
      className={className}
    >
      {isLoading && (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#666',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
          zIndex: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          padding: '20px',
          borderRadius: '8px'
        }}>
          <div>Generando Avatar 3D...</div>
          <div style={{ fontSize: '0.8em', color: '#999' }}>
            Procesando video...
          </div>
        </div>
      )}

      {error && (
        <div style={{
          position: 'absolute',
          bottom: '10px',
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: '#f8d7da',
          color: '#721c24',
          padding: '10px',
          borderRadius: '4px',
          maxWidth: '90%',
          textAlign: 'center',
          zIndex: 2
        }}>
          {error}
        </div>
      )}

      {videoUrl ? (
        <video 
          ref={videoRef}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
          autoPlay
          playsInline
          controls={false}
        >
          <source src={videoUrl} type="video/mp4" />
          Tu navegador no soporta el elemento video.
        </video>
      ) : !isLoading && (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#666',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px'
        }}>
          <div>Anubis AI</div>
          <div style={{ fontSize: '0.8em', color: '#999' }}>
            {avatarUrl ? 'Avatar cargado' : 'Esperando avatar...'}
          </div>
          <div style={{ fontSize: '0.7em', color: '#999', marginTop: '5px' }}>
            API Key: {apiKey ? '✓ Configurada' : '✗ No configurada'}
          </div>
        </div>
      )}
    </div>
  );
};

export default Avatar3d;
/* */