import { useState } from 'react';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import behance from 'assets/img/nav-icons/behance.webp';
import googleCloud from 'assets/img/nav-icons/google-cloud.webp';
import slack from 'assets/img/nav-icons/slack.webp';
import gitlab from 'assets/img/nav-icons/gitlab.webp';
import bitbucket from 'assets/img/nav-icons/bitbucket.webp';
import googleDrive from 'assets/img/nav-icons/google-drive.webp';
import trello from 'assets/img/nav-icons/trello.webp';
import figma from 'assets/img/nav-icons/figma.webp';
import twitter from 'assets/img/nav-icons/twitter.webp';
import pinterest from 'assets/img/nav-icons/pinterest.webp';
import ln from 'assets/img/nav-icons/ln.webp';
import googleMaps from 'assets/img/nav-icons/google-maps.webp';
import googlePhotos from 'assets/img/nav-icons/google-photos.webp';
import spotify from 'assets/img/nav-icons/spotify.webp';
import xgis from 'assets/img/Anubis/xgis.png'
import sentinel from 'assets/img/Anubis/sentinel.png'
import whatsapp from 'assets/img/Anubis/whatsapp.png'
import hazbot from 'assets/img/Anubis/Hazball.png'
import aidetect from 'assets/img/Anubis/AIDetect.png'

import Scrollbar from 'components/base/Scrollbar';
import Badge from 'components/base/Badge';
import { FeatherIcon, Star } from 'lucide-react';

type BadgeType = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info';

interface NineDotMenuProps {
  onSelect?: () => void;
}

interface BadgeConfig {
  type: BadgeType;
  text: string;
}

interface MenuItem {
  img: string;
  title: string;
  width: string;
  disable: boolean;
  link: string;
  status: string;
  target?: string;
  badge?: BadgeConfig;
}

const NineDotMenu: React.FC<NineDotMenuProps> = ({ onSelect }) => {
  const [items] = useState<MenuItem[]>([
      { img: xgis, title: 'xGisMaps', width: '30', disable: true, link: '#', status: 'grayscale(100%)', badge: { type: 'info', text: 'soon' }},
      { img: sentinel, title: 'Sentinel', width: '30', disable: true, link: '#', status: 'grayscale(100%)', badge: { type: 'info', text: 'soon' }}, //badge: { type: 'success', text: 'ready' }},
      { img: whatsapp, title: 'WhatsApp', width: '30', disable: true, link: '#', status: 'grayscale(100%)', badge: { type: 'info', text: 'soon' }}, //badge: { type: 'warning', text: 'offline' }},
      { img: hazbot, title: 'Hazbot', width: '30', disable: true, link: '#', status: 'grayscale(100%)', badge: { type: 'info', text: 'soon' }}, //badge: { type: 'danger', text: 'error' }},
      { img: aidetect, title: 'AIDetect', width: '30', disable: false, link: 'aidetect', status: 'grayscale(0%)', badge: { type: 'warning', text: 'new' }, target: '_self'},
    /*{ img: googleCloud, title: 'Cloud' },
    { img: slack, title: 'Slack' },
    { img: gitlab, title: 'Gitlab' },
    { img: bitbucket, title: 'BitBucket' },
    { img: googleDrive, title: 'Drive' },
    { img: trello, title: 'Trello' },
    { img: figma, title: 'Figma', width: '20' },
    { img: twitter, title: 'Twitter' },
    { img: pinterest, title: 'Pinterest' },
    { img: ln, title: 'Linkedin' },
    { img: googleMaps, title: 'Maps' },
    { img: googlePhotos, title: 'Photos' },
    { img: spotify, title: 'Spotify' }*/
  ]);
  return (
    <Dropdown.Menu
      align="end"
      className="navbar-dropdown-caret py-0 dropdown-nine-dots shadow border"
    >
      <Card
        className="position-relative border-0"
        style={{ height: '20rem', minWidth: 244 }}
      >
        <Scrollbar>
          <Card.Body className="pt-3 px-3 pb-0">
            <Row className="text-center align-items-center g-0">
              {items.map(item => (
              <Col xs={4} key={item.title}>
                <Link
                  to={item.link}
                  target={item.target}
                  className="d-block bg-body-secondary-hover p-2 rounded-3 text-center text-decoration-none mb-3"
                  onClick={() => onSelect && onSelect()}
                >
                  <div className="relative top-0 right-0" style={{ transform: 'rotate(20deg)' }}>
                    {item.badge && (
                      <Badge
                        bg={item.badge.type}
                        variant="phoenix"
                        iconPosition="end"
                        className="fs-12"
                        icon={<Star size={10} className="ms-1" />}
                      >
                        {item.badge.text}
                      </Badge>
                    )}
                  </div>
                  <img
                    className="fixed"
                    src={item.img}
                    alt={item.title}
                    width={item.width || 30}
                    style={{ filter: item.status}}
                  />
                  <p className="mb-0 text-body-emphasis text-truncate fs-10 mt-1 pt-1">
                    {item.title}
                  </p>
                </Link>
              </Col>
              ))}
            </Row>
          </Card.Body>
        </Scrollbar>
      </Card>
    </Dropdown.Menu>
  );
};

export default NineDotMenu;