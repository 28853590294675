import WidgetsSectionTitle from '../widgets/WidgetsSectionTitle';
import { faPercentage } from '@fortawesome/free-solid-svg-icons';
import AnalyticsStats from 'components/stats/AnalyticsStats';
import { stats } from 'data/crm/stats';
import { Col, Row } from 'react-bootstrap';
import EcomTotalOrdersCard from 'components/cards/EcomTotalOrdersCard';
import EcomNewCustomersCard from 'components/cards/EcomNewCustomersCard';
import EcomTopCouponsCard from 'components/cards/EcomTopCouponsCard';
import EcomPayingVsNonPayingCard from 'components/cards/EcomPayingVsNonPayingCard';
import NewUsersAndLeads from '../crm/NewUsersAndLeads';
import ContactsBySource from '../crm/ContactsBySource';
import EcomProjectionVsActual from '../e-commerce/dashboard/EcomProjectionVsActual';
import EcomReturningCustomerRate from '../e-commerce/dashboard/EcomReturningCustomerRate';
import EcomTotalSells from '../e-commerce/dashboard/EcomTotalSells';
import ZeroRoadMap from '../project-management/dashboard/ZeroRoadMap';
import IssuesDiscovered from '../project-management/dashboard/IssuesDiscovered';
import ProjectElevenProgress from '../project-management/dashboard/ProjectElevenProgress';
import LeadConversion from '../crm/LeadConversion';
import RevenueTarget from '../crm/RevenueTarget';
import EmailCampaign from '../crm/analytics/NeuroStatsReport';
import MarketingCampaign from '../crm/analytics/NeuroStatsPerUser';
import SalesTrends from '../crm/analytics/SalesTrends';
import CallCampaignReport from '../crm/analytics/CallCampaignReport';

const WidgetStats = () => {
  return (
    <div>
      <WidgetsSectionTitle
        title="NeuroSkills"
        subtitle="General NeuroSkills Statistics."
        icon={faPercentage}
        className="my-5"
      />
      <div className="">
        <Row className="gx-6">
          <Col
            md={6}
            lg={12}
            xl={6}
            className="mb-5 mb-md-3 mb-lg-5 mb-xl-2 mb-xxl-3"
          >
            <EmailCampaign />
          </Col>
          <Col md={6} lg={12} xl={6} className="mb-1 mb-sm-0">
            <MarketingCampaign />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default WidgetStats;
