/* ShellCommand v1.2 - timeout: 120 secs, check nmap p.e: nmap --host-timeout 95s --max-retries 1 --script-timeout 90s */
import { useState, useRef, useEffect } from 'react';
import { Card, CardHeader, CardTitle } from 'react-bootstrap';
import { ShellService } from '../service/shellService';
import { useUserContext } from './UserContext';

interface ShellInput {
  command: string;
}

interface ShellOutput {
  response: string;
}

interface User {
  username: string;
}

interface UserContextType {
  user: User | null;
  isLoading: boolean;
}

const ShellCommand: React.FC = () => {
  const { user, isLoading } = useUserContext() as UserContextType;
  const [command, setCommand] = useState<string>('');
  const [outputs, setOutputs] = useState<string[]>([]);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [commandHistory, setCommandHistory] = useState<string[]>([]);
  const [historyIndex, setHistoryIndex] = useState<number>(-1);
  const terminalRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const commandTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [outputs]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    return () => {
      if (commandTimeoutRef.current) {
        clearTimeout(commandTimeoutRef.current);
      }
    };
  }, []);

  if (isLoading || !user) {
    return (
      <Card className="w-100">
        <CardHeader>
          <CardTitle>Command console</CardTitle>
        </CardHeader>
        <div className="p-4">
          <div className="text-center">Loading...</div>
        </div>
      </Card>
    );
  }

  const allowedUsernames: string[] = ['doremysweet830', 'demoanubis788'];
  if (!allowedUsernames.includes(user.username)) {
    return (
      <Card className="w-100">
        <CardHeader>
          <CardTitle>Command console</CardTitle>
        </CardHeader>
        <div className="p-4">
          <div className="text-center">You haven't perms to use command console.</div>
        </div>
      </Card>
    );
  }

  const handleInternalCommands = (cmd: string): boolean => {
    switch(cmd.toLowerCase()) {
      case 'clear':
        setOutputs([]);
        return true;
      case 'help':
        setOutputs(prev => [...prev, 
          'Available commands:',
          '  clear   - Clear terminal',
          '  help    - Show this help',
          '  history - Show command history'
        ]);
        return true;
      case 'history':
        setOutputs(prev => [...prev, 
          'Command history:',
          ...commandHistory.map((cmd, i) => `${i + 1}  ${cmd}`)
        ]);
        return true;
      default:
        return false;
    }
  };

  const sendCommand = async (cmd: string): Promise<void> => {
    if (!cmd.trim()) return;
    
    if (commandTimeoutRef.current) {
      clearTimeout(commandTimeoutRef.current);
    }

    try {
      setIsSending(true);
      setError(null);

      if (handleInternalCommands(cmd)) {
        setCommandHistory(prev => [...prev, cmd]);
        setCommand('');
        setIsSending(false);
        return;
      }

      setOutputs(prev => [...prev, `AI@${process.env.REACT_APP_NAME}:~$ ${cmd}`]);
      
      const timeoutPromise = new Promise((_, reject) => {
        commandTimeoutRef.current = setTimeout(() => {
          reject(new Error('Command timed out after 120 seconds'));
        }, 240000);
      });

      try {
        const shellInput: ShellInput = { command: cmd };
        const shellOutput: ShellOutput = await Promise.race([
          ShellService.sendCommand(shellInput),
          timeoutPromise
        ]) as ShellOutput;

        if (commandTimeoutRef.current) {
          clearTimeout(commandTimeoutRef.current);
        }

        setOutputs(prev => [...prev, shellOutput.response]);
        setCommandHistory(prev => [...prev, cmd]);
        setCommand('');
        setHistoryIndex(-1);
      } catch (error) {
        if (error instanceof Error && error.message.includes('timed out')) {
          setOutputs(prev => [...prev, 'Error: Command execution timed out (120s limit)']);
          setError('Command execution timed out');
        } else {
          throw error;
        }
      }
    } catch (err: unknown) {
      const errorMessage = err instanceof Error ? err.message : 'Unknown error';
      setError(errorMessage);
      setOutputs(prev => [...prev, `Error: ${errorMessage}`]);
    } finally {
      setIsSending(false);
      if (commandTimeoutRef.current) {
        clearTimeout(commandTimeoutRef.current);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!isSending) {
        sendCommand(command);
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (historyIndex < commandHistory.length - 1) {
        const newIndex = historyIndex + 1;
        setHistoryIndex(newIndex);
        setCommand(commandHistory[commandHistory.length - 1 - newIndex]);
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (historyIndex > -1) {
        const newIndex = historyIndex - 1;
        setHistoryIndex(newIndex);
        setCommand(newIndex < 0 ? '' : commandHistory[commandHistory.length - 1 - newIndex]);
      }
    }
  };

  return (
    <Card className="w-100">
      <CardHeader>
        <CardTitle>Command console</CardTitle>
      </CardHeader>
      <div className="p-4">
        <div
          ref={terminalRef}
          className="p-3 bg-dark rounded font-monospace"
          style={{
            minHeight: '300px',
            maxHeight: '330px',
            overflowY: 'auto',
            position: 'relative'
          }}
          onClick={() => inputRef.current?.focus()}
        >
          <style>
            {`
              @keyframes blink {
                50% { opacity: 0; }
              }
            `}
          </style>
          
          {outputs.map((output, index) => (
            <div 
              key={index}
              className={output.startsWith('AI@') ? 'text-success' : 'text-light'}
              style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
            >
              {output}
            </div>
          ))}
          
          {!isSending && (
            <div className="d-flex align-items-center">
              <span className="text-success">AI@{process.env.REACT_APP_NAME}:~$</span>
              <div className="position-relative flex-grow-1">
                <input
                  ref={inputRef}
                  type="text"
                  value={command}
                  onChange={(e) => setCommand(e.target.value)}
                  onKeyDown={handleKeyDown}
                  disabled={isSending}
                  className="bg-transparent border-0 text-light w-100 ms-2 font-monospace"
                  style={{ 
                    outline: 'none',
                    color: 'white'
                  }}
                  autoFocus
                />
                <span 
                  className="position-absolute top-0 font-monospace"
                  style={{
                    left: `${(command.length * 8) + 16}px`,
                    animation: 'blink 1s step-end infinite',
                    pointerEvents: 'none',
                    color: 'white',
                    marginLeft: '4px'
                  }}
                >
                  ▋
                </span>
              </div>
            </div>
          )}
          
          {error && (
            <div className="text-danger mt-2">
              {error}
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ShellCommand;

/* ShellCommand v1.1 
import { useState, useRef, useEffect } from 'react';
import { Card, CardHeader, CardTitle } from 'react-bootstrap';
import { ShellService } from '../service/shellService';
import { useUserContext } from './UserContext';

interface ShellInput {
  command: string;
}

interface ShellOutput {
  response: string;
}

interface User {
  username: string;
}

interface UserContextType {
  user: User | null;
  isLoading: boolean;
}

const ShellCommand: React.FC = () => {
  const { user, isLoading } = useUserContext() as UserContextType;
  const [command, setCommand] = useState<string>('');
  const [outputs, setOutputs] = useState<string[]>([]);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [commandHistory, setCommandHistory] = useState<string[]>([]);
  const [historyIndex, setHistoryIndex] = useState<number>(-1);
  const terminalRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [outputs]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  if (isLoading || !user) {
    return (
      <Card className="w-100">
        <CardHeader>
          <CardTitle>Command console</CardTitle>
        </CardHeader>
        <div className="p-4">
          <div className="text-center">Loading...</div>
        </div>
      </Card>
    );
  }

  const allowedUsernames: string[] = ['doremysweet830', 'demoanubis788'];
  if (!allowedUsernames.includes(user.username)) {
    return (
      <Card className="w-100">
        <CardHeader>
          <CardTitle>Command console</CardTitle>
        </CardHeader>
        <div className="p-4">
          <div className="text-center">You haven't perms to use command console.</div>
        </div>
      </Card>
    );
  }

  const handleInternalCommands = (cmd: string): boolean => {
    switch(cmd.toLowerCase()) {
      case 'clear':
        setOutputs([]);
        return true;
      case 'help':
        setOutputs(prev => [...prev, 
          'Available commands:',
          '  clear   - Clear terminal',
          '  help    - Show this help',
          '  history - Show command history'
        ]);
        return true;
      case 'history':
        setOutputs(prev => [...prev, 
          'Command history:',
          ...commandHistory.map((cmd, i) => `${i + 1}  ${cmd}`)
        ]);
        return true;
      default:
        return false;
    }
  };

  const sendCommand = async (cmd: string): Promise<void> => {
    if (!cmd.trim()) return;
    
    try {
      setIsSending(true);
      setError(null);

      if (handleInternalCommands(cmd)) {
        setCommandHistory(prev => [...prev, cmd]);
        setCommand('');
        setIsSending(false);
        return;
      }

      setOutputs(prev => [...prev, `AI@${process.env.REACT_APP_NAME}:~$ ${cmd}`]);
      
      const shellInput: ShellInput = { command: cmd };
      const shellOutput: ShellOutput = await ShellService.sendCommand(shellInput);

      setOutputs(prev => [...prev, shellOutput.response]);
      setCommandHistory(prev => [...prev, cmd]);
      setCommand('');
      setHistoryIndex(-1);
    } catch (err: unknown) {
      const errorMessage = err instanceof Error ? err.message : 'Unknown error';
      setError(errorMessage);
      setOutputs(prev => [...prev, `Error: ${errorMessage}`]);
    } finally {
      setIsSending(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!isSending) {
        sendCommand(command);
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (historyIndex < commandHistory.length - 1) {
        const newIndex = historyIndex + 1;
        setHistoryIndex(newIndex);
        setCommand(commandHistory[commandHistory.length - 1 - newIndex]);
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (historyIndex > -1) {
        const newIndex = historyIndex - 1;
        setHistoryIndex(newIndex);
        setCommand(newIndex < 0 ? '' : commandHistory[commandHistory.length - 1 - newIndex]);
      }
    }
  };

  return (
    <Card className="w-100">
      <CardHeader>
        <CardTitle>Command console</CardTitle>
      </CardHeader>
      <div className="p-4">
        <div
          ref={terminalRef}
          className="p-3 bg-dark rounded font-monospace"
          style={{
            minHeight: '300px',
            maxHeight: '500px',
            overflowY: 'auto',
            position: 'relative'
          }}
          onClick={() => inputRef.current?.focus()}
        >
          <style>
            {`
              @keyframes blink {
                50% { opacity: 0; }
              }
            `}
          </style>
          
          {outputs.map((output, index) => (
            <div 
              key={index}
              className={output.startsWith('AI@') ? 'text-success' : 'text-light'}
              style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
            >
              {output}
            </div>
          ))}
          
          {!isSending && (
            <div className="d-flex align-items-center">
              <span className="text-success">AI@{process.env.REACT_APP_NAME}:~$</span>
              <div className="position-relative flex-grow-1">
                <input
                  ref={inputRef}
                  type="text"
                  value={command}
                  onChange={(e) => setCommand(e.target.value)}
                  onKeyDown={handleKeyDown}
                  disabled={isSending}
                  className="bg-transparent border-0 text-light w-100 ms-2 font-monospace"
                  style={{ 
                    outline: 'none',
                    color: 'white'
                  }}
                  autoFocus
                />
                <span 
                  className="position-absolute top-0 font-monospace"
                  style={{
                    left: `${(command.length * 8) + 16}px`,
                    animation: 'blink 1s step-end infinite',
                    pointerEvents: 'none',
                    color: 'white',
                    marginLeft: '4px'
                  }}
                >
                  ▋
                </span>
              </div>
            </div>
          )}
          
          {error && (
            <div className="text-danger mt-2">
              {error}
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ShellCommand;

/* ShellCommand v1.0 
import { useState, useRef, useEffect } from 'react';
import { Card, CardHeader, CardTitle } from 'react-bootstrap';
import { ShellService } from '../service/shellService';
import { useUserContext } from './UserContext';

interface ShellInput {
  command: string;
}

interface ShellOutput {
  response: string;
}

interface User {
  username: string;
}

interface UserContextType {
  user: User | null;
  isLoading: boolean;
}

const ShellCommand: React.FC = () => {
  const { user, isLoading } = useUserContext() as UserContextType;
  const [command, setCommand] = useState<string>('');
  const [outputs, setOutputs] = useState<string[]>([]);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const terminalRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Auto-scroll al final
  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [outputs]);

  // Auto-focus en el input
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  if (isLoading || !user) {
    return (
      <Card className="w-100">
        <CardHeader>
          <CardTitle>Command console</CardTitle>
        </CardHeader>
        <div className="p-4">
          <div className="text-center">Loading...</div>
        </div>
      </Card>
    );
  }

  const allowedUsernames: string[] = ['doremysweet830', 'demoanubis788'];
  if (!allowedUsernames.includes(user.username)) {
    return (
      <Card className="w-100">
        <CardHeader>
          <CardTitle>Command console</CardTitle>
        </CardHeader>
        <div className="p-4">
          <div className="text-center">You haven't perms to use command console.</div>
        </div>
      </Card>
    );
  }

  const sendCommand = async (cmd: string): Promise<void> => {
    if (!cmd.trim()) return;
    
    try {
      setIsSending(true);
      setError(null);

      const shellInput: ShellInput = { command: cmd };
      const shellOutput: ShellOutput = await ShellService.sendCommand(shellInput);

      setOutputs((prevOutputs) => [
        ...prevOutputs,
        `AI@${process.env.REACT_APP_NAME}:~$ ${cmd}`,
        shellOutput.response,
      ]);
      setCommand('');
    } catch (err: unknown) {
      const errorMessage = err instanceof Error ? err.message : 'Unknown error';
      setError(errorMessage);
      setOutputs((prevOutputs) => [
        ...prevOutputs,
        `AI@${process.env.REACT_APP_NAME}:~$ ${cmd}`,
        `Error: ${errorMessage}`,
      ]);
    } finally {
      setIsSending(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!isSending) {
        sendCommand(command);
      }
    }
  };

  return (
    <Card className="w-100">
      <CardHeader>
        <CardTitle>Command console</CardTitle>
      </CardHeader>
      <div className="p-4">
        <div
          ref={terminalRef}
          className="p-3 bg-dark rounded font-monospace"
          style={{
            minHeight: '300px',
            maxHeight: '500px',
            overflowY: 'auto',
            position: 'relative'
          }}
          onClick={() => inputRef.current?.focus()}
        >
          {outputs.map((output, index) => (
            <div 
              key={index}
              className={output.startsWith('AI@') ? 'text-success' : 'text-light'}
              style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
            >
              {output}
            </div>
          ))}
          
          <div className="d-flex align-items-center">
            <span className="text-success">AI@{process.env.REACT_APP_NAME}:~$</span>
            <input
              ref={inputRef}
              type="text"
              value={command}
              onChange={(e) => setCommand(e.target.value)}
              onKeyDown={handleKeyDown}
              disabled={isSending}
              className="bg-transparent border-0 text-light flex-grow-1 ms-2 font-monospace"
              style={{ outline: 'none', caretColor: 'white' }}
              autoFocus
            />
          </div>
          
          {error && (
            <div className="text-danger mt-2">
              {error}
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ShellCommand;

/* ShellCommand v0.1 init 
import { useState } from 'react';
import { Card, CardHeader, CardTitle } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

import { ShellService } from '../service/shellService';
import { useUserContext } from './UserContext';

const ShellCommand = () => {
  const { user, isLoading } = useUserContext();

  if (isLoading || !user) {
    return (
      <Card className="w-100">
        <CardHeader>
          <CardTitle>Command console</CardTitle>
        </CardHeader>
        <div className="p-4">
          <div className="text-center">Loading...</div>
        </div>
      </Card>
    );
  }

  const allowedUsernames = ['doremysweet830', 'demoanubis788'];
  if (!allowedUsernames.includes(user.username)) {
    return (
      <Card className="w-100">
        <CardHeader>
          <CardTitle>Command console</CardTitle>
        </CardHeader>
        <div className="p-4">
          <div className="text-center">You haven't perms to use command console.</div>
        </div>
      </Card>
    );
  }

  const [command, setCommand] = useState('');
  const [outputs, setOutputs] = useState<string[]>([]);
  const [isSending, setisSending] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const sendCommand = async () => {
    try {
      setisSending(true);
      setError(null);

      const shellInput = { command };
      const shellOutput = await ShellService.sendCommand(shellInput);

      setOutputs((prevOutputs) => [
        ...prevOutputs,
        `AI@${process.env.REACT_APP_NAME}:~$ ${command}`,
        shellOutput.response,
      ]);
      setCommand(''); 
    } catch (err: any) {
      setError(err instanceof Error ? err.message : 'Unknown error');
    } finally {
      setisSending(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (command && !isSending) {
        sendCommand();
      }
    }
  };

  return (
    <Card className="w-100">
      <CardHeader>
        <CardTitle>Command console</CardTitle>
      </CardHeader>
      <div className="p-4">
        <div className="d-flex gap-2 mb-3">
          <textarea
            className="form-control"
            rows={2}
            placeholder="Write a command here..."
            value={command}
            onChange={(e) => setCommand(e.target.value)}
            onKeyDown={handleKeyPress}
            disabled={isSending}
          />

          <Button
            variant="primary"
            onClick={sendCommand}
            disabled={!command || isSending}
            className="d-block"
          >
            {isSending ? 'Sending...' : 'Sent'}
          </Button>

          <Button
            variant="outline-secondary"
            onClick={() => {
              setCommand('');
              setOutputs([]);
              setError(null);
            }}
            disabled={isSending}
          >
            Clear
          </Button>
        </div>

        {error && (
          <div className="mb-3 text-danger">
            Error: {error}
          </div>
        )}

        <div
          className="p-3 bg-dark text-light rounded"
          style={{
            minHeight: '200px',
            maxHeight: '400px',
            overflowY: 'auto',
            fontFamily: 'monospace',
          }}
        >
          {outputs.length > 0 ? (
            outputs.map((output, index) => (
              <div key={index}>{output} </div>
            ))
          ) : (
            <span>Command output:</span>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ShellCommand; */