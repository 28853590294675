import React, { useState } from 'react';
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Col, Form, Row, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useUserContext } from 'layouts/UserContext';
import { UserService } from 'service/userService';

const SignInForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  const { refetchUser } = useUserContext();

  const navigate = useNavigate();

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successModalMessage, setSuccessModalMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleLoginClick = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (isLoading) return; // Prevent multiple clicks while loading

    setIsLoading(true);

    try {
      await UserService.login({ email: login, password: password });

      setSuccessModalMessage('Login successful');
      setShowSuccessModal(true);

      /*
        esto es muy importante, si no se llama a la función, el User Context no recargará el usuario
        y la aplicación no sabrá que el usuario ha iniciado sesión, provocando síntomas como la
        desaparición de la barra de navegación.
      */
      await refetchUser();
    } catch (error: any) {
      console.error(error);
      
      if (!error.response) {
        if (error.message) {
          setErrorModalMessage("Error: " + error.message);
        } else {
          setErrorModalMessage("Error: " + error);
        }
      } else {
        if (error.response.data && error.response.data.message) {
          setErrorModalMessage("Error: " + error.response.data.message);
        } else {
          setErrorModalMessage("Error: " + error);
        }
      }
      setShowErrorModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
    setErrorModalMessage('');
  }

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    setSuccessModalMessage('');
    navigate("/anubis");
  }

  return (
    <>
      <div className="text-center">
        {/*<Link
          to={`/pages/authentication/${layout}/sign-up`}
          className="fs-13 mb-5 fw-bold"
        >
          Create an Account
        </Link>*/}
        <h3 className="text-body-highlight mt-2 mb-3">Sign In</h3>
      </div>

      <div className="position-relative">
        <hr className="bg-body-secondary mt-5 mb-4" />
        <div className="divider-content-center">or use email</div>
      </div>
      <Form onSubmit={handleLoginClick}>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">Email address</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              id="login"  
              type="text"
              className="form-icon-input"
              placeholder="name@creatimus.com"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
              required
            />
            <FontAwesomeIcon icon={faUser as IconProp} className="text-body fs-9 form-icon" />
          </div>
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="password">Password</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              id="password"
              type="password"
              className="form-icon-input"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <FontAwesomeIcon icon={faKey as IconProp} className="text-body fs-9 form-icon" />
          </div>
        </Form.Group>
        <Row className="flex-between-center mb-7">
          <Col xs="auto">
            <Form.Check type="checkbox" className="mb-0">
              <Form.Check.Input
                type="checkbox"
                name="remember-me"
                id="remember-me"
                defaultChecked
              />
              <Form.Check.Label htmlFor="remember-me" className="mb-0">
                Remember me
              </Form.Check.Label>
            </Form.Check>
          </Col>
          <Col xs="auto">
            {/*<Link
              to={`/pages/authentication/${layout}/forgot-password`}
              className="fs-9 fw-semibold"
            >
              Forgot Password?
            </Link>*/}
          </Col>
        </Row>
        <Button 
          variant="primary" 
          className="w-100" 
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? 'Signing In...' : 'Sign In'}
        </Button>
      </Form>
      
      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {successModalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseSuccessModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      
      <Modal show={showErrorModal} onHide={handleCloseErrorModal}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {errorModalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseErrorModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      
      <div className="text-center">
        {/*<Link
          to={`/pages/authentication/${layout}/sign-up`}
          className="fs-9 fw-bold"
        >
          Create an account
        </Link>*/}
      </div>
    </>
  );
};

export default SignInForm;
