import NeuroSkillsDashboardTable from 'components/tables/NeuroSkillsDashboardTable';
import React from 'react';

const NeuroSkillsDashboard = () => {
  return (
    <>
      <h3>NeuroSkills Administration</h3>
      <p className="text-body-tertiary lh-sm mb-4">
        General NeuroSkill Center Administration.
      </p>
      <NeuroSkillsDashboardTable />
    </>
  );
};

export default NeuroSkillsDashboard;
