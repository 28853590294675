import NeuroPromptsDashboardTable from 'components/tables/NeuroPromptsDashboardTable';
import React from 'react';

const NeuroSkillsDashboard = () => {
  return (
    <>
      <h3>NeuroPrompts Administration</h3>
      <p className="text-body-tertiary lh-sm mb-4">
        General NeuroPrompts Center Administration.
      </p>
      <NeuroPromptsDashboardTable />
    </>
  );
};

export default NeuroSkillsDashboard;
