/* @v0.5_beta WidgetAIDetectGraph.tsx - Enhanced AI Detection Visualization */
import React, { useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { TooltipComponent } from 'echarts/components';
import { PieChart, BarChart } from 'echarts/charts';
import { Accordion, Alert, Card, Col, Row } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import { CallbackDataParams } from 'echarts/types/dist/shared';

echarts.use([TooltipComponent, PieChart, BarChart]);

// Enhanced interface with more detailed indicators
interface AnalysisVisualsProps {
  results: {
    probability: number;
    indicators: {
      repetitivePatterns: number;
      formulaicTransitions: number;
      formalLanguage: number;
      consistentTone: number;
      standardPhrases: number;
      structuralPatterns: number;
      styleConsistency: number;
      citations: number;
      documentSimilarity: number;
      citationValidity: number;
      imageAnalysis: number;
      contentOriginality: number;
    };
    confidence: string;
    detailedAnalysis: string[];
    suggestions: string[];
    apiReasons?: string[];
  };
  language: 'es' | 'en';
  deepMode?: boolean; // Add this optional prop
}

const AnalysisVisuals = ({ 
  results, 
  language, 
  deepMode = false  // Default to false if not provided
}: AnalysisVisualsProps) => {
  const [selectedSegment, setSelectedSegment] = useState<CallbackDataParams | null>(null);
  if (!results) return null;
  
  const { getThemeColor } = useAppContext();

  // Advanced color grading for AI probability
  const getColor = (value: number): string => {
    if (value < 10) return '#4ade80';  // light green - low AI probability
    if (value < 30) return '#facc15';  // yellow - moderate AI probability
    if (value < 50) return '#fb923c';  // orange - significant AI probability
    if (value < 70) return '#f87171';  // red - high AI probability
    return '#ef4444';  // dark red - very high AI probability
  };

  // Main Pie Chart Options
  const getMainChartOptions = () => {
    const aiValue = results.probability;
    const humanValue = 100 - results.probability;
    
    let centerValue = selectedSegment 
      ? selectedSegment.value as number
      : (aiValue > humanValue ? aiValue : humanValue);

    return {
      color: [
        getColor(results.probability),
        '#e5e7eb'  // gray for human percentage
      ],
      tooltip: {
        trigger: 'item',
        padding: [7, 10],
        backgroundColor: getThemeColor('body-highlight-bg'),
        borderColor: getThemeColor('border-color'),
        textStyle: { color: getThemeColor('light-text-emphasis') },
        formatter: (params: CallbackDataParams) =>
          `<strong>${params.name}:</strong> ${params.percent}%`
      },
      series: [
        {
          name: `${centerValue.toFixed(1)}%`,
          type: 'pie',
          radius: ['100%', '87%'],
          label: {
            show: true,
            position: 'center',
            formatter: '{a}',
            fontSize: 23,
            color: getThemeColor('light-text-emphasis')
          },
          data: [
            {
              name: language === 'es' ? 'IA' : 'AI',
              value: aiValue
            },
            {
              name: language === 'es' ? 'Humano' : 'Human',
              value: humanValue
            }
          ]
        }
      ]
    };
  };

  // Detailed Bar Chart Options
  const getDetailsChartOptions = () => {
    const detailsData = [
      {
        name: language === 'es' ? 'Patrones Repetitivos' : 'Repetitive Patterns',
        value: results.indicators.repetitivePatterns * 100,
        itemStyle: { 
          color: '#60a5fa', 
          borderRadius: [4, 4, 4, 4] 
        }
      },
      {
        name: language === 'es' ? 'Originalidad' : 'Originality',
        value: (1 - results.indicators.contentOriginality) * 100,
        itemStyle: { 
          color: '#34d399', 
          borderRadius: [4, 4, 4, 4] 
        }
      },
      {
        name: language === 'es' ? 'Formalidad' : 'Formality',
        value: results.indicators.formalLanguage * 100,
        itemStyle: { 
          color: '#a78bfa', 
          borderRadius: [4, 4, 4, 4] 
        }
      }
    ];

    return {
      tooltip: {
        trigger: 'axis',
        padding: [7, 10],
        backgroundColor: getThemeColor('body-highlight-bg'),
        borderColor: getThemeColor('border-color'),
        textStyle: { color: getThemeColor('light-text-emphasis') },
        axisPointer: { type: 'none' }
      },
      xAxis: {
        type: 'value',
        axisLabel: { show: false },
        show: false,
        axisLine: {
          lineStyle: {
            color: getThemeColor('tertiary-bg')
          }
        },
        axisTick: false
      },
      yAxis: {
        type: 'category',
        data: detailsData.map(() => ''),
        axisPointer: { type: 'none' },
        axisTick: 'none',
        axisLine: { show: false },
        axisLabel: { show: false }
      },
      series: {
        type: 'bar',
        barWidth: '20px',
        showBackground: true,
        backgroundStyle: {
          borderRadius: [4, 4, 4, 4]
        },
        data: detailsData.map(item => ({
          value: item.value,
          itemStyle: item.itemStyle,
          emphasis: {
            itemStyle: {
              color: item.itemStyle.color
            },
            label: {
              formatter: () => `{b| ${item.value.toFixed(1)}% }`,
              rich: {
                b: {
                  color: getThemeColor('white')
                }
              }
            }
          },
          label: {
            show: true,
            position: 'inside',
            formatter: () => `{b| ${item.value.toFixed(1)}%}`,
            rich: {
              b: {
                color: getThemeColor('body-color'),
                fontWeight: 900,
                padding: [-39, -21, 0, 0]
              }
            }
          }
        }))
      },
      grid: {
        right: 5,
        left: 20,
        bottom: 0,
        top: '5%',
        containLabel: false
      },
      animation: false
    };
  };

  // Probability Text Explanation
  const getProbabilityText = () => {
    const probability = results.probability;
    
    const confidenceMessages = {
      es: {
        low: 'El texto muestra características más típicas de escritura humana.',
        medium: 'Hay algunos indicadores de contenido generado por IA, pero no es concluyente.',
        high: 'Alta probabilidad de contenido generado por IA.'
      },
      en: {
        low: 'The text shows characteristics more typical of human writing.',
        medium: 'There are some indicators of AI-generated content, but it\'s not conclusive.',
        high: 'High likelihood of AI-generated content.'
      }
    };

    const confidenceLevel = 
      probability < 30 ? 'low' : 
      probability < 70 ? 'medium' : 'high';

    return `${language === 'es' ? 'El texto muestra' : 'The text shows'} ${probability.toFixed(1)}% ${
      language === 'es' ? 'de probabilidad de ser generado por IA' : 'probability of being AI-generated'}. ${
      confidenceMessages[language][confidenceLevel]
    }`;
  };

  return (
    <div>
      <Row className="g-3">
        {/* Main Analysis Card */}
        <Col xl={6} sm={6}>
          <Card className="h-100">
            <Card.Body>
              <div className="d-flex flex-column">
                <h3 className="h5 mb-4">
                  {language === 'es' ? 'Probabilidad de IA' : 'AI Probability'}
                </h3>
                <h6 className="text-body-tertiary">
                  {language === 'es' ? 'Tolerancia humana' : 'Human tolerance'}: 10%
                </h6>
                <div style={{ height: '200px' }}>
                  <ReactEChartsCore
                    echarts={echarts}
                    option={getMainChartOptions()}
                    style={{ height: '100%', width: '100%' }}
                  />
                </div>
                <div className="mt-3">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div className="d-flex align-items-center">
                      <div 
                        className="rounded-circle me-2" 
                        style={{ 
                          width: '8px', 
                          height: '8px', 
                          backgroundColor: getColor(results.probability) 
                        }} 
                      />
                      <span className="small">AI</span>
                    </div>
                    <span className="small fw-medium">
                      {results.probability.toFixed(1)}%
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <div 
                        className="rounded-circle me-2" 
                        style={{ 
                          width: '8px', 
                          height: '8px', 
                          backgroundColor: '#e5e7eb' 
                        }} 
                      />
                      <span className="small">Human</span>
                    </div>
                    <span className="small fw-medium">
                      {(100 - results.probability).toFixed(1)}%
                    </span>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Detailed Analysis Card */}
        <Col xl={6} sm={6}>
          <Card className="h-100">
            <Card.Body>
              <div className="d-flex flex-column">
                <h3 className="h5 mb-4">
                  {language === 'es' ? 'Análisis Detallado' : 'Detailed Analysis'}
                </h3>
                <h6 className="text-body-tertiary">
                  {deepMode 
                    ? (language === 'es' ? 'Patrones avanzados (modo deep)' : 'Advanced patterns (deep mode)')
                     : (language === 'es' ? 'Patrones standard (no-deep)' : 'Standard patterns (non-deep)')
                  }
                </h6>
                <div style={{ height: '200px' }}>
                  <ReactEChartsCore
                    echarts={echarts}
                    option={getDetailsChartOptions()}
                    style={{ height: '100%', width: '100%' }}
                  />
                </div>
                <div className="mt-4">
                  {[
                    { 
                      color: '#60a5fa', 
                      label: language === 'es' ? 'Patrones Repetitivos' : 'Repetitive Patterns', 
                      value: results.indicators.repetitivePatterns * 100 
                    },
                    { 
                      color: '#34d399', 
                      label: language === 'es' ? 'Originalidad' : 'Originality', 
                      value: (1 - results.indicators.contentOriginality) * 100 
                    },
                    { 
                      color: '#a78bfa', 
                      label: language === 'es' ? 'Formalidad' : 'Formality', 
                      value: results.indicators.formalLanguage * 100 
                    }
                  ].map((item, index) => (
                    <div key={index} className="d-flex align-items-center mb-2">
                      <div 
                        className="rounded-circle me-2" 
                        style={{ 
                          width: '8px', 
                          height: '8px', 
                          backgroundColor: item.color 
                        }} 
                      />
                      <span className="small me-auto">{item.label}</span>
                      <span className="small fw-medium">{item.value.toFixed(1)}%</span>
                    </div>
                  ))}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Analysis Interpretation */}
      <Card className="mt-3">
        <Card.Body>
          <h3 className="h5 mb-4">
            {language === 'es' ? 'Interpretación del Análisis' : 'Analysis Interpretation'}
          </h3>
          <div className="space-y-4">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0" className="border-top">
                <Accordion.Header>
                  {language === 'es' ? 'Probabilidad General' : 'General Probability'}
                </Accordion.Header>
                <Accordion.Body className="pt-0">
                  <p className="text-muted mb-0">{getProbabilityText()}</p>
                </Accordion.Body>
              </Accordion.Item>
              
              <Accordion.Item eventKey="1" className="border-top">
                <Accordion.Header>
                  {language === 'es' ? 'Análisis General' : 'General Analysis'}
                </Accordion.Header>
                <Accordion.Body className="pt-0">
                  {results.detailedAnalysis.map((analysis, index) => (
                    <div key={index} className="p-3 rounded">
                      <p className="text-muted mb-0">{analysis}</p>
                    </div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>

              {/* API Reasons Section (if available) */}
              {results.apiReasons && results.apiReasons.length > 0 && (
                <Accordion.Item eventKey="2" className="border-top">
                  <Accordion.Header>
                    {language === 'es' ? 'Razones del Análisis API' : 'API Analysis Reasons'}
                  </Accordion.Header>
                  <Accordion.Body className="pt-0">
                    {results.apiReasons.map((reason, index) => (
                      <div key={index} className="p-3 rounded">
                        <p className="text-muted mb-0">{reason}</p>
                      </div>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </Accordion>
          </div>
        </Card.Body>
      </Card>

      {/* Improvement Suggestions */}
      <Card className="mt-3">
        <Card.Body>
          <h3 className="h5 mb-4">
            {language === 'es' ? 'Sugerencias de Mejoras' : 'Improvement Suggestions'}
          </h3>
          <ul className="list-unstyled mb-0">
            {results.suggestions.length === 0 ? (
              <li className="d-flex mb-2">
                <Alert variant="subtle-warning">
                  <span>
                    {language === 'es' ? 'Sin sugerencias' : 'No Suggestions'}
                  </span>
                </Alert>
              </li>
            ) : (
              results.suggestions.map((suggestion, index) => (
                <li key={index} className="d-flex mb-2">
                  <Alert variant="subtle-info">
                    <span className="me-2">•</span>
                    <span>{suggestion}</span>
                  </Alert>
                </li>
              ))
            )}
          </ul>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AnalysisVisuals;
/* @v0.3_beta WidgetAIDetectGraph.tsx - modificación gráficos - barras / click porcentaje 
import React, { useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { TooltipComponent } from 'echarts/components';
import { PieChart, BarChart } from 'echarts/charts';
import { Accordion, Alert, Card, Col, Row } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import { CallbackDataParams } from 'echarts/types/dist/shared';

echarts.use([TooltipComponent, PieChart, BarChart]);

interface AnalysisVisualsProps {
  results: {
    probability: number;
    indicators: {
      repetitivePatterns: number;
      formulaicTransitions: number;
      formalLanguage: number;
      consistentTone: number;
      standardPhrases: number;
      structuralPatterns: number;
      styleConsistency: number;
      citations: number;
      documentSimilarity: number;
      citationValidity: number;
      imageAnalysis: number;
      contentOriginality: number;
    };
    confidence: string;
    detailedAnalysis: string[];
    suggestions: string[];
  };
  language: 'es' | 'en';
}

const AnalysisVisuals = ({ results, language }: AnalysisVisualsProps) => {
  const [selectedSegment, setSelectedSegment] = useState<CallbackDataParams | null>(null);
  if (!results) return null;
  const { getThemeColor } = useAppContext();

  const getColor = (value: number): string => {
    if (value < 10) return '#4ade80';  // green
    if (value < 40) return '#fbbf24';  // yellow
    return '#ef4444';  // red
  };

  const getMainChartOptions = () => {
    const aiValue = results.probability;
    const humanValue = 100 - results.probability;
    
    // Determinar el valor a mostrar en el centro
    let centerValue = selectedSegment 
      ? selectedSegment.value as number
      : (aiValue > humanValue ? aiValue : humanValue);

    return {
      color: [
        getColor(results.probability),
        '#e5e7eb'  // gray for human percentage
      ],
      tooltip: {
        trigger: 'item',
        padding: [7, 10],
        backgroundColor: getThemeColor('body-highlight-bg'),
        borderColor: getThemeColor('border-color'),
        textStyle: { color: getThemeColor('light-text-emphasis') },
        borderWidth: 1,
        transitionDuration: 0,
        formatter: (params: CallbackDataParams) =>
          `<strong>${params.name}:</strong> ${params.percent}%`
      },
      legend: { show: false },
      series: [
        {
          name: `${centerValue.toFixed(1)}%`,
          type: 'pie',
          radius: ['100%', '87%'],
          avoidLabelOverlap: false,
          emphasis: {
            scale: false,
            itemStyle: {
              color: 'inherit'
            }
          },
          itemStyle: {
            borderWidth: 2,
            borderColor: getThemeColor('body-bg')
          },
          label: {
            show: true,
            position: 'center',
            formatter: '{a}',
            fontSize: 23,
            color: getThemeColor('light-text-emphasis')
          },
          data: [
            {
              name: language === 'es' ? 'IA' : 'AI',
              value: aiValue
            },
            {
              name: language === 'es' ? 'Humano' : 'Human',
              value: humanValue
            }
          ]
        }
      ]
    };
  };

  const getDetailsChartOptions = () => {
    const detailsData = [
      {
        name: language === 'es' ? 'Patrones Repetitivos' : 'Repetitive Patterns',
        value: results.indicators.repetitivePatterns * 100,
        itemStyle: {
          color: '#60a5fa',
          borderRadius: [4, 4, 4, 4]
        }
      },
      {
        name: language === 'es' ? 'Originalidad' : 'Originality',
        value: (1 - results.indicators.contentOriginality) * 100,
        itemStyle: {
          color: '#34d399',
          borderRadius: [4, 4, 4, 4]
        }
      },
      {
        name: language === 'es' ? 'Formalidad' : 'Formality',
        value: results.indicators.formalLanguage * 100,
        itemStyle: {
          color: '#a78bfa',
          borderRadius: [4, 4, 4, 4]
        }
      }
    ];

    return {
      tooltip: {
        trigger: 'axis',
        padding: [7, 10],
        backgroundColor: getThemeColor('body-highlight-bg'),
        borderColor: getThemeColor('border-color'),
        textStyle: { color: getThemeColor('light-text-emphasis') },
        borderWidth: 1,
        transitionDuration: 0,
        axisPointer: {
          type: 'none'
        }
      },
      xAxis: {
        type: 'value',
        axisLabel: {
          show: false
        },
        show: false,
        axisLine: {
          lineStyle: {
            color: getThemeColor('tertiary-bg')
          }
        },
        axisTick: false
      },
      yAxis: {
        type: 'category',
        data: detailsData.map(item => ''),  // Dejamos vacío el texto del eje
        axisPointer: { type: 'none' },
        axisTick: 'none',
        axisLine: { show: false },
        axisLabel: { show: false }
      },
      series: {
        type: 'bar',
        barWidth: '20px',
        showBackground: true,
        backgroundStyle: {
          borderRadius: [4, 4, 4, 4]
        },
        data: detailsData.map(item => ({
          value: item.value,
          itemStyle: item.itemStyle,
          emphasis: {
            itemStyle: {
              color: item.itemStyle.color
            },
            label: {
              formatter: () => `{b| ${item.value.toFixed(1)}% }`,
              rich: {
                b: {
                  color: getThemeColor('white')
                }
              }
            }
          },
          label: {
            show: true,
            position: 'inside',
            formatter: () => `{b| ${item.value.toFixed(1)}%}`,
            rich: {
              b: {
                color: getThemeColor('body-color'),
                fontWeight: 900,
                padding: [-39, -21, 0, 0]
              }
            }
          }
        }))
      },
      grid: {
        right: 5,
        left: 20,  // Reducido ya que no hay etiquetas
        bottom: 0,
        top: '5%',
        containLabel: false
      },
      animation: false
    };
  };

  const getProbabilityText = () => {
    if (language === 'es') {
      return `El texto muestra una probabilidad del ${results.probability.toFixed(1)}% de haber sido generado por IA. ${
        results.probability > 70
          ? 'Esto sugiere una alta probabilidad de contenido generado por IA.'
          : results.probability > 30
          ? 'Hay algunos indicadores de contenido generado por IA, pero no es concluyente.'
          : 'El texto muestra características más típicas de escritura humana.'
      }`;
    }
    return `The text shows a ${results.probability.toFixed(1)}% probability of being AI-generated. ${
      results.probability > 70
        ? 'This suggests a high likelihood of AI-generated content.'
        : results.probability > 30
        ? 'There are some indicators of AI-generated content, but it\'s not conclusive.'
        : 'The text shows characteristics more typical of human writing.'
    }`;
  };

  const handleChartClick = (params: CallbackDataParams) => {
    setSelectedSegment(params);
  };

  const onEvents = {
    click: handleChartClick
  };

  return (
    <div>
      <Row className="g-3">
        {/* Main Analysis Card *-/}
        <Col xl={6} sm={6}>
          <Card className="h-100">
            <Card.Body>
              <div className="d-flex flex-column">
                <h3 className="h5 mb-4">
                  {language === 'es' ? 'Probabilidad de IA' : 'AI Probability'}
                </h3>
                <h6 className="text-body-tertiary">{language === 'es' ? 'Tolerancia humana' : 'Human tolerance'}: 10%</h6>
                <div style={{ height: '200px' }}>
                  <ReactEChartsCore
                    echarts={echarts}
                    option={getMainChartOptions()}
                    style={{ height: '100%', width: '100%' }}
                    onEvents={onEvents}
                  />
                </div>
                <div className="mt-3">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div className="d-flex align-items-center">
                      <div className="rounded-circle me-2" style={{ width: '8px', height: '8px', backgroundColor: getColor(results.probability) }} />
                      <span className="small">AI</span>
                    </div>
                    <span className="small fw-medium">{results.probability.toFixed(1)}%</span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="rounded-circle me-2" style={{ width: '8px', height: '8px', backgroundColor: '#e5e7eb' }} />
                      <span className="small">Human</span>
                    </div>
                    <span className="small fw-medium">{(100 - results.probability).toFixed(1)}%</span>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Detailed Analysis Card *-/}
        <Col xl={6} sm={6}>
          <Card className="h-100">
            <Card.Body>
              <div className="d-flex flex-column">
                <h3 className="h5 mb-4">
                  {language === 'es' ? 'Análisis Detallado' : 'Detailed Analysis'}
                </h3>
                <h6 className="text-body-tertiary">{language == 'es' ? 'Patrones standard (no-deep)' : 'Standard patterns (non-deep)' }</h6>
                <div style={{ height: '200px' }}>
                  <ReactEChartsCore
                    echarts={echarts}
                    option={getDetailsChartOptions()}
                    style={{ height: '100%', width: '100%' }}
                  />
                </div>
                <div className="mt-4">
                  {[
                    { color: '#60a5fa', label: language === 'es' ? 'Patrones Repetitivos' : 'Repetitive Patterns', value: results.indicators.repetitivePatterns * 100 },
                    { color: '#34d399', label: language === 'es' ? 'Originalidad' : 'Originality', value: (1 - results.indicators.contentOriginality) * 100 },
                    { color: '#a78bfa', label: language === 'es' ? 'Formalidad' : 'Formality', value: results.indicators.formalLanguage * 100 }
                  ].map((item, index) => (
                    <div key={index} className="d-flex align-items-center mb-2">
                      <div className="rounded-circle me-2" style={{ width: '8px', height: '8px', backgroundColor: item.color }} />
                      <span className="small me-auto">{item.label}</span>
                      <span className="small fw-medium">{item.value.toFixed(1)}%</span>
                    </div>
                  ))}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Analysis Interpretation *-/}
      <Card className="mt-3">
        <Card.Body>
          <h3 className="h5 mb-4">
            {language === 'es' ? 'Interpretación del Análisis' : 'Analysis Interpretation'}
          </h3>
          <div className="space-y-4">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0" className="border-top">
                <Accordion.Header>
                  {language === 'es' ? 'Probabilidad General' : 'General Probability'}
                </Accordion.Header>
                <Accordion.Body className="pt-0">
                  <p className=" text-muted mb-0">{getProbabilityText()}</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="border-top">
                <Accordion.Header>
                  {language === 'es' ? 'Análisis General' : 'General Analysis'}
                </Accordion.Header>
                <Accordion.Body className="pt-0">
                  {results.detailedAnalysis.map((analysis, index) => (
                    <div key={index} className="p-3 rounded"><p className=" text-muted mb-0">{analysis}</p></div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Card.Body>
      </Card>

      {/* Improvement Suggestions *-/}
      <Card className="mt-3">
        <Card.Body>
          <h3 className="h5 mb-4">
            {language === 'es' ? 'Sugerencias de Mejoras' : 'Improvement Suggestions'}
          </h3>
          <ul className="list-unstyled mb-0">
            {results.suggestions.length === 0 ? (
            <li className="d-flex mb-2">
              <Alert variant="subtle-warning">
                <span>{language === 'es' ? 'Sin sugerencias' : 'No Suggestions'}</span>
              </Alert>
            </li>
            ) : (
            results.suggestions.map((suggestion, index) => (
            <li key={index} className="d-flex mb-2">
              <Alert variant="subtle-info">
                <span className="me-2">•</span>
                <span>{suggestion}</span>
              </Alert>
            </li>
            ))
            )}
          </ul>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AnalysisVisuals;

/* @v0.2 - modificación gráficos - 05:00 11/02/2025
import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { TooltipComponent } from 'echarts/components';
import { PieChart, BarChart } from 'echarts/charts';
import { Card, Col, Row } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import { CallbackDataParams } from 'echarts/types/dist/shared';

echarts.use([TooltipComponent, PieChart, BarChart]);

interface AnalysisVisualsProps {
  results: {
    probability: number;
    indicators: {
      repetitivePatterns: number;
      formulaicTransitions: number;
      formalLanguage: number;
      consistentTone: number;
      standardPhrases: number;
      structuralPatterns: number;
      styleConsistency: number;
      citations: number;
      documentSimilarity: number;
      citationValidity: number;
      imageAnalysis: number;
      contentOriginality: number;
    };
    confidence: string;
    detailedAnalysis: string[];
    suggestions: string[];
  };
  language: 'es' | 'en';
}

const AnalysisVisuals = ({ results, language }: AnalysisVisualsProps) => {
  if (!results) return null;
  const { getThemeColor } = useAppContext();

  const getColor = (value: number): string => {
    if (value < 30) return '#4ade80';  // green
    if (value < 70) return '#fbbf24';  // yellow
    return '#ef4444';  // red
  };

  const getMainChartOptions = () => ({
    color: [
      getColor(results.probability),
      '#e5e7eb'  // gray for human percentage
    ],
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: getThemeColor('body-highlight-bg'),
      borderColor: getThemeColor('border-color'),
      textStyle: { color: getThemeColor('light-text-emphasis') },
      borderWidth: 1,
      transitionDuration: 0,
      formatter: (params: CallbackDataParams) =>
        `<strong>${params.name}:</strong> ${params.percent}%`
    },
    legend: { show: false },
    series: [
      {
        name: `${results.probability.toFixed(1)}%`,
        type: 'pie',
        radius: ['100%', '87%'],
        avoidLabelOverlap: false,
        emphasis: {
          scale: false,
          itemStyle: {
            color: 'inherit'
          }
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: getThemeColor('body-bg')
        },
        label: {
          show: true,
          position: 'center',
          formatter: '{a}',
          fontSize: 23,
          color: getThemeColor('light-text-emphasis')
        },
        data: [
          {
            name: language === 'es' ? 'IA' : 'AI',
            value: results.probability
          },
          {
            name: language === 'es' ? 'Humano' : 'Human',
            value: 100 - results.probability
          }
        ]
      }
    ]
  });

  const getDetailsChartOptions = () => {
    const detailsData = [
      {
        name: language === 'es' ? 'Patrones Repetitivos' : 'Repetitive Patterns',
        value: results.indicators.repetitivePatterns * 100,
        itemStyle: {
          color: '#60a5fa',
          borderRadius: [4, 4, 4, 4]
        }
      },
      {
        name: language === 'es' ? 'Originalidad' : 'Originality',
        value: (1 - results.indicators.contentOriginality) * 100,
        itemStyle: {
          color: '#34d399',
          borderRadius: [4, 4, 4, 4]
        }
      },
      {
        name: language === 'es' ? 'Formalidad' : 'Formality',
        value: results.indicators.formalLanguage * 100,
        itemStyle: {
          color: '#a78bfa',
          borderRadius: [4, 4, 4, 4]
        }
      }
    ];

    return {
      tooltip: {
        trigger: 'axis',
        padding: [7, 10],
        backgroundColor: getThemeColor('body-highlight-bg'),
        borderColor: getThemeColor('border-color'),
        textStyle: { color: getThemeColor('light-text-emphasis') },
        borderWidth: 1,
        transitionDuration: 0,
        axisPointer: {
          type: 'none'
        }
      },
      xAxis: {
        type: 'value',
        axisLabel: {
          show: false
        },
        show: false,
        axisLine: {
          lineStyle: {
            color: getThemeColor('tertiary-bg')
          }
        },
        axisTick: false
      },
      yAxis: {
        type: 'category',
        data: detailsData.map(item => ''),  // Dejamos vacío el texto del eje
        axisPointer: { type: 'none' },
        axisTick: 'none',
        axisLine: { show: false },
        axisLabel: { show: false }
      },
      series: {
        type: 'bar',
        barWidth: '20px',
        showBackground: true,
        backgroundStyle: {
          borderRadius: [4, 4, 4, 4]
        },
        data: detailsData.map(item => ({
          value: item.value,
          itemStyle: item.itemStyle,
          emphasis: {
            itemStyle: {
              color: item.itemStyle.color
            },
            label: {
              formatter: () => `{b| ${item.value.toFixed(1)}% }`,
              rich: {
                b: {
                  color: getThemeColor('white')
                }
              }
            }
          },
          label: {
            show: true,
            position: 'inside',
            formatter: () => `{b| ${item.value.toFixed(1)}%}`,
            rich: {
              b: {
                color: getThemeColor('body-color'),
                fontWeight: 900,
                padding: [-39, -21, 0, 0]
              }
            }
          }
        }))
      },
      grid: {
        right: 5,
        left: 20,  // Reducido ya que no hay etiquetas
        bottom: 0,
        top: '5%',
        containLabel: false
      },
      animation: false
    };
  };

  const getProbabilityText = () => {
    if (language === 'es') {
      return `El texto muestra una probabilidad del ${results.probability.toFixed(1)}% de haber sido generado por IA. ${
        results.probability > 70
          ? 'Esto sugiere una alta probabilidad de contenido generado por IA.'
          : results.probability > 30
          ? 'Hay algunos indicadores de contenido generado por IA, pero no es concluyente.'
          : 'El texto muestra características más típicas de escritura humana.'
      }`;
    }
    return `The text shows a ${results.probability.toFixed(1)}% probability of being AI-generated. ${
      results.probability > 70
        ? 'This suggests a high likelihood of AI-generated content.'
        : results.probability > 30
        ? 'There are some indicators of AI-generated content, but it\'s not conclusive.'
        : 'The text shows characteristics more typical of human writing.'
    }`;
  };

  return (
    <div>
      <Row className="g-3">
        {/* Main Analysis Card *--/}
        <Col xl={6} sm={6}>
          <Card className="h-100">
            <Card.Body>
              <div className="d-flex flex-column">
                <h3 className="h5 mb-4">
                  {language === 'es' ? 'Probabilidad de IA' : 'AI Probability'}
                </h3>
                <div style={{ height: '200px' }}>
                  <ReactEChartsCore
                    echarts={echarts}
                    option={getMainChartOptions()}
                    style={{ height: '100%', width: '100%' }}
                  />
                </div>
                <div className="mt-3">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div className="d-flex align-items-center">
                      <div className="rounded-circle me-2" style={{ width: '8px', height: '8px', backgroundColor: getColor(results.probability) }} />
                      <span className="small">AI</span>
                    </div>
                    <span className="small fw-medium">{results.probability.toFixed(1)}%</span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="rounded-circle me-2" style={{ width: '8px', height: '8px', backgroundColor: '#e5e7eb' }} />
                      <span className="small">Human</span>
                    </div>
                    <span className="small fw-medium">{(100 - results.probability).toFixed(1)}%</span>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Detailed Analysis Card *--/}
        <Col xl={6} sm={6}>
          <Card className="h-100">
            <Card.Body>
              <div className="d-flex flex-column">
                <h3 className="h5 mb-4">
                  {language === 'es' ? 'Análisis Detallado' : 'Detailed Analysis'}
                </h3>
                <div style={{ height: '200px' }}>
                  <ReactEChartsCore
                    echarts={echarts}
                    option={getDetailsChartOptions()}
                    style={{ height: '100%', width: '100%' }}
                  />
                </div>
                <div className="mt-4">
                  {[
                    { color: '#60a5fa', label: language === 'es' ? 'Patrones Repetitivos' : 'Repetitive Patterns', value: results.indicators.repetitivePatterns * 100 },
                    { color: '#34d399', label: language === 'es' ? 'Originalidad' : 'Originality', value: (1 - results.indicators.contentOriginality) * 100 },
                    { color: '#a78bfa', label: language === 'es' ? 'Formalidad' : 'Formality', value: results.indicators.formalLanguage * 100 }
                  ].map((item, index) => (
                    <div key={index} className="d-flex align-items-center mb-2">
                      <div className="rounded-circle me-2" style={{ width: '8px', height: '8px', backgroundColor: item.color }} />
                      <span className="small me-auto">{item.label}</span>
                      <span className="small fw-medium">{item.value.toFixed(1)}%</span>
                    </div>
                  ))}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Analysis Interpretation *--/}
      <Card className="mt-3">
        <Card.Body>
          <h3 className="h5 mb-4">
            {language === 'es' ? 'Interpretación del Análisis' : 'Analysis Interpretation'}
          </h3>
          <div className="space-y-4">
            <div className="p-3 bg-light rounded">
              <h4 className="h6 mb-2">
                {language === 'es' ? 'Probabilidad General' : 'General Probability'}
              </h4>
              <p className="small text-muted mb-0">{getProbabilityText()}</p>
            </div>

            {results.detailedAnalysis.map((analysis, index) => (
              <div key={index} className="p-3 bg-light rounded">
                <p className="small text-muted mb-0">{analysis}</p>
              </div>
            ))}
          </div>
        </Card.Body>
      </Card>

      {/* Improvement Suggestions *--/}
      <Card className="mt-3">
        <Card.Body>
          <h3 className="h5 mb-4">
            {language === 'es' ? 'Sugerencias de Mejora' : 'Improvement Suggestions'}
          </h3>
          <ul className="list-unstyled mb-0">
            {results.suggestions.map((suggestion, index) => (
              <li key={index} className="d-flex mb-2">
                <span className="me-2">•</span>
                <span className="small text-muted">{suggestion}</span>
              </li>
            ))}
          </ul>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AnalysisVisuals;

/* @v0.1 - init
import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { TooltipComponent } from 'echarts/components';
import { Card, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

interface Indicators {
  repetitivePatterns: number;
  formulaicTransitions: number;
  formalLanguage: number;
  consistentTone: number;
  standardPhrases: number;
  structuralPatterns: number;
  styleConsistency: number;
  citations: number;
  documentSimilarity: number;
  citationValidity: number;
  imageAnalysis: number;
  contentOriginality: number;
}

interface Results {
  probability: number;
  indicators: Indicators;
  confidence: string;
  detailedAnalysis: string[];
  suggestions: string[];
}

type LanguageType = 'es' | 'en';

// Pie Chart Component
const PieChart: React.FC<{
  data: { label: string; value: number; color: string }[];
  title: string;
}> = ({ data, title }) => {
  let currentAngle = 0;
  const total = data.reduce((sum, item) => sum + item.value, 0);
  const svgData = data.map(item => {
    const startAngle = currentAngle;
    const angle = (item.value / total) * 360;
    const endAngle = currentAngle + angle;
    currentAngle = endAngle;
    
    const x1 = Math.cos((startAngle - 90) * Math.PI / 180) * 100 + 100;
    const y1 = Math.sin((startAngle - 90) * Math.PI / 180) * 100 + 100;
    const x2 = Math.cos((endAngle - 90) * Math.PI / 180) * 100 + 100;
    const y2 = Math.sin((endAngle - 90) * Math.PI / 180) * 100 + 100;
    
    const largeArcFlag = angle > 180 ? 1 : 0;
    
    return {
      ...item,
      path: `M 100 100 L ${x1} ${y1} A 100 100 0 ${largeArcFlag} 1 ${x2} ${y2} Z`,
      angle: startAngle + angle / 2
    };
  });

  return (
    <div className="flex flex-col items-center p-4">
      <h3 className="text-lg font-semibold mb-4">{title}</h3>
      <div className="relative" style={{ width: '200px', height: '200px' }}>
        <svg viewBox="0 0 200 200">
          {svgData.map((item, index) => (
            <path
              key={index}
              d={item.path}
              fill={item.color}
              stroke="white"
              strokeWidth="1"
            />
          ))}
        </svg>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-sm font-semibold text-gray-700">
            {total.toFixed(1)}%
          </div>
        </div>
      </div>
      <div className="mt-4 space-y-2">
        {data.map((item, index) => (
          <div key={index} className="flex items-center">
            <div
              className="w-4 h-4 mr-2"
              style={{ backgroundColor: item.color }}
            />
            <span className="text-sm">
              {item.label}: {item.value.toFixed(1)}%
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const AnalysisVisuals: React.FC<{
  results: Results;
  language: LanguageType;
}> = ({ results, language }) => {
  if (!results) return null;

  const getColor = (value: number): string => {
    if (value < 30) return '#4ade80';  // green
    if (value < 70) return '#fbbf24';  // yellow
    return '#ef4444';  // red
  };

  const mainPieData = [
    {
      label: language === 'es' ? 'IA' : 'AI',
      value: results.probability,
      color: getColor(results.probability)
    },
    {
      label: language === 'es' ? 'Humano' : 'Human',
      value: 100 - results.probability,
      color: '#e5e7eb'
    }
  ];

  const indicatorsPieData = [
    {
      label: language === 'es' ? 'Patrones Repetitivos' : 'Repetitive Patterns',
      value: results.indicators.repetitivePatterns * 100,
      color: '#60a5fa'
    },
    {
      label: language === 'es' ? 'Originalidad' : 'Originality',
      value: (1 - results.indicators.contentOriginality) * 100,
      color: '#34d399'
    },
    {
      label: language === 'es' ? 'Formalidad' : 'Formality',
      value: results.indicators.formalLanguage * 100,
      color: '#a78bfa'
    }
  ];

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Main Analysis Card *--/}
        <Card className="p-6">
          <PieChart
            data={mainPieData}
            title={language === 'es' ? 'Probabilidad de IA' : 'AI Probability'}
          />
        </Card>

        {/* Detailed Analysis Card *--/}
        <Card className="p-6">
          <PieChart
            data={indicatorsPieData}
            title={language === 'es' ? 'Análisis Detallado' : 'Detailed Analysis'}
          />
        </Card>
      </div>

      {/* Analysis Interpretation *--/}
      <Card className="p-6">
        <h3 className="text-xl font-semibold mb-4">
          {language === 'es' ? 'Interpretación del Análisis' : 'Analysis Interpretation'}
        </h3>
        <div className="space-y-4">
          <div className="p-4 bg-gray-50 rounded-lg">
            <h4 className="font-semibold mb-2">
              {language === 'es' ? 'Probabilidad General' : 'General Probability'}
            </h4>
            <p className="text-sm text-gray-700">
              {language === 'es'
                ? `El texto muestra una probabilidad del ${results.probability.toFixed(1)}% de haber sido generado por IA. ${
                    results.probability > 70
                      ? 'Esto sugiere una alta probabilidad de contenido generado por IA.'
                      : results.probability > 30
                      ? 'Hay algunos indicadores de contenido generado por IA, pero no es concluyente.'
                      : 'El texto muestra características más típicas de escritura humana.'
                  }`
                : `The text shows a ${results.probability.toFixed(1)}% probability of being AI-generated. ${
                    results.probability > 70
                      ? 'This suggests a high likelihood of AI-generated content.'
                      : results.probability > 30
                      ? 'There are some indicators of AI-generated content, but it\'s not conclusive.'
                      : 'The text shows characteristics more typical of human writing.'
                  }`}
            </p>
          </div>

          {results.detailedAnalysis.map((analysis, index) => (
            <div key={index} className="p-4 bg-gray-50 rounded-lg">
              <p className="text-sm text-gray-700">{analysis}</p>
            </div>
          ))}
        </div>
      </Card>

      {/* Improvement Suggestions *--/}
      <Card className="p-6">
        <h3 className="text-xl font-semibold mb-4">
          {language === 'es' ? 'Sugerencias de Mejora' : 'Improvement Suggestions'}
        </h3>
        <ul className="space-y-2">
          {results.suggestions.map((suggestion, index) => (
            <li key={index} className="flex items-start">
              <span className="mr-2">•</span>
              <span className="text-sm text-gray-700">{suggestion}</span>
            </li>
          ))}
        </ul>
      </Card>
    </div>
  );
};

export default AnalysisVisuals;
/* */