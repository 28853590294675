import axios from 'axios';
import { IShellInput, IShellOutput } from 'data/models/shell/IShell';
import { apiUrl, getHatateToken } from 'helpers/utils';

export const ShellService = {
  sendCommand: async (shellInput: IShellInput): Promise<IShellOutput> => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
    
    try {
      // Sanitize the command by properly escaping quotes
      const sanitizedCommand = shellInput.command.replace(/"/g, '\\"');
      
      const response = await axios.post(
        `${apiUrl}shell/input`,
        {
          command: sanitizedCommand
        },
        { headers }
      );
      
      return response.data;
    } catch (error: any) {
      console.error('Error processing the command:', error);
      
      // Enhanced error handling
      if (error.response?.data) {
        throw new Error(error.response.data.message || 'Error en el servidor');
      } else if (error.request) {
        throw new Error('No se pudo conectar con el servidor');
      } else {
        throw new Error(error.message || 'Error desconocido');
      }
    }
  },
};

/* shellCommand v0.1 init 
import axios from 'axios';
import { IShellInput, IShellOutput } from 'data/models/shell/IShell';
import { apiUrl, getHatateToken } from 'helpers/utils';

export const ShellService = {
  sendCommand: async (shellInput: IShellInput): Promise<IShellOutput> => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };

    try {
      const response = await axios.post<IShellOutput>(`${apiUrl}shell/input`, { command: shellInput.command }, { headers });

      return response.data;
    } catch (error: any) {
      console.error('Error procesando el comando:', error);
      throw error.response ? error.response.data.message : error.message;
    }
  },
};
*/
