import Avatar from 'components/base/Avatar';
import { Button, Dropdown, Modal, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import defaultAvatar from 'assets/img/team/avatar-placeholder.webp';
import ProfileDropdownMenu from './ProfileDropdownMenu';
import { useAppContext } from 'providers/AppProvider';
import FeatherIcon from 'feather-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import NotificationDropdownMenu from './NotificationDropdownMenu';
import ThemeToggler from 'components/common/ThemeToggler';
import { useState } from 'react';
import DropdownSearchBox from 'components/common/DropdownSearchBox';
import SearchResult from 'components/common/SearchResult';
import classNames from 'classnames';
import NineDotMenu from './NineDotMenu';
import { useUserContext } from 'layouts/UserContext';
import { UserService } from 'service/userService';

const NavItems = () => {
  const { config: { navbarPosition } } = useAppContext();
  
  const { isLoading, refetchUser } = useUserContext();

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successModalMessage, setSuccessModalMessage] = useState('');
  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const handleEndSessionClick = async (e: any) => {
    e.preventDefault();

    UserService.logout();
    
    setSuccessModalMessage('Logout Successful');
    setShowSuccessModal(true);
  };

  const handleCloseSuccessModal = async () => {
    setShowSuccessModal(false);
    setSuccessModalMessage('');

    navigate('/pages/authentication/split/Sign-in');

    await refetchUser();
  };

  if (isLoading) {
    return <div>Loading, please wait...</div>;
  }
  
  return (
    <div className="navbar-nav navbar-nav-icons flex-row">
      <Nav.Item>
        <ThemeToggler className="px-2" />
      </Nav.Item>
      {/*<Nav.Item
        className={classNames({
          'd-lg-none':
            navbarPosition === 'vertical' || navbarPosition === 'dual'
        })}
      >
        <Nav.Link onClick={() => setOpenSearchModal(!openSearchModal)}>
          <FeatherIcon icon="search" size={19} style={{ marginBottom: 2 }} />
        </Nav.Link>
      </Nav.Item>*/}
      {/*<Nav.Item>
        <Dropdown autoClose="outside" className="h-100">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link h-100"
            variant=""
          >
            <FeatherIcon icon="bell" size={20} />
          </Dropdown.Toggle>
          <NotificationDropdownMenu />
        </Dropdown>
      </Nav.Item>*/}
      <Nav.Item>
        <Dropdown show={show} onToggle={(nextShow) => setShow(nextShow)}  className="h-100">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-100 d-flex align-items-center"
            variant=""
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
              <circle cx="2" cy="8" r="2" fill="currentColor"></circle>
              <circle cx="2" cy="14" r="2" fill="currentColor"></circle>
              <circle cx="8" cy="8" r="2" fill="currentColor"></circle>
              <circle cx="8" cy="14" r="2" fill="currentColor"></circle>
              <circle cx="14" cy="8" r="2" fill="currentColor"></circle>
              <circle cx="14" cy="14" r="2" fill="currentColor"></circle>
              <circle cx="8" cy="2" r="2" fill="currentColor"></circle>
              <circle cx="14" cy="2" r="2" fill="currentColor"></circle>
            </svg>
          </Dropdown.Toggle>
          <NineDotMenu onSelect={() => setShow(false)} />
        </Dropdown>
      </Nav.Item>
      <Nav.Item>
        <div className='px-2'>
          <OverlayTrigger placement='left' overlay={
            <Tooltip id="CloseSession" style={{ position: 'fixed' }}>
              Logout
            </Tooltip>}
          >
            <Link
              to='#'
              className="btn btn-link p-0 text-body order-1 order-lg-0"
              onClick={handleEndSessionClick}
            >
              <FeatherIcon icon="log-out" className="me-2" size={25} />
            </Link>
          </OverlayTrigger>
        </div>
      </Nav.Item>

      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">{successModalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseSuccessModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/*<Nav.Item>
        <Dropdown autoClose="outside" className="h-100">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-100 d-flex align-items-center"
            variant=""
          >
            <Avatar src={defaultAvatar} size="l" />
          </Dropdown.Toggle>
          <ProfileDropdownMenu />
        </Dropdown>
      </Nav.Item>*/}

      {/*<Modal
        show={openSearchModal}
        onHide={() => setOpenSearchModal(false)}
        className="search-box-modal mt-15"
      >
        <Modal.Body className="p-0 bg-transparent">
          <DropdownSearchBox
            className="navbar-top-search-box"
            inputClassName="rounded-pill"
            size="lg"
            style={{ width: 'auto' }}
          >
            <SearchResult />
          </DropdownSearchBox>
        </Modal.Body>
      </Modal>*/}
    </div>
  );
};

export default NavItems;
