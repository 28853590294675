/* v0.1 - ANUBIS */
import hazbotLogo from 'assets/img/team/40x40/Hazball.png';

import { FileAttachment } from 'components/common/AttachmentPreview';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faFaceSmile,
  faPenToSquare,
  faReply,
  faShare,
  faTrash
} from '@fortawesome/free-solid-svg-icons';

import { v4 as uuidv4 } from 'uuid';

export interface Message {
  conversationContext?: { /* se agrega para interacción con el usuario */
    needsClarification?: boolean;
    pendingConfirmation?: boolean;
    options?: string[];
    lastQuestion?: string;
  };
  id: number;
  type: 'sent' | 'received';
  message?: string;
  time: string;
  readAt: Date | string | null;
  attachments?: { images?: string[]; file?: FileAttachment };
  seen?: boolean;
  isTemporary?: boolean;
  isFinished?: boolean;
}

// diagram components
export const createComponentDiagram = (
  components: Array<{ 
    id: string; 
    name: string; 
    x: number; 
    y: number; 
    width?: number; 
    height?: number 
  }>,
  connections: Array<{ from: string; to: string; label?: string }>
): Message => {
  const svgContent = `<svg viewBox="0 0 600 400">
    <defs>
      <marker id="arrow" viewBox="0 0 10 10" refX="9" refY="5"
        markerWidth="6" markerHeight="6" orient="auto">
        <path d="M 0 0 L 10 5 L 0 10 z" fill="black"/>
      </marker>
    </defs>
    ${components.map(comp => `
      <rect x="${comp.x}" y="${comp.y}" 
        width="${comp.width || 120}" height="${comp.height || 60}" 
        fill="white" stroke="black" rx="5"/>
      <text x="${comp.x + (comp.width || 120)/2}" y="${comp.y + (comp.height || 60)/2}" 
        text-anchor="middle" dominant-baseline="middle">${comp.name}</text>
    `).join('')}
    ${connections.map(conn => {
      const from = components.find(c => c.id === conn.from);
      const to = components.find(c => c.id === conn.to);
      if (from && to) {
        const startX = from.x + (from.width || 120);
        const startY = from.y + (from.height || 60)/2;
        const endX = to.x;
        const endY = to.y + (to.height || 60)/2;
        return `
          <line x1="${startX}" y1="${startY}" x2="${endX}" y2="${endY}" 
            stroke="black" marker-end="url(#arrow)"/>
          ${conn.label ? `
            <text x="${(startX + endX)/2}" y="${(startY + endY)/2 - 10}" 
              text-anchor="middle">${conn.label}</text>
          ` : ''}
        `;
      }
      return '';
    }).join('')}
  </svg>`;

  return createSVGMessage(svgContent, 'Aquí está el diagrama de componentes:');
};

// math
export const createMathDiagram = (equation: string, explanation?: string): Message => {
  const svgContent = `<svg viewBox="0 0 400 200">
    <text x="50" y="80" font-family="serif" font-size="24">${equation}</text>
    ${explanation ? `
      <text x="50" y="120" font-size="14">${explanation}</text>
    ` : ''}
  </svg>`;

  return createSVGMessage(svgContent, 'Representación matemática:');
};

// Procs
export const createProcessDiagram = (steps: string[]): Message => {
  const spacing = 120;
  const svgContent = `<svg viewBox="0 0 ${steps.length * spacing + 50} 150">
    <defs>
      <marker id="arrow" viewBox="0 0 10 10" refX="9" refY="5"
        markerWidth="6" markerHeight="6" orient="auto">
        <path d="M 0 0 L 10 5 L 0 10 z" fill="black"/>
      </marker>
    </defs>
    ${steps.map((step, index) => `
      <rect x="${30 + index * spacing}" y="40" width="100" height="50" 
        fill="white" stroke="black" rx="5"/>
      <text x="${80 + index * spacing}" y="65" 
        text-anchor="middle" dominant-baseline="middle">${step}</text>
      ${index < steps.length - 1 ? `
        <line x1="${130 + index * spacing}" y1="65" 
          x2="${30 + (index + 1) * spacing}" y2="65" 
          stroke="black" marker-end="url(#arrow)"/>
      ` : ''}
    `).join('')}
  </svg>`;

  return createSVGMessage(svgContent, 'Diagrama de proceso:');
};

export const createSVGMessage = (
  svgContent: string,
  text?: string,
  context?: Message['conversationContext']
): Message => {
  return {
    id: Date.now(),
    type: 'received',
    message: `${text || ''}

{svg: ${svgContent}}`,
    time: new Date().toLocaleTimeString(),
    readAt: null,
    isFinished: true,
    conversationContext: context
  };
};

export const SVGExamples = {
  createFlowDiagram: (
    nodes: Array<{ id: string; text: string }>,
    arrows: Array<{ from: string; to: string }>
  ): Message => {
    const nodeWidth = 80;
    const nodeHeight = 40;
    const spacing = 100;

    let svgContent = `<svg viewBox="0 0 ${nodes.length * spacing} 100">
      <defs>
        <marker id="arrow" viewBox="0 0 10 10" refX="9" refY="5"
          markerWidth="6" markerHeight="6" orient="auto">
          <path d="M 0 0 L 10 5 L 0 10 z" fill="black"/>
        </marker>
      </defs>`;

    // draw nodos
    nodes.forEach((node, index) => {
      const x = 10 + (index * spacing);
      svgContent += `
        <rect x="${x}" y="10" width="${nodeWidth}" height="${nodeHeight}" 
          fill="white" stroke="black" rx="5"/>
        <text x="${x + nodeWidth/2}" y="35" text-anchor="middle">${node.text}</text>`;
    });

    // draw arrows
    arrows.forEach(arrow => {
      const fromIndex = nodes.findIndex(n => n.id === arrow.from);
      const toIndex = nodes.findIndex(n => n.id === arrow.to);
      if (fromIndex >= 0 && toIndex >= 0) {
        const startX = 10 + (fromIndex * spacing) + nodeWidth;
        const endX = 10 + (toIndex * spacing);
        svgContent += `
          <line x1="${startX}" y1="30" x2="${endX}" y2="30" 
            stroke="black" marker-end="url(#arrow)"/>`;
      }
    });

    svgContent += '</svg>';

    return createSVGMessage(svgContent, 'Aquí está el diagrama de flujo:');
  },

  createMathFormula: (formula: string): Message => {
    const svgContent = `<svg viewBox="0 0 300 100">
      <text x="20" y="50" font-family="serif" font-size="24">
        ${formula}
      </text>
    </svg>`;

    return createSVGMessage(svgContent, 'Aquí está la fórmula:');
  },

  createSimpleDiagram: (title: string): Message => {
    const svgContent = `<svg viewBox="0 0 200 100">
      <rect x="10" y="10" width="180" height="80" 
        fill="white" stroke="black" rx="5"/>
      <text x="100" y="55" text-anchor="middle">${title}</text>
    </svg>`;

    return createSVGMessage(svgContent);
  }
};

export interface User {
  id: number;
  avatar?: string;
  status: 'online' | 'offline';
  name: string;
}
export interface Conversation {
  id: string;
  user: User;
  messages: Message[];
  unreadMessages?: number;
}

export type MessageActionType = {
  icon: IconProp;
  label: string;
};

export const chatBotInstance: Conversation = {
  id: uuidv4(),
  user: { id: 1, avatar: hazbotLogo, status: 'online', name: 'TestBot' },
  messages: []
};

export const suggestions: string[] = [
  'What kind of support do you offer?',
  'What`re your areas of expertise?',
  'Who`re your developers?',
];

/*
export const suggestions: string[] = [
  'What is the amount of sulfuric acid I can load in a cylindrical tank, considering a 22% vapor expansion chamber (cut)? Please provide the amount of product in gallons, metric tons, and US pounds.',
  'What are the U.S. regulations for the handling, transport, and storage of sodium cyanide? I would like information on key regulations, such as those issued by the Department of Transportation (DOT) under 49 CFR and the Environmental Protection Agency (EPA), etc. Also, provide information regarding the classification, labeling, and transportation of the product, as well as the recommended chemical and respiratory protection for cleaning up a 3-ton spill of sodium cyanide pellets inside a warehouse.',
  'What chemical reaction occurs when ethyl bromide and hydrochloric acid are mixed, and what are the consequences and products of this reaction? I would like to know the risks associated with this mixture, such as the possible release of toxic or corrosive gases, as well as the recommended safety measures for handling these compounds. Please provide an analysis of the physicochemical consequences of the reaction and a stoichiometric calculation.',
]; */

export const files: FileAttachment[] = [
  {
    name: 'Federico_salsaniuella_godarf_design.zip',
    size: '53.34 MB',
    date: 'Dec 8, 2011',
    format: 'zip'
  },
  {
    name: 'Restart_lyf.bat',
    size: '11.13 KB',
    date: 'Dec 2, 2011',
    format: 'bat'
  },
  {
    name: 'Fake lorem ipsum fr fr.txt',
    size: '11.13 KB',
    date: 'Dec 2, 2011',
    format: 'txt'
  },
  {
    name: 'Unsupported file format.mad',
    size: '11.13 KB',
    date: 'Dec 2, 2011',
    format: 'mad'
  }
];

export const actions: MessageActionType[] = [
  {
    icon: faTrash,
    label: 'Delete'
  },
  {
    icon: faReply,
    label: 'Reply'
  },
  {
    icon: faPenToSquare,
    label: 'Edit'
  },
  {
    icon: faShare,
    label: 'Share'
  },
  {
    icon: faFaceSmile,
    label: 'Emoji'
  }
];
/* */
