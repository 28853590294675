import { getHatateToken } from "helpers/utils";
import { useUserContext } from "layouts/UserContext";
import { Navigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

export const ProtectedRoute = ({ children, redirectToSignIn }: any) => {
  const location = useLocation();
  const { user, setUser, isLoading } = useUserContext();

  useEffect(() => {
    const validateToken = async () => {
      const token = getHatateToken();
      if (token) {
        try {
          // Tu lógica de validación de token
          // Por ejemplo:
          // const userData = await validateTokenWithBackend();
          // setUser(userData);
        } catch (error) {
          // En caso de error de validación
          setUser(null);
        }
      }
    };

    validateToken();
  }, []);

  // Manejar estado de carga si es necesario
  if (isLoading) {
    return <div>Cargando...</div>; // O tu componente de carga
  }

  // Resto de tu lógica de protección de ruta
  if (getHatateToken() === '' && redirectToSignIn) {
    return <Navigate to="/pages/authentication/split/sign-in" replace />;
  }

  if (!user) {
    return <Navigate to="/" replace />;
  }

  return children;
};

/* v0.1 init
import { getHatateToken } from "helpers/utils";
import { useUserContext } from "layouts/UserContext";
import { Navigate } from "react-router-dom";
//import React from "react";

export const ProtectedRoute = ({ children, redirectToSignIn }: any) => {
  if (getHatateToken() == '' && redirectToSignIn) {
    return <Navigate to="/pages/authentication/split/sign-in" replace />;
  }

  console.log('ProtectedRoute');

  const { user } = useUserContext();
  if (!user) {
    return <Navigate to="/" replace />;
  }

  console.log('ProtectedRoute2');

  if (!user.verified) {
    alert('Account not verified. Please subscribe to a plan to view this page.');
    return <Navigate to="/" replace />;
  }
  
  console.log('ProtectedRoute3');
 
  return children;
};
/* */