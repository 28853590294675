import React, { useState } from 'react';
import { Upload, ImagePlus, Image as ImageIcon } from 'lucide-react';

const ImageAnalysisBot = () => {
  const [imageUrl, setImageUrl] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [analysis, setAnalysis] = useState('');
  const [prompt, setPrompt] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedImage, setGeneratedImage] = useState('');
  const [mode, setMode] = useState('analyze'); // 'analyze' or 'generate'

  async function analyzeImage(base64Image: string) {
    let responseData;

    try {
      const apiKey = "sk-proj-B89BlshzJpB62vuAZn5VT3BlbkFJEnr2IQbymPUwdqWjlX0x";
      if (!apiKey) {
        throw new Error('API key no encontrada');
      }

      const requestBody = {
        model: "gpt-4o-mini",
        messages: [
          {
            role: "user",
            content: [
              {
                type: "text",
                text: "Describe esta imagen en español y calcula siempre un aproximado de la cantidad de posibles personas y animales que existan en la imagen como también deducir cualquier número de orden o registro oculto o tapado sobreescrito. Deducir los números tachados con exactitud."
              },
              {
                type: "image_url",
                image_url: {
                  url: base64Image
                }
              }
            ]
          }
        ],
        max_tokens: 300
      };

      responseData = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        },
        body: JSON.stringify(requestBody)
      }).then(async res => {
        if (!res.ok) {
          const errorData = await res.json();
          throw new Error(`API Error: ${res.status} - ${errorData.error?.message || res.statusText}`);
        }
        return res.json();
      });

      return responseData.choices[0].message.content;
    } catch (error) {
      console.error('Error completo:', error);
      if (error instanceof Error) {
        throw new Error(`Error en el análisis: ${error.message}`);
      }
      throw new Error('Error desconocido en el análisis');
    }
  }

  async function generateImage(promptText: string) {
    try {
      const apiKey = "sk-proj-B89BlshzJpB62vuAZn5VT3BlbkFJEnr2IQbymPUwdqWjlX0x";
      if (!apiKey) {
        throw new Error('API key no encontrada');
      }

      const response = await fetch('https://api.openai.com/v1/images/generations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        },
        body: JSON.stringify({
          model: "dall-e-3",
          prompt: promptText,
          n: 1,
          size: "1024x1024"
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`API Error: ${response.status} - ${errorData.error?.message || response.statusText}`);
      }

      const data = await response.json();
      return data.data[0].url;
    } catch (error) {
      console.error('Error en la generación:', error);
      throw error;
    }
  }

  function handleUpload() {
    setLoading(true);
    setError('');
    
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    
    input.onchange = async function() {
      const reader = new FileReader();
      
      reader.onload = async function(e) {
        if (e.target && e.target.result) {
          const base64Image = e.target.result.toString();
          setImageUrl(base64Image);
          
          try {
            console.log('Enviando imagen a la API...');
            const analysisResult = await analyzeImage(base64Image);
            setAnalysis(analysisResult);
          } catch (error) {
            setError(error instanceof Error ? error.message : 'Error al procesar la imagen');
            console.error('Error en el procesamiento:', error);
          } finally {
            setLoading(false);
          }
        }
      };
      
      reader.onerror = function() {
        setError('Error al leer la imagen');
        setLoading(false);
      };
      
      if (input.files && input.files[0]) {
        reader.readAsDataURL(input.files[0]);
      } else {
        setLoading(false);
      }
    };
    
    input.click();
  }

  async function handleGenerate(e: { preventDefault: () => void; }) {
    e.preventDefault();
    if (!prompt.trim()) {
      setError('Por favor ingresa un prompt para generar la imagen');
      return;
    }

    setIsGenerating(true);
    setError('');
    setGeneratedImage('');

    try {
      const imageUrl = await generateImage(prompt);
      setGeneratedImage(imageUrl);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Error al generar la imagen');
    } finally {
      setIsGenerating(false);
    }
  }

  return (
    <div className="w-full max-w-2xl mx-auto p-4">
      <div className="flex gap-4 mb-6">
        <button
          onClick={() => setMode('analyze')}
          className={`flex items-center px-4 py-2 rounded-lg ${
            mode === 'analyze'
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
          }`}
        >
          <ImageIcon className="w-5 h-5 mr-2" />
          Analizar Imagen
        </button>
        <button
          onClick={() => setMode('generate')}
          className={`flex items-center px-4 py-2 rounded-lg ${
            mode === 'generate'
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
          }`}
        >
          <ImagePlus className="w-5 h-5 mr-2" />
          Generar Imagen
        </button>
      </div>

      {mode === 'analyze' ? (
        <div 
          className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center cursor-pointer hover:bg-gray-50 transition-colors"
          onClick={handleUpload}
        >
          <div className="flex flex-col items-center">
            <Upload className="w-12 h-12 text-gray-400" />
            <span className="mt-2 text-sm text-gray-500">
              Haz clic para seleccionar una imagen
            </span>
          </div>
        </div>
      ) : (
        <form onSubmit={handleGenerate} className="space-y-4">
          <div>
            <textarea
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Describe la imagen que quieres generar..."
              rows={4}
            />
          </div>
          <button
            type="submit"
            disabled={isGenerating}
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
          >
            {isGenerating ? 'Generando imagen...' : 'Generar Imagen'}
          </button>
        </form>
      )}

      {loading && (
        <div className="mt-4 text-center text-gray-600">
          <div className="animate-pulse">Analizando imagen con IA...</div>
        </div>
      )}

      {error && (
        <div className="mt-4 bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">
          {error}
        </div>
      )}

      {mode === 'analyze' && imageUrl && (
        <div className="mt-4">
          <img
            src={imageUrl}
            alt="Imagen cargada"
            className="max-w-full h-auto rounded-lg shadow"
          />
        </div>
      )}

      {mode === 'generate' && generatedImage && (
        <div className="mt-4">
          <img
            src={generatedImage}
            alt="Imagen generada"
            className="max-w-full h-auto rounded-lg shadow"
          />
        </div>
      )}

      {analysis && mode === 'analyze' && (
        <div className="mt-4 p-4 bg-white border border-gray-200 rounded-lg shadow-sm">
          <h3 className="font-semibold mb-2">Análisis de la Imagen:</h3>
          <p className="text-gray-700 whitespace-pre-wrap">{analysis}</p>
        </div>
      )}
    </div>
  );
};

export default ImageAnalysisBot;
/*
import React, { useState } from 'react';
import { Upload } from 'lucide-react';

const ImageAnalysisBot = () => {
  const [imageUrl, setImageUrl] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [analysis, setAnalysis] = useState('');

  async function analyzeImage(base64Image: string) {
    let responseData;

    try {
      const apiKey = "sk-proj-B89BlshzJpB62vuAZn5VT3BlbkFJEnr2IQbymPUwdqWjlX0x";
      if (!apiKey) {
        throw new Error('API key no encontrada');
      }

      const requestBody = {
        model: "gpt-4o-mini",
        messages: [
          {
            role: "user",
            content: [
              {
                type: "text",
                text: "Describe esta imagen en español"
              },
              {
                type: "image_url",
                image_url: {
                  url: base64Image
                }
              }
            ]
          }
        ],
        max_tokens: 300
      };

      console.log('Request body:', {
        ...requestBody,
        messages: [
          {
            ...requestBody.messages[0],
            content: [
              requestBody.messages[0].content[0],
              {
                ...requestBody.messages[0].content[1],
                image_url: {
                  url: base64Image.substring(0, 100) + '...'
                }
              }
            ]
          }
        ]
      });

      responseData = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        },
        body: JSON.stringify(requestBody)
      }).then(async res => {
        if (!res.ok) {
          const errorData = await res.json();
          throw new Error(`API Error: ${res.status} - ${errorData.error?.message || res.statusText}`);
        }
        return res.json();
      });

      return responseData.choices[0].message.content;
    } catch (error) {
      console.error('Error completo:', error);
      if (error instanceof Error) {
        throw new Error(`Error en el análisis: ${error.message}`);
      }
      throw new Error('Error desconocido en el análisis');
    }
  }

  function handleUpload() {
    setLoading(true);
    setError('');
    
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    
    input.onchange = async function() {
      const reader = new FileReader();
      
      reader.onload = async function(e) {
        if (e.target && e.target.result) {
          const base64Image = e.target.result.toString();
          setImageUrl(base64Image);
          
          try {
            console.log('Enviando imagen a la API...');
            const analysisResult = await analyzeImage(base64Image);
            setAnalysis(analysisResult);
          } catch (error) {
            setError(error instanceof Error ? error.message : 'Error al procesar la imagen');
            console.error('Error en el procesamiento:', error);
          } finally {
            setLoading(false);
          }
        }
      };
      
      reader.onerror = function() {
        setError('Error al leer la imagen');
        setLoading(false);
      };
      
      if (input.files && input.files[0]) {
        reader.readAsDataURL(input.files[0]);
      } else {
        setLoading(false);
      }
    };
    
    input.click();
  }

  return (
    <div className="w-full max-w-2xl mx-auto p-4">
      <div 
        className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center cursor-pointer hover:bg-gray-50 transition-colors"
        onClick={handleUpload}
      >
        <div className="flex flex-col items-center">
          <Upload className="w-12 h-12 text-gray-400" />
          <span className="mt-2 text-sm text-gray-500">
            Haz clic para seleccionar una imagen
          </span>
        </div>
      </div>

      {loading && (
        <div className="mt-4 text-center text-gray-600">
          <div className="animate-pulse">Analizando imagen con IA...</div>
        </div>
      )}

      {error && (
        <div className="mt-4 bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">
          {error}
        </div>
      )}

      {imageUrl && (
        <div className="mt-4">
          <img
            src={imageUrl}
            alt="Imagen cargada"
            className="max-w-full h-auto rounded-lg shadow"
          />
        </div>
      )}

      {analysis && (
        <div className="mt-4 p-4 bg-white border border-gray-200 rounded-lg shadow-sm">
          <h3 className="font-semibold mb-2">Análisis de la Imagen:</h3>
          <p className="text-gray-700 whitespace-pre-wrap">{analysis}</p>
        </div>
      )}
    </div>
  );
};

export default ImageAnalysisBot;*/