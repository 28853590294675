import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import UsersTokensDashboardTable from 'components/tables/UsersTokensDashboardTable';

const UsersTokensDashboard = () => {
  return (
    <>
      <h3>Users Administration</h3>
        <p className="text-body-tertiary lh-sm mb-4">
        General user administration.
        </p>
      <UsersTokensDashboardTable />
    </>
  );
}

export default UsersTokensDashboard;