import WidgetsSectionTitle from '../widgets/WidgetsSectionTitle';
import { faPercentage } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'react-bootstrap';
import PromptsDiscovered from '../project-management/dashboard/PromptsDiscovered';

const WidgetStats = () => {
  return (
    <div>
      <WidgetsSectionTitle
        title="NeuroPrompts"
        subtitle="General NeuroPrompts Statistics."
        icon={faPercentage}
        className="my-5"
      />
      <div className="">
        <Row>
          <Col xl={12} xxl={12}>
            <PromptsDiscovered />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default WidgetStats;
