import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Project, Status, projects } from 'data/project-management/projects';
import Avatar from 'components/base/Avatar';
import { Col, Form, Modal, ProgressBar, Row } from 'react-bootstrap';
import classNames from 'classnames';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/UsersTokensActionDropdownItems';
import AvatarDropdown from 'components/common/AvatarDropdown';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import SearchBox from 'components/common/SearchBox';
import { ChangeEvent, useState } from 'react';
import { IUser } from 'data/models/user/IUser';
import { useUserContext } from 'layouts/UserContext';

const columns: ColumnDef<IUser>[] = [
  {
    accessorKey: 'name',
    header: 'System user', 
    cell: ({ row: { original } }) => {
      const { email } = original;
      return (
        <Link
          to="#"
          className="text-decoration-none fw-bold fs-9"
        >
          {email}
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap' },
      headerProps: { style: { width: '30%' } }
    }
  },
  {
    header: 'Register date',
    accessorKey: 'createdAt',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    header: 'Last Login',
    accessorKey: 'updatedAt',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap' },
      headerProps: { style: { width: '15%' }, className: 'ps-3' }
    }
  },
  {
    id: 'action',
    cell: () => (
      <RevealDropdownTrigger>
        <RevealDropdown>
          <ActionDropdownItems />
        </RevealDropdown>
      </RevealDropdownTrigger>
    ),
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  }
];

const UsersTokensDashboardTable = () => {
  const { user } = useUserContext();

  const table = useAdvanceTable({
    data: user?.children as IUser[],
    columns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Row>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox
                placeholder="Search members"
                onChange={handleSearchInputChange} />
            </Col>
            <Col
              xs="auto"
              className="scrollbar overflow-hidden-y flex-grow-1"
            ></Col>
            <Col xs="auto">
              <Button disabled variant="link" className="text-body me-4 px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Export
              </Button>
              <Button variant="primary" onClick={handleShow}>
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Add member
              </Button>
            </Col>
          </Row>
        </div>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add new member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Lists: email (checker), name, lastname, phone, neuroskill multiselect, tokens default (select), passwd autogen/no
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="name@example.com" autoFocus />
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="name@example.com" autoFocus />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Data test</Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <AdvanceTableProvider {...table}>
        <AdvanceTable
          tableProps={{
            className: 'phoenix-table border-top border-translucent fs-9'
          }}
        />
        <AdvanceTableFooter pagination />
      </AdvanceTableProvider>
    </div>
  );
};

export default UsersTokensDashboardTable;
