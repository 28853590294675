import DealsReportTable, {
  dealsReportColumns
} from 'components/tables/DealsReportTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { dealsReportData } from 'data/crm/reportsData';
import { Col, Row } from 'react-bootstrap';
import UsersTokensDashboard from 'components/modules/Anubis-AI/userstokens/UsersTokensDashboard';
import UsersTokensStatusPercent from 'components/modules/crm/UsersTokensStats';

const WidgetTables = () => {
  const table = useAdvanceTable({
    data: dealsReportData,
    columns: dealsReportColumns,
    pageSize: 10,
    selection: true,
    pagination: true,
    sortable: true
  });
  
  return (
    <div>
       <AdvanceTableProvider {...table}>
        <Row>
          <Col xxl={6}>
            <UsersTokensStatusPercent />
          </Col>
        </Row>
        <UsersTokensDashboard />
      { /*<Row className="gx-6 mt-n3 pb-5">
        <Col xl={6}>
          <EcomTopRegions />
        </Col>
        <Col xl={6} className="mt-7">
          <DealForecast />
        </Col>
      </Row> */ }
      </AdvanceTableProvider>
    </div>
  );
};

export default WidgetTables;
