import WidgetsSectionTitle from '../components/modules/widgets/WidgetsSectionTitle';
import { faList, faPercentage } from '@fortawesome/free-solid-svg-icons';
import DealsReportTable, {
  dealsReportColumns
} from 'components/tables/DealsReportTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { dealsReportData } from 'data/crm/reportsData';
import { Col, Row } from 'react-bootstrap';
import EcomTopRegions from '../components/modules/e-commerce/dashboard/EcomTopRegions';
import DealForecast from '../components/modules/crm/DealForecast';
import NeuroSkillsDashboard from 'components/modules/Anubis-AI/userstokens/NeuroSkillsDashboard';
import NeuroSkillsStatusPercent from 'components/modules/crm/NeuroSkillsStats';

const WidgetTables = () => {
  const table = useAdvanceTable({
    data: dealsReportData,
    columns: dealsReportColumns,
    pageSize: 10,
    selection: true,
    pagination: true,
    sortable: true
  });
  return (
    <div>
      <Col xxl={6}>
        <NeuroSkillsStatusPercent />
      </Col>
        <NeuroSkillsDashboard />
      { /*<Row className="gx-6 mt-n3 pb-5">
        <Col xl={6}>
          <EcomTopRegions />
        </Col>
        <Col xl={6} className="mt-7">
          <DealForecast />
        </Col>
      </Row> */ }
    </div>
  );
};

export default WidgetTables;
