/* @v3.8_beta - {autoplay: "hello anubis", [test] } { interctions } - testing integración imagenes - buscar detección de idioma para contestar audio */
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Avatar from 'components/base/Avatar';
import Lightbox from 'components/base/LightBox';
import { Message as MessageType, User, actions } from 'data/hazbot/chat';
import useLightbox from 'hooks/useLightbox';
import MessageActionButtons from './MessageActionButtons';
import MessageAttachments from './MessageAttachments';
import AttachmentPreview from 'components/common/AttachmentPreview';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { KatexOptions } from 'katex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
 faCheckDouble, 
 faChevronDown, 
 faChevronUp,
 faVolumeUp,
 faPause,
 faPaperPlane
} from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';

const VOICE_CONFIGS = {
 spanish: {
   name: 'Microsoft Helena - Spanish (Spain)',
   backupNames: ['Helena', 'Microsoft Helena', 'Google español', 'Paulina', 'Monica'],
   lang: 'es-MX',
   pitch: 1.1,
   rate: 0.9,
   volume: 0.95
 },
 english: {
   name: 'Microsoft Samantha',
   backupNames: ['Samantha', 'Alex', 'Karen', 'Victoria', 'Google US English Female'],
   lang: 'en-US',
   pitch: 1.05,
   rate: 0.9,
   volume: 0.95
 },
 portuguese: {
   name: 'Microsoft Maria - Portuguese (Brazil)', 
   backupNames: ['Maria', 'Microsoft Maria', 'Google português', 'Luciana', 'Joana'],
   lang: 'pt-BR',
   pitch: 1.15,
   rate: 0.9,
   volume: 0.95
 },
 arabic: {
   name: 'Microsoft Hoda - Arabic',
   backupNames: ['Hoda', 'Microsoft Hoda', 'Google Arabic', 'Laila', 'Amira'],
   lang: 'ar-SA',
   pitch: 1.1,
   rate: 0.9,
   volume: 0.95
 }
};

interface CodeProps {
  inline?: boolean;
  className?: string;
  children: React.ReactNode;
  language?: string;
  node?: any;
}

interface PreProps {
  children: React.ReactNode;
}

interface MessageProps {
 message: MessageType;
 user: User;
 showActions?: boolean;
 onResponse?: (response: string) => void;
}

interface ConversationContext {
 needsClarification?: boolean;
 pendingConfirmation?: boolean;
 options?: string[];
 lastQuestion?: string;
 attachments?: {
   images?: string[];
   [key: string]: any;
 };
}

interface SVGDrawingProps {
 svgContent: string;  
}

const SVGDrawing: React.FC<SVGDrawingProps> = ({ svgContent }) => {
 return (
   <div 
     className="svg-container my-3 d-flex justify-content-center" 
     dangerouslySetInnerHTML={{ __html: svgContent }} 
   />
 );
};

const parseSVGBlocks = (content: string): { text: string, svgBlocks: string[] } => {
 const svgBlocks: string[] = [];
 let text = content;
 const svgRegex = /{svg:\s*(<svg[\s\S]*?<\/svg>)}/g;
 text = text.replace(svgRegex, (match, svgContent) => {
   svgBlocks.push(svgContent);
   return '[Drawing]';
 });
 return { text, svgBlocks };
};

const MessageContent: React.FC<{ 
 content: string;
 isReceived: boolean;
 isLoading: boolean | undefined;
 isFinished: boolean | undefined;
 onResponse?: (response: string) => void;
 context?: ConversationContext;
}> = ({ 
 content, 
 isReceived, 
 isLoading,
 isFinished,
 onResponse,
 context
}) => {
 const [svgDrawings, setSvgDrawings] = useState<string[]>([]);
 const [displayedContent, setDisplayedContent] = useState('');
 const [isPlaying, setIsPlaying] = useState(false);
 const [selectedVoice, setSelectedVoice] = useState<'spanish' | 'english' | 'portuguese' | 'arabic'>('english');
 const [audioProgress, setAudioProgress] = useState(0);
 const [audioDuration, setAudioDuration] = useState(0);
 const [userResponse, setUserResponse] = useState('');
 const [showResponseInput, setShowResponseInput] = useState(false);
 const [hasSpoken, setHasSpoken] = useState(false);

 const containerRef = useRef<HTMLDivElement>(null);
 const speechRef = useRef<SpeechSynthesisUtterance | null>(null);
 const progressIntervalRef = useRef<NodeJS.Timeout | null>(null);
 const inputRef = useRef<HTMLInputElement>(null);
 
 // En MessageContent, agregar estado:
 const [isGeneratingImage, setIsGeneratingImage] = useState(false);

// Agregar función:
async function generateImage(promptText: string): Promise<void> {
  try {
    const apiKey = process.env.REACT_APP_OPENAI_KEY;
    if (!apiKey) throw new Error('API key no encontrada');

    console.log('Enviando solicitud de imagen para:', promptText);

    const response = await fetch('https://api.openai.com/v1/images/generations', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`
      },
      body: JSON.stringify({
        model: "dall-e-3",
        prompt: promptText,
        n: 1,
        quality: "standard"
      })
    });

    console.log('Respuesta recibida:', response.status);
    const data = await response.json();
    console.log('Datos:', data);

    console.log(data.data[0].url);
    
    if (data?.data?.[0]?.url) {
      const imageElement = `
        <div style="margin-top: 1rem;">
          <img src="${data.data[0].url}" 
               alt="${promptText}" 
               style="max-width: 100%; height: auto; border-radius: 8px; margin: 1rem 0;" />
        HOLA!</div>
      `;
      
      if (onResponse) {
        onResponse(imageElement);
      }
    }
  } catch (error) {
    console.error('Error en generación:', error);
    if (onResponse) {
      onResponse('Error generando la imagen. Por favor intenta nuevamente.');
    }
  }
}

// Agregar useEffect para detectar comando de generación:
useEffect(() => {
  const checkForImageCommand = async () => {
    const imageRegex = /^(dibuja|inventa) (?:la |una |un )?imagen de (.+)/i;
    const match = content?.match(imageRegex);
    
    if (match) {
      setIsGeneratingImage(true);
      const prompt = match[2].trim();
      try {
        await generateImage(prompt);
      } finally {
        setIsGeneratingImage(false);
      }
    }
  };

  if (content) {
    checkForImageCommand();
  }
}, [content]);

 // Función para analizar imágenes
 async function analyzeImage(base64Image: string) {
   try {
     const apiKey = process.env.REACT_APP_OPENAI_KEY;
     if (!apiKey) throw new Error('API key no encontrada');

     const requestBody = {
       model: "gpt-4-vision-preview",
       messages: [
         {
           role: "user", 
           content: [
             {
               type: "text",
               text: "Describe esta imagen en español y calcula siempre un aproximado de la cantidad de posibles personas y animales que existan en la imagen como también deducir cualquier número de orden o registro oculto o tapado sobreescrito. Deducir los números tachados con exactitud."
             },
             {
               type: "image_url",
               image_url: {
                 url: base64Image
               }
             }
           ]
         }
       ],
       max_tokens: 300
     };

     const response = await fetch('https://api.openai.com/v1/chat/completions', {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${apiKey}`
       },
       body: JSON.stringify(requestBody)
     });

     if (!response.ok) {
       const errorData = await response.json();
       throw new Error(`API Error: ${response.status} - ${errorData.error?.message || response.statusText}`);
     }

     const data = await response.json();
     return data.choices[0].message.content;

   } catch (error) {
     console.error('Error en el análisis:', error);
     throw error;
   }
 }

 // Procesar imagen cuando se recibe
 useEffect(() => {
   const processImageAttachment = async () => {
     if (context?.attachments?.images?.[0]) {
       try {
         const analysis = await analyzeImage(context.attachments.images[0]);
         if (onResponse) {
           onResponse(analysis);
         }
       } catch (error) {
         console.error('Error procesando imagen:', error);
       }
     }
   };

   processImageAttachment();
 }, [context?.attachments?.images]);

 const processBlockMath = (text: string): string => {
   if (!text || typeof text !== 'string') return '';
   return text.replace(/\\\[([\s\S]*?)\\\]/g, (_, math) => `\n$$${math}$$\n`);
 };

 const processInlineMath = (text: string): string => {
   if (!text || typeof text !== 'string') return '';
   return text.replace(/\\\((.*?)\\\)/g, (_, math) => `$${math}$`);
 };

 const katexOptions: KatexOptions = {
   strict: false,
   throwOnError: false,
   displayMode: true,
   trust: true,
 };

 useEffect(() => {
   if (!content || typeof content !== 'string') {
     setDisplayedContent('');
     setSvgDrawings([]);
     setShowResponseInput(false);
     return;
   }

   const { text, svgBlocks } = parseSVGBlocks(content);
   let processedContent = processInlineMath(processBlockMath(content));

   if (isReceived && context) {
     if (context.needsClarification) {
       processedContent += '\n\n> *¿Podrías proporcionar más detalles sobre esto?*';
       setShowResponseInput(true);
     } else if (context.pendingConfirmation) {
       processedContent += '\n\n> *¿Confirmas esta acción?*';
       setShowResponseInput(true);
     } else if (context.options?.length) {
       processedContent += '\n\n> *Por favor, elige una opción:*\n';
       context.options.forEach((opt, i) => {
         processedContent += `> ${i + 1}. ${opt}\n`;
       });
       setShowResponseInput(true);
     } else {
       setShowResponseInput(false);
     }
   }

   setDisplayedContent(processedContent);
   setSvgDrawings(svgBlocks);
 }, [content, isReceived, context]);

 useEffect(() => {
   const initVoices = () => {
     const synth = window.speechSynthesis;
     synth.getVoices();
   };

   if (typeof window !== 'undefined') {
     initVoices();
     window.speechSynthesis.onvoiceschanged = initVoices;
   }

   return () => {
     if (typeof window !== 'undefined') {
       window.speechSynthesis.onvoiceschanged = null;
       window.speechSynthesis.cancel();
       if (progressIntervalRef.current) {
         clearInterval(progressIntervalRef.current);
       }
     }
   };
 }, []);

 useEffect(() => {
   if (isReceived && isFinished && displayedContent && !hasSpoken) {
     const lowercaseContent = displayedContent.toLowerCase();
 
     if (
       ['hi anubis', 'hello anubis', process.env.REACT_APP_NAME?.toLowerCase()]
         .some(term => term && lowercaseContent.includes(term.toLowerCase()))
     ) {
       handleSpeak();
       setHasSpoken(true);
     }
   }
 }, [isReceived, isFinished, displayedContent]);

 const findBestVoiceMatch = (voiceConfig: typeof VOICE_CONFIGS.spanish | typeof VOICE_CONFIGS.english | typeof VOICE_CONFIGS.portuguese | typeof VOICE_CONFIGS.arabic) => {
   const availableVoices = window.speechSynthesis.getVoices();
   let voice = availableVoices.find(v => v.name === voiceConfig.name);

   if (!voice) {
     for (const backupName of voiceConfig.backupNames) {
       voice = availableVoices.find(v =>
         v.name.toLowerCase().includes(backupName.toLowerCase()) &&
         v.lang.startsWith(voiceConfig.lang.split('-')[0])
       );
       if (voice) break;
     }
   }

   if (!voice) {
     voice = availableVoices.find(v =>
       v.lang.startsWith(voiceConfig.lang.split('-')[0]) &&
       v.name.toLowerCase().includes('female')
     );
   }

   if (!voice) {
     voice = availableVoices.find(v =>
       v.lang.startsWith(voiceConfig.lang.split('-')[0])
     );
   }

   return voice;
 };

 const updateAudioProgress = () => {
   if (speechRef.current) {
     const elapsedTime = window.speechSynthesis.speaking ? performance.now() - (speechRef.current as any).startTime : 0;
     const progress = Math.min((elapsedTime / ((speechRef.current as any).duration || 1)) * 100, 100);
     setAudioProgress(progress);

     if (progress >= 100) {
       if (progressIntervalRef.current) {
         clearInterval(progressIntervalRef.current);
       }
       setIsPlaying(false);
       setAudioProgress(0);
     }
   }
 };

 const handleSpeak = () => {
   if (!displayedContent) return;

   if (isPlaying) {
     window.speechSynthesis.cancel();
     setIsPlaying(false);
     if (progressIntervalRef.current) {
       clearInterval(progressIntervalRef.current);
     }
     setAudioProgress(0);
     return;
   }

   const voiceConfig = VOICE_CONFIGS[selectedVoice];
   speechRef.current = new SpeechSynthesisUtterance(displayedContent);

   const voice = findBestVoiceMatch(voiceConfig);
   if (voice) {
     speechRef.current.voice = voice;
     speechRef.current.lang = voiceConfig.lang;
     speechRef.current.pitch = voiceConfig.pitch;
     speechRef.current.rate = voiceConfig.rate;
     speechRef.current.volume = voiceConfig.volume;
   }

   (speechRef.current as any).startTime = performance.now();
   (speechRef.current as any).duration = (displayedContent.length / voiceConfig.rate) * 100;
   setAudioDuration((speechRef.current as any).duration);

   speechRef.current.onend = () => {
     setIsPlaying(false);
     if (progressIntervalRef.current) {
       clearInterval(progressIntervalRef.current);
     }
     setAudioProgress(0);
   };

   speechRef.current.onstart = () => {
     progressIntervalRef.current = setInterval(updateAudioProgress, 100);
   };

   window.speechSynthesis.speak(speechRef.current);
   setIsPlaying(true);
 };

 const toggleVoice = () => {
   setSelectedVoice(prev => prev === 'spanish' ? 'english' : prev === 'english' ? 'portuguese' : prev === 'portuguese' ? 'arabic' : 'spanish');
   if (isPlaying) {
     window.speechSynthesis.cancel();
     setIsPlaying(false);
     if (progressIntervalRef.current) {
       clearInterval(progressIntervalRef.current);
     }
     setAudioProgress(0);
   }
 };

 const handleUserResponse = () => {
   if (!userResponse.trim() || !onResponse) return;
   onResponse(userResponse);
   setUserResponse('');
 };

 const CustomCode: React.FC<CodeProps> = ({ 
   inline = false, 
   className, 
   children,
   ...props 
 }) => {
   const style = {
     display: 'block',
     maxWidth: '100%',
     overflow: 'auto',
     wordWrap: 'break-word' as const,
     whiteSpace: inline ? 'normal' as const : 'pre-wrap' as const
   };
   return (
     <code className={className} style={style} {...props}>
       {children}
     </code>
   );
 };

 const CustomPre: React.FC<PreProps> = ({ children }) => (
   <pre style={{ maxWidth: '100%', overflow: 'auto', marginBottom: '1rem' }}>
     {children}
   </pre>
 );

 if (isLoading) {
   return (
     <div className="d-flex align-items-center justify-content-center p-2">
       <Spinner animation="grow" />
     </div>
   );
 }

 return (
  <div className="relative">
    <div ref={containerRef} className="prose max-w-none message-scroll-container">
      {isGeneratingImage && (
        <div className="mt-4 text-center">
          <Spinner animation="border" size="sm" />
          <span className="ms-2">Generando imagen...</span>
        </div>
      )}

      <ReactMarkdown
        remarkPlugins={[remarkMath]}
        rehypePlugins={[[rehypeKatex, katexOptions]]}
        components={{
          code: CustomCode as any,
          pre: CustomPre as any
        }}
      >
        {displayedContent || ''}
      </ReactMarkdown>

       {svgDrawings.map((svg, index) => (
         <SVGDrawing key={`svg-${index}`} svgContent={svg} />
       ))}

       {showResponseInput && isReceived && (
         <div className="mt-3 d-flex gap-2">
           <input
             ref={inputRef}
             type="text"
             value={userResponse}
             onChange={(e) => setUserResponse(e.target.value)}
             onKeyPress={(e) => e.key === 'Enter' && handleUserResponse()}
             className="form-control"
             placeholder="Escribe tu respuesta..."
           />
           <button
             onClick={handleUserResponse}
             className="btn btn-primary d-flex align-items-center gap-2"
             disabled={!userResponse.trim()}
           >
             <FontAwesomeIcon icon={faPaperPlane} />
           </button>
         </div>
       )}
     </div>

     {isReceived && displayedContent && (
       <div className="absolute top-2 right-2 d-flex gap-3 align-items-center">
         <button
           onClick={toggleVoice}
           className="p-1 rounded-circle hover-bg-light transition"
           style={{ border: 'none', background: 'transparent', fontSize: '15px', cursor: 'pointer' }}
           title={`Current: ${selectedVoice === 'spanish' ? 'Español' : selectedVoice === 'english' ? 'English' : selectedVoice === 'portuguese' ? 'Português' : selectedVoice === 'arabic' ? 'العربية' : 'العربية'}`}
         >
           {selectedVoice === 'spanish' ? '🇪🇸' : selectedVoice === 'english' ? '🇺🇸' : selectedVoice === 'portuguese' ? '🇧🇷' : selectedVoice === 'arabic' ? '🇸🇦' : '🇸🇦'}
         </button>
         <button
           onClick={handleSpeak}
           className="p-1 rounded-circle hover-bg-light transition"
           style={{ border: 'none', background: 'transparent', fontSize: '14px', cursor: 'pointer' }}
           title={isPlaying ? "Pause audio" : "Play audio"}
         >
           <FontAwesomeIcon 
             icon={isPlaying ? faPause : faVolumeUp} 
             className="text-secondary hover-text-dark"
           />
         </button>
         <div 
           className="position-relative w-32 h-2 bg-light rounded"
           style={{ minWidth: '128px', cursor: 'pointer' }}
         >
           <div 
             className="position-absolute top-0 start-0 h-100 bg-primary rounded transition"
             style={{ 
               width: `${audioProgress}%`,
               backgroundColor: isPlaying ? '#0d6efd' : '#6c757d'
             }}
           />
           <div 
             className="position-absolute h-3 w-3 rounded-circle bg-white border border-primary shadow-sm transition"
             style={{ 
               left: `${audioProgress}%`,
               transform: 'translateX(-50%)',
               display: isPlaying ? 'block' : 'none'
             }}
           />
         </div>
       </div>
     )}
   </div>
 );
};

const Message: React.FC<MessageProps> = ({ message, user, showActions = true, onResponse }) => {
 const [isExpanded, setIsExpanded] = useState(true);
 const { lightboxProps, openLightbox } = useLightbox(
   message.attachments?.images || []
 );

 const showCollapseButton = message.type === 'received';

 const toggleAccordion = () => {
   setIsExpanded(!isExpanded);
 };

 return (
   <div className="d-flex chat-message">
     <div
       className={classNames('d-flex flex-1', {
         'justify-content-end': message.type === 'sent'
       })}
     >
       <div
         className={classNames('', {
           'w-xxl-75': showActions
         })}
         style={{ maxWidth: '100%' }}
       >
         {showCollapseButton && (
           <div className="d-flex justify-content-start mb-1">
             <button 
               onClick={toggleAccordion}
               className={classNames(
                 'border-0 bg-transparent p-1 rounded-circle d-flex align-items-center justify-content-center',
                 'hover-bg-light transition',
                 'text-body-tertiary'
               )}
               style={{ 
                 width: '20px', 
                 height: '20px',
                 fontSize: '12px',
                 cursor: 'pointer'
               }}
             >
               <FontAwesomeIcon 
                 icon={isExpanded ? faChevronUp : faChevronDown}
                 className="text-secondary hover-text-dark"
               />
             </button>
           </div>
         )}

         {(!showCollapseButton || isExpanded) && (
           <>
             <div
               className={classNames('d-flex hover-actions-trigger', {
                 'flex-end-center': message.type === 'sent'
               })}
             >
               {message.type === 'received' && (
                 <div className='me-2'></div>
               )}
               {message.type === 'sent' && showActions && (
                 <MessageActionButtons actions={actions} variant="sent" />
               )}
               <div
                 className={classNames('me-2', {
                   received: message.type === 'received'
                 })}
                 style={{ maxWidth: '100%', flex: '1 1 auto' }}
               >
                 <div
                   className={classNames('mb-0', {
                     'sent-message-content': message.type === 'sent',
                     'received-message-content border border-translucent':
                       message.type === 'received',
                     attachments:
                       Boolean(message.attachments?.images?.length) &&
                       !message.message
                   })}
                   style={{ maxWidth: '100%' }}
                 >
                      {message.isTemporary && !message.message && (
                        <div className="d-flex align-items-center p-3 gap-2">
                          <style>
                            {`
                              @keyframes dot-animation {
                              0% { content: ''; }
                              25% { content: '.'; }
                              50% { content: '..'; }
                              75% { content: '...'; }
                              100% { content: ''; }
                            }
                            .thinking-dots::after {
                              content: '';
                              animation: dot-animation 1.5s infinite;
                            }
                          `}
                          </style>
                          <Spinner 
                            animation="border" 
                            size="sm"
                            variant="secondary"
                          />
                          <span className="text-muted small d-flex align-items-center">
                            I'm thinking, please wait
                            <span className="thinking-dots"></span>
                          </span>
                        </div>
                      )}
                    {message.message && (
                      <div className="mb-0 message-container" style={{
                        maxWidth: '100%',
                        overflow: 'hidden'
                    }}>
                        <MessageContent 
                          content={message.message} 
                          isReceived={message.type === 'received'}
                          isLoading={message.isTemporary}
                          isFinished={message.isFinished}
                          onResponse={onResponse}
                          context={message.conversationContext}
                        />
                      </div>
                    )}
                   {message.attachments?.images && (
                     <MessageAttachments
                       attachments={message.attachments.images}
                       openLightbox={openLightbox}
                     />
                   )}
                   {message.attachments?.file && (
                     <AttachmentPreview
                       attachment={message.attachments.file}
                       variant={
                         message.type === 'received' ? 'primary' : 'secondary'
                       }
                     />
                   )}
                 </div>
               </div>
               {message.type === 'received' && showActions && (
                 <MessageActionButtons
                   actions={actions.slice(1)}
                   variant="received"
                 />
               )}
             </div>
             <div
               className={classNames('d-flex gap-1 fs-10', {
                 'ms-7': message.type === 'received',
                 'justify-content-end': message.type === 'sent'
               })}
             >
               <p className="mb-0 text-body-tertiary text-opacity-85 fw-semibold">
                 {message.time}
               </p>
               {message.readAt && (
                 <FontAwesomeIcon icon={faCheckDouble} className="text-success" />
               )}
             </div>
             <Lightbox {...lightboxProps} />
           </>
         )}
       </div>
     </div>
   </div>
 );
};

export default Message;

/* @v3.7 - {autoplay: "hello anubis", [test] } { interctions } - buscar detección de idioma para contestar audio 
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Avatar from 'components/base/Avatar';
import Lightbox from 'components/base/LightBox';
import { Message as MessageType, User, actions } from 'data/hazbot/chat';
import useLightbox from 'hooks/useLightbox';
import MessageActionButtons from './MessageActionButtons';
import MessageAttachments from './MessageAttachments';
import AttachmentPreview from 'components/common/AttachmentPreview';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { KatexOptions } from 'katex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCheckDouble, 
  faChevronDown, 
  faChevronUp,
  faVolumeUp,
  faPause,
  faPaperPlane
} from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';

const VOICE_CONFIGS = {
  spanish: {
    name: 'Microsoft Helena - Spanish (Spain)',
    backupNames: [
      'Helena',
      'Microsoft Helena',
      'Google español',
      'Paulina',
      'Monica'
    ],
    lang: 'es-MX',
    pitch: 1.1,
    rate: 0.9,
    volume: 0.95
  },
  english: {
    name: 'Microsoft Samantha',
    backupNames: [
      'Samantha',
      'Alex',
      'Karen',
      'Victoria',
      'Google US English Female'
    ],
    lang: 'en-US',
    pitch: 1.05,
    rate: 0.9,
    volume: 0.95
  },
  portuguese: {
    name: 'Microsoft Maria - Portuguese (Brazil)',
    backupNames: [
      'Maria',
      'Microsoft Maria',
      'Google português',
      'Luciana',
      'Joana'
    ],
    lang: 'pt-BR',
    pitch: 1.15,
    rate: 0.9,
    volume: 0.95
  },
  arabic: {
    name: 'Microsoft Hoda - Arabic',
    backupNames: [
      'Hoda',
      'Microsoft Hoda',
      'Google Arabic',
      'Laila',
      'Amira'
    ],
    lang: 'ar-SA',
    pitch: 1.1,
    rate: 0.9,
    volume: 0.95
  }
};

interface CodeProps {
  inline?: boolean;
  className?: string;
  children: React.ReactNode;
  node?: any;
}

interface PreProps {
  children: React.ReactNode;
}

interface MessageProps {
  message: MessageType;
  user: User;
  showActions?: boolean;
  onResponse?: (response: string) => void;
}

interface ConversationContext {
  needsClarification?: boolean;
  pendingConfirmation?: boolean;
  options?: string[];
  lastQuestion?: string;
}

interface SVGDrawingProps {
  svgContent: string;
}

const SVGDrawing: React.FC<SVGDrawingProps> = ({ svgContent }) => {
  return (
    <div 
      className="svg-container my-3 d-flex justify-content-center" 
      dangerouslySetInnerHTML={{ __html: svgContent }} 
    />
  );
};

const parseSVGBlocks = (content: string): { 
  text: string, 
  svgBlocks: string[] 
} => {
  const svgBlocks: string[] = [];
  let text = content;

  // Busca bloques SVG en el formato {svg: <svg>...</svg>}
  const svgRegex = /{svg:\s*(<svg[\s\S]*?<\/svg>)}/g;
  
  text = text.replace(svgRegex, (match, svgContent) => {
    svgBlocks.push(svgContent);
    return '[Drawing]';
  });

  return { text, svgBlocks };
};

const MessageContent: React.FC<{ 
  content: string; 
  isReceived: boolean;
  isLoading: boolean | undefined;
  isFinished: boolean | undefined;
  onResponse?: (response: string) => void;
  context?: ConversationContext;
}> = ({ 
  content, 
  isReceived, 
  isLoading,
  isFinished,
  onResponse,
  context
}) => {
  const [svgDrawings, setSvgDrawings] = useState<string[]>([]);
  const [displayedContent, setDisplayedContent] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState<'spanish' | 'english' | 'portuguese' | 'arabic'>('english');
  const [audioProgress, setAudioProgress] = useState(0);
  const [audioDuration, setAudioDuration] = useState(0);
  const [userResponse, setUserResponse] = useState('');
  const [showResponseInput, setShowResponseInput] = useState(false);
  
  const [hasSpoken, setHasSpoken] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const speechRef = useRef<SpeechSynthesisUtterance | null>(null);
  const progressIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const processBlockMath = (text: string): string => {
    if (!text || typeof text !== 'string') return '';
    return text.replace(/\\\[([\s\S]*?)\\\]/g, (_, math) => `\n$$${math}$$\n`);
  };

  const processInlineMath = (text: string): string => {
    if (!text || typeof text !== 'string') return '';
    return text.replace(/\\\((.*?)\\\)/g, (_, math) => `$${math}$`);
  };

  const katexOptions: KatexOptions = {
    strict: false,
    throwOnError: false,
    displayMode: true,
    trust: true,
  };

  // Efecto para procesar el contenido y determinar la interactividad
  useEffect(() => {
    if (!content || typeof content !== 'string') {
      setDisplayedContent('');
      setSvgDrawings([]);
      setShowResponseInput(false);
      return;
    }

    const { text, svgBlocks } = parseSVGBlocks(content);

    let processedContent = processInlineMath(processBlockMath(content));

    if (isReceived && context) {
      if (context.needsClarification) {
        processedContent += '\n\n> *¿Podrías proporcionar más detalles sobre esto?*';
        setShowResponseInput(true);
      } else if (context.pendingConfirmation) {
        processedContent += '\n\n> *¿Confirmas esta acción?*';
        setShowResponseInput(true);
      } else if (context.options?.length) {
        processedContent += '\n\n> *Por favor, elige una opción:*\n';
        context.options.forEach((opt, i) => {
          processedContent += `> ${i + 1}. ${opt}\n`;
        });
        setShowResponseInput(true);
      } else {
        setShowResponseInput(false);
      }
    }

    setDisplayedContent(processedContent);
    setSvgDrawings(svgBlocks);
  }, [content, isReceived, context]);

  // Inicializar las voces
  useEffect(() => {
    const initVoices = () => {
      const synth = window.speechSynthesis;
      synth.getVoices();
    };

    if (typeof window !== 'undefined') {
      initVoices();
      window.speechSynthesis.onvoiceschanged = initVoices;
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.speechSynthesis.onvoiceschanged = null;
        window.speechSynthesis.cancel();
        if (progressIntervalRef.current) {
          clearInterval(progressIntervalRef.current);
        }
      }
    };
  }, []);

  // Efecto para autoplay cuando el contenido se establece
  useEffect(() => {
    if (isReceived && isFinished && displayedContent && !hasSpoken) {
      const lowercaseContent = displayedContent.toLowerCase();
  
      if (
        ['hi anubis', 'hello anubis', process.env.REACT_APP_NAME?.toLowerCase()]
          .some(term => term && lowercaseContent.includes(term.toLowerCase()))
      ) {
        handleSpeak();
        setHasSpoken(true);
      }
    }
  }, [isReceived, isFinished, displayedContent]); // Dependemos de estos valoresv

  const findBestVoiceMatch = (voiceConfig: typeof VOICE_CONFIGS.spanish | typeof VOICE_CONFIGS.english | typeof VOICE_CONFIGS.portuguese | typeof VOICE_CONFIGS.arabic) => {
    const availableVoices = window.speechSynthesis.getVoices();
    let voice = availableVoices.find(v => v.name === voiceConfig.name);

    if (!voice) {
      for (const backupName of voiceConfig.backupNames) {
        voice = availableVoices.find(v =>
          v.name.toLowerCase().includes(backupName.toLowerCase()) &&
          v.lang.startsWith(voiceConfig.lang.split('-')[0])
        );
        if (voice) break;
      }
    }

    if (!voice) {
      voice = availableVoices.find(v =>
        v.lang.startsWith(voiceConfig.lang.split('-')[0]) &&
        v.name.toLowerCase().includes('female')
      );
    }

    if (!voice) {
      voice = availableVoices.find(v =>
        v.lang.startsWith(voiceConfig.lang.split('-')[0])
      );
    }

    return voice;
  };

  const updateAudioProgress = () => {
    if (speechRef.current) {
      const elapsedTime = window.speechSynthesis.speaking ? performance.now() - (speechRef.current as any).startTime : 0;
      const progress = Math.min((elapsedTime / ((speechRef.current as any).duration || 1)) * 100, 100);
      setAudioProgress(progress);

      if (progress >= 100) {
        if (progressIntervalRef.current) {
          clearInterval(progressIntervalRef.current);
        }
        setIsPlaying(false);
        setAudioProgress(0);
      }
    }
  };

  const handleSpeak = () => {
    if (!displayedContent) return;

    if (isPlaying) {
      window.speechSynthesis.cancel();
      setIsPlaying(false);
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
      setAudioProgress(0);
      return;
    }

    const voiceConfig = VOICE_CONFIGS[selectedVoice];
    speechRef.current = new SpeechSynthesisUtterance(displayedContent);

    const voice = findBestVoiceMatch(voiceConfig);
    if (voice) {
      speechRef.current.voice = voice;
      speechRef.current.lang = voiceConfig.lang;
      speechRef.current.pitch = voiceConfig.pitch;
      speechRef.current.rate = voiceConfig.rate;
      speechRef.current.volume = voiceConfig.volume;
    }

    (speechRef.current as any).startTime = performance.now();
    (speechRef.current as any).duration = (displayedContent.length / voiceConfig.rate) * 100; // Estimación
    setAudioDuration((speechRef.current as any).duration);

    speechRef.current.onend = () => {
      setIsPlaying(false);
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
      setAudioProgress(0);
    };

    speechRef.current.onstart = () => {
      progressIntervalRef.current = setInterval(updateAudioProgress, 100);
    };

    window.speechSynthesis.speak(speechRef.current);
    setIsPlaying(true);
  };

  const toggleVoice = () => {
    setSelectedVoice(prev => prev === 'spanish' ? 'english' : prev === 'english' ? 'portuguese' : prev === 'portuguese' ? 'arabic' : 'spanish');
    if (isPlaying) {
      window.speechSynthesis.cancel();
      setIsPlaying(false);
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
      setAudioProgress(0);
    }
  };

  const handleUserResponse = () => {
    if (!userResponse.trim() || !onResponse) return;
    onResponse(userResponse);
    setUserResponse('');
  };

  const CustomCode: React.FC<CodeProps> = ({ 
    inline = false, 
    className, 
    children,
    ...props 
  }) => {
    const style = {
      display: 'block',
      maxWidth: '100%',
      overflow: 'auto',
      wordWrap: 'break-word' as const,
      whiteSpace: inline ? 'normal' as const : 'pre-wrap' as const
    };
    return (
      <code className={className} style={style} {...props}>
        {children}
      </code>
    );
  };

  const CustomPre: React.FC<PreProps> = ({ children }) => (
    <pre style={{ maxWidth: '100%', overflow: 'auto', marginBottom: '1rem' }}>
      {children}
    </pre>
  );

  if (isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center p-2">
        <Spinner animation="grow" />
      </div>
    );
  }

  return (
    <div className="relative">
      <div ref={containerRef} className="prose max-w-none message-scroll-container">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" size="sm" />
          </div>
        ) : (
          <>
            <ReactMarkdown
              remarkPlugins={[remarkMath]}
              rehypePlugins={[[rehypeKatex, katexOptions]]}
              components={{
                code: CustomCode as any,
                pre: CustomPre as any
              }}
            >
              {displayedContent || ''}
            </ReactMarkdown>

            {/* Renderizado de SVGs *}
            {svgDrawings.map((svg, index) => (
              <SVGDrawing key={`svg-${index}`} svgContent={svg} />
            ))}

            {showResponseInput && isReceived && (
              <div className="mt-3 d-flex gap-2">
                <input
                  ref={inputRef}
                  type="text"
                  value={userResponse}
                  onChange={(e) => setUserResponse(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && handleUserResponse()}
                  className="form-control"
                  placeholder={
                    //context.needsClarification ? "Proporciona más detalles..." :
                    //context.pendingConfirmation ? "Escribe 'sí' o 'no'" :
                    //context.options ? "Elige una opción..." :
                    "Escribe tu respuesta..."
                  }
                />
                <button
                  onClick={handleUserResponse}
                  className="btn btn-primary d-flex align-items-center gap-2"
                  disabled={!userResponse.trim()}
                >
                  <FontAwesomeIcon icon={faPaperPlane} />
                </button>
              </div>
            )}
          </>
        )}
      </div>

      {isReceived && displayedContent && (
        <div className="absolute top-2 right-2 d-flex gap-3 align-items-center">
          <button
            onClick={toggleVoice}
            className="p-1 rounded-circle hover-bg-light transition"
            style={{ border: 'none', background: 'transparent', fontSize: '15px', cursor: 'pointer' }}
            title={`Current: ${selectedVoice === 'spanish' ? 'Español' : selectedVoice === 'english' ? 'English' : selectedVoice === 'portuguese' ? 'Português' : selectedVoice === 'arabic' ? 'العربية' : 'العربية'}`}
          >
          {selectedVoice === 'spanish' ? '🇪🇸' : selectedVoice === 'english' ? '🇺🇸' : selectedVoice === 'portuguese' ? '🇧🇷' : selectedVoice === 'arabic' ? '🇸🇦' : '🇸🇦'}
          </button>
          <button
            onClick={handleSpeak}
            className="p-1 rounded-circle hover-bg-light transition"
            style={{ border: 'none', background: 'transparent', fontSize: '14px', cursor: 'pointer' }}
            title={isPlaying ? "Pause audio" : "Play audio"}
          >
            <FontAwesomeIcon 
              icon={isPlaying ? faPause : faVolumeUp} 
              className="text-secondary hover-text-dark"
            />
          </button>
          <div 
            className="position-relative w-32 h-2 bg-light rounded"
            style={{ minWidth: '128px', cursor: 'pointer' }}
          >
            <div 
              className="position-absolute top-0 start-0 h-100 bg-primary rounded transition"
              style={{ 
                width: `${audioProgress}%`,
                backgroundColor: isPlaying ? '#0d6efd' : '#6c757d'
              }}
            />
            <div 
              className="position-absolute h-3 w-3 rounded-circle bg-white border border-primary shadow-sm transition"
              style={{ 
                left: `${audioProgress}%`,
                transform: 'translateX(-50%)',
                display: isPlaying ? 'block' : 'none'
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const Message: React.FC<MessageProps> = ({ message, user, showActions = true, onResponse }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { lightboxProps, openLightbox } = useLightbox(
    message.attachments?.images || []
  );

  const showCollapseButton = message.type === 'received';

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="d-flex chat-message">
      <div
        className={classNames('d-flex flex-1', {
          'justify-content-end': message.type === 'sent'
        })}
      >
        <div
          className={classNames('', {
            'w-xxl-75': showActions
          })}
          style={{ maxWidth: '100%' }}
        >
          {showCollapseButton && (
            <div className="d-flex justify-content-start mb-1">
              <button 
                onClick={toggleAccordion}
                className={classNames(
                  'border-0 bg-transparent p-1 rounded-circle d-flex align-items-center justify-content-center',
                  'hover-bg-light transition',
                  'text-body-tertiary'
                )}
                style={{ 
                  width: '20px', 
                  height: '20px',
                  fontSize: '12px',
                  cursor: 'pointer'
                }}
              >
                <FontAwesomeIcon 
                  icon={isExpanded ? faChevronUp : faChevronDown}
                  className="text-secondary hover-text-dark"
                />
              </button>
            </div>
          )}

          {(!showCollapseButton || isExpanded) && (
            <>
              <div
                className={classNames('d-flex hover-actions-trigger', {
                  'flex-end-center': message.type === 'sent'
                })}
              >
                {message.type === 'received' && (
                  <div className='me-2'></div>
                )}
                {message.type === 'sent' && showActions && (
                  <MessageActionButtons actions={actions} variant="sent" />
                )}
                <div
                  className={classNames('me-2', {
                    received: message.type === 'received'
                  })}
                  style={{ maxWidth: '100%', flex: '1 1 auto' }}
                >
                  <div
                    className={classNames('mb-0', {
                      'sent-message-content': message.type === 'sent',
                      'received-message-content border border-translucent':
                        message.type === 'received',
                      attachments:
                        Boolean(message.attachments?.images?.length) &&
                        !message.message
                    })}
                    style={{ maxWidth: '100%' }}
                  >
                    {message.message && (
                      <div className="mb-0 message-container" style={{
                        maxWidth: '100%',
                        overflow: 'hidden'
                      }}>
                        <MessageContent 
                          content={message.message} 
                          isReceived={message.type === 'received'}
                          isLoading={message.isTemporary}
                          isFinished={message.isFinished}
                          onResponse={onResponse}
                          context={message.conversationContext}
                        />
                      </div>
                    )}
                    {message.attachments?.images && (
                      <MessageAttachments
                        attachments={message.attachments.images}
                        openLightbox={openLightbox}
                      />
                    )}
                    {message.attachments?.file && (
                      <AttachmentPreview
                        attachment={message.attachments.file}
                        variant={
                          message.type === 'received' ? 'primary' : 'secondary'
                        }
                      />
                    )}
                  </div>
                </div>
                {message.type === 'received' && showActions && (
                  <MessageActionButtons
                    actions={actions.slice(1)}
                    variant="received"
                  />
                )}
              </div>
              <div
                className={classNames('d-flex gap-1 fs-10', {
                  'ms-7': message.type === 'received',
                  'justify-content-end': message.type === 'sent'
                })}
              >
                <p className="mb-0 text-body-tertiary text-opacity-85 fw-semibold">
                  {message.time}
                </p>
                {message.readAt && (
                  <FontAwesomeIcon icon={faCheckDouble} className="text-success" />
                )}
              </div>
              <Lightbox {...lightboxProps} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;

/* @v3.6 - {autoplay: "hello anubis", [test] } { interactúa perfecto } - buscar detección de idioma para contestar audio
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Avatar from 'components/base/Avatar';
import Lightbox from 'components/base/LightBox';
import { Message as MessageType, User, actions } from 'data/hazbot/chat';
import useLightbox from 'hooks/useLightbox';
import MessageActionButtons from './MessageActionButtons';
import MessageAttachments from './MessageAttachments';
import AttachmentPreview from 'components/common/AttachmentPreview';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { KatexOptions } from 'katex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCheckDouble, 
  faChevronDown, 
  faChevronUp,
  faVolumeUp,
  faPause,
  faPaperPlane
} from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';

const VOICE_CONFIGS = {
  spanish: {
    name: 'Microsoft Helena - Spanish (Spain)',
    backupNames: [
      'Helena',
      'Microsoft Helena',
      'Google español',
      'Paulina',
      'Monica'
    ],
    lang: 'es-MX',
    pitch: 1.1,
    rate: 0.9,
    volume: 0.95
  },
  english: {
    name: 'Microsoft Samantha',
    backupNames: [
      'Samantha',
      'Alex',
      'Karen',
      'Victoria',
      'Google US English Female'
    ],
    lang: 'en-US',
    pitch: 1.05,
    rate: 0.9,
    volume: 0.95
  },
  portuguese: {
    name: 'Microsoft Maria - Portuguese (Brazil)',
    backupNames: [
      'Maria',
      'Microsoft Maria',
      'Google português',
      'Luciana',
      'Joana'
    ],
    lang: 'pt-BR',
    pitch: 1.15,
    rate: 0.9,
    volume: 0.95
  }
};

interface CodeProps {
  inline?: boolean;
  className?: string;
  children: React.ReactNode;
  node?: any;
}

interface PreProps {
  children: React.ReactNode;
}

interface MessageProps {
  message: MessageType;
  user: User;
  showActions?: boolean;
  onResponse?: (response: string) => void;
}

interface ConversationContext {
  needsClarification?: boolean;
  pendingConfirmation?: boolean;
  options?: string[];
  lastQuestion?: string;
}

const MessageContent: React.FC<{ 
  content: string; 
  isReceived: boolean;
  isLoading: boolean | undefined;
  isFinished: boolean | undefined;
  onResponse?: (response: string) => void;
  context?: ConversationContext;
}> = ({ 
  content, 
  isReceived, 
  isLoading,
  isFinished,
  onResponse,
  context
}) => {
  const [displayedContent, setDisplayedContent] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState<'spanish' | 'english' | 'portuguese'>('english');
  const [audioProgress, setAudioProgress] = useState(0);
  const [audioDuration, setAudioDuration] = useState(0);
  const [userResponse, setUserResponse] = useState('');
  const [showResponseInput, setShowResponseInput] = useState(false);
  
  const [hasSpoken, setHasSpoken] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const speechRef = useRef<SpeechSynthesisUtterance | null>(null);
  const progressIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const processBlockMath = (text: string): string => {
    if (!text || typeof text !== 'string') return '';
    return text.replace(/\\\[([\s\S]*?)\\\]/g, (_, math) => `\n$$${math}$$\n`);
  };

  const processInlineMath = (text: string): string => {
    if (!text || typeof text !== 'string') return '';
    return text.replace(/\\\((.*?)\\\)/g, (_, math) => `$${math}$`);
  };

  const katexOptions: KatexOptions = {
    strict: false,
    throwOnError: false,
    displayMode: true,
    trust: true,
  };

  // Efecto para procesar el contenido y determinar la interactividad
  useEffect(() => {
    if (!content || typeof content !== 'string') {
      setDisplayedContent('');
      setShowResponseInput(false);
      return;
    }

    let processedContent = processInlineMath(processBlockMath(content));

    if (isReceived && context) { 
      if (context.needsClarification) {
        processedContent += '\n\n> *¿Podrías proporcionar más detalles sobre esto?*';
        setShowResponseInput(true);
      } else if (context.pendingConfirmation) {
        processedContent += '\n\n> *¿Confirmas esta acción?*';
        setShowResponseInput(true);
      } else if (context.options?.length) {
        processedContent += '\n\n> *Por favor, elige una opción:*\n';
        context.options.forEach((opt, i) => {
          processedContent += `> ${i + 1}. ${opt}\n`;
        });
        setShowResponseInput(true);
      } else {
        setShowResponseInput(false);
      }
    } else {
      setShowResponseInput(false);
    }

    setDisplayedContent(processedContent);
  }, [content, isReceived, context]);

  // Inicializar las voces
  useEffect(() => {
    const initVoices = () => {
      const synth = window.speechSynthesis;
      synth.getVoices();
    };

    if (typeof window !== 'undefined') {
      initVoices();
      window.speechSynthesis.onvoiceschanged = initVoices;
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.speechSynthesis.onvoiceschanged = null;
        window.speechSynthesis.cancel();
        if (progressIntervalRef.current) {
          clearInterval(progressIntervalRef.current);
        }
      }
    };
  }, []);

  // Efecto para autoplay cuando el contenido se establece
  useEffect(() => {
    if (isReceived && isFinished && displayedContent && !hasSpoken) {
      const lowercaseContent = displayedContent.toLowerCase();
  
      if (
        ['hi anubis', 'hello anubis', process.env.REACT_APP_NAME?.toLowerCase()]
          .some(term => term && lowercaseContent.includes(term.toLowerCase()))
      ) {
        handleSpeak();
        setHasSpoken(true);
      }
    }
  }, [isReceived, isFinished, displayedContent]); // Dependemos de estos valoresv

  const findBestVoiceMatch = (voiceConfig: typeof VOICE_CONFIGS.spanish | typeof VOICE_CONFIGS.english | typeof VOICE_CONFIGS.portuguese) => {
    const availableVoices = window.speechSynthesis.getVoices();
    let voice = availableVoices.find(v => v.name === voiceConfig.name);

    if (!voice) {
      for (const backupName of voiceConfig.backupNames) {
        voice = availableVoices.find(v =>
          v.name.toLowerCase().includes(backupName.toLowerCase()) &&
          v.lang.startsWith(voiceConfig.lang.split('-')[0])
        );
        if (voice) break;
      }
    }

    if (!voice) {
      voice = availableVoices.find(v =>
        v.lang.startsWith(voiceConfig.lang.split('-')[0]) &&
        v.name.toLowerCase().includes('female')
      );
    }

    if (!voice) {
      voice = availableVoices.find(v =>
        v.lang.startsWith(voiceConfig.lang.split('-')[0])
      );
    }

    return voice;
  };

  const updateAudioProgress = () => {
    if (speechRef.current) {
      const elapsedTime = window.speechSynthesis.speaking ? performance.now() - (speechRef.current as any).startTime : 0;
      const progress = Math.min((elapsedTime / ((speechRef.current as any).duration || 1)) * 100, 100);
      setAudioProgress(progress);

      if (progress >= 100) {
        if (progressIntervalRef.current) {
          clearInterval(progressIntervalRef.current);
        }
        setIsPlaying(false);
        setAudioProgress(0);
      }
    }
  };

  const handleSpeak = () => {
    if (!displayedContent) return;

    if (isPlaying) {
      window.speechSynthesis.cancel();
      setIsPlaying(false);
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
      setAudioProgress(0);
      return;
    }

    const voiceConfig = VOICE_CONFIGS[selectedVoice];
    speechRef.current = new SpeechSynthesisUtterance(displayedContent);

    const voice = findBestVoiceMatch(voiceConfig);
    if (voice) {
      speechRef.current.voice = voice;
      speechRef.current.lang = voiceConfig.lang;
      speechRef.current.pitch = voiceConfig.pitch;
      speechRef.current.rate = voiceConfig.rate;
      speechRef.current.volume = voiceConfig.volume;
    }

    (speechRef.current as any).startTime = performance.now();
    (speechRef.current as any).duration = (displayedContent.length / voiceConfig.rate) * 100; // Estimación
    setAudioDuration((speechRef.current as any).duration);

    speechRef.current.onend = () => {
      setIsPlaying(false);
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
      setAudioProgress(0);
    };

    speechRef.current.onstart = () => {
      progressIntervalRef.current = setInterval(updateAudioProgress, 100);
    };

    window.speechSynthesis.speak(speechRef.current);
    setIsPlaying(true);
  };

  const toggleVoice = () => {
    setSelectedVoice(prev => prev === 'spanish' ? 'english' : prev === 'english' ? 'portuguese' : 'spanish');
    if (isPlaying) {
      window.speechSynthesis.cancel();
      setIsPlaying(false);
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
      setAudioProgress(0);
    }
  };

  const handleUserResponse = () => {
    if (!userResponse.trim() || !onResponse) return;
    onResponse(userResponse);
    setUserResponse('');
  };

  const CustomCode: React.FC<CodeProps> = ({ 
    inline = false, 
    className, 
    children,
    ...props 
  }) => {
    const style = {
      display: 'block',
      maxWidth: '100%',
      overflow: 'auto',
      wordWrap: 'break-word' as const,
      whiteSpace: inline ? 'normal' as const : 'pre-wrap' as const
    };
    return (
      <code className={className} style={style} {...props}>
        {children}
      </code>
    );
  };

  const CustomPre: React.FC<PreProps> = ({ children }) => (
    <pre style={{ maxWidth: '100%', overflow: 'auto', marginBottom: '1rem' }}>
      {children}
    </pre>
  );

  if (isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center p-2">
        <Spinner animation="grow" />
      </div>
    );
  }

  return (
    <div className="relative">
      <div 
        ref={containerRef} 
        className="prose max-w-none message-scroll-container"
        style={{ display: 'block', maxWidth: '100%', overflow: 'hidden', wordWrap: 'break-word' }}
      >
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" size="sm" />
          </div>
        ) : (
          <>
            <ReactMarkdown
              remarkPlugins={[remarkMath]}
              rehypePlugins={[[rehypeKatex, katexOptions]]}
              components={{
                code: CustomCode as any,
                pre: CustomPre as any
              }}
            >
              {displayedContent || ''}
            </ReactMarkdown>

            {showResponseInput && isReceived && (
              <div className="mt-3 d-flex gap-2">
                <input
                  ref={inputRef}
                  type="text"
                  value={userResponse}
                  onChange={(e) => setUserResponse(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && handleUserResponse()}
                  className="form-control"
                  placeholder={
                    //context.needsClarification ? "Proporciona más detalles..." :
                    //context.pendingConfirmation ? "Escribe 'sí' o 'no'" :
                    //context.options ? "Elige una opción..." :
                    "Escribe tu respuesta..."
                  }
                />
                <button
                  onClick={handleUserResponse}
                  className="btn btn-primary d-flex align-items-center gap-2"
                  disabled={!userResponse.trim()}
                >
                  <FontAwesomeIcon icon={faPaperPlane} />
                </button>
              </div>
            )}
          </>
        )}
      </div>

      {isReceived && displayedContent && (
        <div className="absolute top-2 right-2 d-flex gap-3 align-items-center">
          <button
            onClick={toggleVoice}
            className="p-1 rounded-circle hover-bg-light transition"
            style={{ border: 'none', background: 'transparent', fontSize: '15px', cursor: 'pointer' }}
            title={`Current: ${selectedVoice === 'spanish' ? 'Español' : selectedVoice === 'english' ? 'English' : 'Português'}`}
          >
            {selectedVoice === 'spanish' ? '🇪🇸' : selectedVoice === 'english' ? '🇺🇸' : '🇧🇷'}
          </button>
          <button
            onClick={handleSpeak}
            className="p-1 rounded-circle hover-bg-light transition"
            style={{ border: 'none', background: 'transparent', fontSize: '14px', cursor: 'pointer' }}
            title={isPlaying ? "Pause audio" : "Play audio"}
          >
            <FontAwesomeIcon 
              icon={isPlaying ? faPause : faVolumeUp} 
              className="text-secondary hover-text-dark"
            />
          </button>
          <div 
            className="position-relative w-32 h-2 bg-light rounded"
            style={{ minWidth: '128px', cursor: 'pointer' }}
          >
            <div 
              className="position-absolute top-0 start-0 h-100 bg-primary rounded transition"
              style={{ 
                width: `${audioProgress}%`,
                backgroundColor: isPlaying ? '#0d6efd' : '#6c757d'
              }}
            />
            <div 
              className="position-absolute h-3 w-3 rounded-circle bg-white border border-primary shadow-sm transition"
              style={{ 
                left: `${audioProgress}%`,
                transform: 'translateX(-50%)',
                display: isPlaying ? 'block' : 'none'
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const Message: React.FC<MessageProps> = ({ message, user, showActions = true, onResponse }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { lightboxProps, openLightbox } = useLightbox(
    message.attachments?.images || []
  );

  const showCollapseButton = message.type === 'received';

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="d-flex chat-message">
      <div
        className={classNames('d-flex flex-1', {
          'justify-content-end': message.type === 'sent'
        })}
      >
        <div
          className={classNames('', {
            'w-xxl-75': showActions
          })}
          style={{ maxWidth: '100%' }}
        >
          {showCollapseButton && (
            <div className="d-flex justify-content-start mb-1">
              <button 
                onClick={toggleAccordion}
                className={classNames(
                  'border-0 bg-transparent p-1 rounded-circle d-flex align-items-center justify-content-center',
                  'hover-bg-light transition',
                  'text-body-tertiary'
                )}
                style={{ 
                  width: '20px', 
                  height: '20px',
                  fontSize: '12px',
                  cursor: 'pointer'
                }}
              >
                <FontAwesomeIcon 
                  icon={isExpanded ? faChevronUp : faChevronDown}
                  className="text-secondary hover-text-dark"
                />
              </button>
            </div>
          )}

          {(!showCollapseButton || isExpanded) && (
            <>
              <div
                className={classNames('d-flex hover-actions-trigger', {
                  'flex-end-center': message.type === 'sent'
                })}
              >
                {message.type === 'received' && (
                  <div className='me-2'></div>
                )}
                {message.type === 'sent' && showActions && (
                  <MessageActionButtons actions={actions} variant="sent" />
                )}
                <div
                  className={classNames('me-2', {
                    received: message.type === 'received'
                  })}
                  style={{ maxWidth: '100%', flex: '1 1 auto' }}
                >
                  <div
                    className={classNames('mb-0', {
                      'sent-message-content': message.type === 'sent',
                      'received-message-content border border-translucent':
                        message.type === 'received',
                      attachments:
                        Boolean(message.attachments?.images?.length) &&
                        !message.message
                    })}
                    style={{ maxWidth: '100%' }}
                  >
                    {message.message && (
                      <div className="mb-0 message-container" style={{
                        maxWidth: '100%',
                        overflow: 'hidden'
                      }}>
                        <MessageContent 
                          content={message.message} 
                          isReceived={message.type === 'received'}
                          isLoading={message.isTemporary}
                          isFinished={message.isFinished}
                          onResponse={onResponse}
                          context={message.conversationContext}
                        />
                      </div>
                    )}
                    {message.attachments?.images && (
                      <MessageAttachments
                        attachments={message.attachments.images}
                        openLightbox={openLightbox}
                      />
                    )}
                    {message.attachments?.file && (
                      <AttachmentPreview
                        attachment={message.attachments.file}
                        variant={
                          message.type === 'received' ? 'primary' : 'secondary'
                        }
                      />
                    )}
                  </div>
                </div>
                {message.type === 'received' && showActions && (
                  <MessageActionButtons
                    actions={actions.slice(1)}
                    variant="received"
                  />
                )}
              </div>
              <div
                className={classNames('d-flex gap-1 fs-10', {
                  'ms-7': message.type === 'received',
                  'justify-content-end': message.type === 'sent'
                })}
              >
                <p className="mb-0 text-body-tertiary text-opacity-85 fw-semibold">
                  {message.time}
                </p>
                {message.readAt && (
                  <FontAwesomeIcon icon={faCheckDouble} className="text-success" />
                )}
              </div>
              <Lightbox {...lightboxProps} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;

/* @v3.5 - {autoplay: NO FUNCIONA, sacrificamos command line - hi anubis, hello anubis, anubis } - NO INTERACTUA - buscar detección de idioma para contestar audio 
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Avatar from 'components/base/Avatar';
import Lightbox from 'components/base/LightBox';
import { Message as MessageType, User, actions } from 'data/hazbot/chat';
import useLightbox from 'hooks/useLightbox';
import MessageActionButtons from './MessageActionButtons';
import MessageAttachments from './MessageAttachments';
import AttachmentPreview from 'components/common/AttachmentPreview';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { KatexOptions } from 'katex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCheckDouble, 
  faChevronDown, 
  faChevronUp,
  faVolumeUp,
  faPause
} from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';

const VOICE_CONFIGS = {
  spanish: {
    name: 'Microsoft Helena - Spanish (Spain)',
    backupNames: [
      'Helena',
      'Microsoft Helena',
      'Google español',
      'Paulina',
      'Monica'
    ],
    lang: 'es-MX',
    pitch: 1.1,
    rate: 0.9,
    volume: 0.95
  },
  english: {
    name: 'Microsoft Samantha',
    backupNames: [
      'Samantha',
      'Alex',
      'Karen',
      'Victoria',
      'Google US English Female'
    ],
    lang: 'en-US',
    pitch: 1.05,
    rate: 0.9,
    volume: 0.95
  },
  portuguese: {
    name: 'Microsoft Maria - Portuguese (Brazil)',
    backupNames: [
      'Maria',
      'Microsoft Maria',
      'Google português',
      'Luciana',
      'Joana'
    ],
    lang: 'pt-BR',
    pitch: 1.15,
    rate: 0.9,
    volume: 0.95
  }
};

interface CodeProps {
  inline?: boolean;
  className?: string;
  children: React.ReactNode;
  node?: any;
}

interface PreProps {
  children: React.ReactNode;
}

interface MessageProps {
  message: MessageType;
  user: User;
  showActions?: boolean;
}

const MessageContent: React.FC<{ content: string; isReceived: boolean, isLoading: boolean | undefined }> = ({ content, isReceived, isLoading }) => {
  const [displayedContent, setDisplayedContent] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState<'spanish' | 'english' | 'portuguese'>('english');
  const [audioProgress, setAudioProgress] = useState(0);
  const [audioDuration, setAudioDuration] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const speechRef = useRef<SpeechSynthesisUtterance | null>(null);
  const progressIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const processBlockMath = (text: string): string => {
    if (!text || typeof text !== 'string') return '';
    return text.replace(/\\\[([\s\S]*?)\\\]/g, (_, math) => `\n$$${math}$$\n`);
  };

  const processInlineMath = (text: string): string => {
    if (!text || typeof text !== 'string') return '';
    return text.replace(/\\\((.*?)\\\)/g, (_, math) => `$${math}$`);
  };

  const katexOptions: KatexOptions = {
    strict: false,
    throwOnError: false,
    displayMode: true,
    trust: true,
  };

  useEffect(() => {
    if (!content || typeof content !== 'string') {
      setDisplayedContent('');
      return;
    }

    const processedContent = processInlineMath(processBlockMath(content));
    setDisplayedContent(processedContent);
  }, [content]);

  useEffect(() => {
    const initVoices = () => {
      const synth = window.speechSynthesis;
      synth.getVoices();
    };

    if (typeof window !== 'undefined') {
      initVoices();
      window.speechSynthesis.onvoiceschanged = initVoices;
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.speechSynthesis.onvoiceschanged = null;
        window.speechSynthesis.cancel();
        if (progressIntervalRef.current) {
          clearInterval(progressIntervalRef.current);
        }
      }
    };
  }, []);

  const findBestVoiceMatch = (voiceConfig: typeof VOICE_CONFIGS.spanish | typeof VOICE_CONFIGS.english) => {
    const availableVoices = window.speechSynthesis.getVoices();
    let voice = availableVoices.find(v => v.name === voiceConfig.name);

    if (!voice) {
      for (const backupName of voiceConfig.backupNames) {
        voice = availableVoices.find(v =>
          v.name.toLowerCase().includes(backupName.toLowerCase()) &&
          v.lang.startsWith(voiceConfig.lang.split('-')[0])
        );
        if (voice) break;
      }
    }

    if (!voice) {
      voice = availableVoices.find(v =>
        v.lang.startsWith(voiceConfig.lang.split('-')[0]) &&
        v.name.toLowerCase().includes('female')
      );
    }

    if (!voice) {
      voice = availableVoices.find(v =>
        v.lang.startsWith(voiceConfig.lang.split('-')[0])
      );
    }

    return voice;
  };

  const updateAudioProgress = () => {
    if (speechRef.current) {
      const elapsedTime = window.speechSynthesis.speaking ? performance.now() - (speechRef.current as any).startTime : 0;
      const progress = Math.min((elapsedTime / ((speechRef.current as any).duration || 1)) * 100, 100);
      setAudioProgress(progress);

      if (progress >= 100) {
        if (progressIntervalRef.current) {
          clearInterval(progressIntervalRef.current);
        }
        setIsPlaying(false);
        setAudioProgress(0);
      }
    }
  };

  const handleSpeak = () => {
    if (!displayedContent) return;

    if (isPlaying) {
      window.speechSynthesis.cancel();
      setIsPlaying(false);
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
      setAudioProgress(0);
      return;
    }

    const voiceConfig = VOICE_CONFIGS[selectedVoice];
    speechRef.current = new SpeechSynthesisUtterance(displayedContent);

    const voice = findBestVoiceMatch(voiceConfig);
    if (voice) {
      speechRef.current.voice = voice;
      speechRef.current.lang = voiceConfig.lang;
      speechRef.current.pitch = voiceConfig.pitch;
      speechRef.current.rate = voiceConfig.rate;
      speechRef.current.volume = voiceConfig.volume;
    }

    // Set start time and estimated duration
    (speechRef.current as any).startTime = performance.now();
    (speechRef.current as any).duration = (displayedContent.length / voiceConfig.rate) * 100; // Rough estimation
    setAudioDuration((speechRef.current as any).duration);

    speechRef.current.onend = () => {
      setIsPlaying(false);
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
      setAudioProgress(0);
    };

    speechRef.current.onstart = () => {
      progressIntervalRef.current = setInterval(updateAudioProgress, 100);
    };

    window.speechSynthesis.speak(speechRef.current);
    setIsPlaying(true);
  };

  const toggleVoice = () => {
    setSelectedVoice(prev => prev === 'spanish' ? 'english' : prev === 'english' ? 'portuguese' : 'spanish');
    if (isPlaying) {
      window.speechSynthesis.cancel();
      setIsPlaying(false);
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
      setAudioProgress(0);
    }
  };

  const CustomCode: React.FC<CodeProps> = ({ 
    inline = false, 
    className, 
    children,
    ...props 
  }) => {
    const style = {
      display: 'block',
      maxWidth: '100%',
      overflow: 'auto',
      wordWrap: 'break-word' as const,
      whiteSpace: inline ? 'normal' as const : 'pre-wrap' as const
    };
    return (
      <code className={className} style={style} {...props}>
        {children}
      </code>
    );
  };

  const CustomPre: React.FC<PreProps> = ({ children }) => (
    <pre style={{ maxWidth: '100%', overflow: 'auto', marginBottom: '1rem' }}>
      {children}
    </pre>
  );

  return (
    <div className="relative">
      <div 
        ref={containerRef} 
        className="prose max-w-none message-scroll-container"
        style={{ display: 'block', maxWidth: '100%', overflow: 'hidden', wordWrap: 'break-word' }}
      >
        {isLoading ? (
          <div className="d-flex justify-center items-center">
            <Spinner animation="border" size="sm" />
          </div>
        ) : (
          <ReactMarkdown
            remarkPlugins={[remarkMath]}
            rehypePlugins={[[rehypeKatex, katexOptions]]}
            components={{
              code: CustomCode as any,
              pre: CustomPre as any
            }}
          >
            {displayedContent || ''}
          </ReactMarkdown>
        )}
      </div>
      {isReceived && displayedContent && (
        <div className="absolute top-2 right-2 flex gap-3 items-center">
          <button
            onClick={toggleVoice}
            className="p-1 rounded-full hover:bg-gray-100 transition-colors"
            style={{ border: 'none', background: 'transparent', fontSize: '15px', cursor: 'pointer' }}
            title={`Current: ${selectedVoice === 'spanish' ? 'Español' : selectedVoice === 'english' ? 'English' : 'Português'}`}
          >
            {selectedVoice === 'spanish' ? '🇪🇸' : selectedVoice === 'english' ? '🇺🇸' : '🇧🇷'}
          </button>
          <button
            onClick={handleSpeak}
            className="p-1 rounded-full hover:bg-gray-100 transition-colors"
            style={{ border: 'none', background: 'transparent', fontSize: '14px', cursor: 'pointer' }}
            title={isPlaying ? "Pause audio" : "Play audio"}
          >
            <FontAwesomeIcon 
              icon={isPlaying ? faPause : faVolumeUp} 
              className="text-gray-500 hover:text-gray-700"
            />
          </button>
          <div 
            className="relative w-32 h-2 bg-gray-200 rounded cursor-pointer flex items-center"
            style={{ minWidth: '128px' }}
          >
            <div 
              className="absolute top-0 left-0 h-full bg-blue-500 rounded transition-all"
              style={{ 
                width: `${audioProgress}%`,
                backgroundColor: isPlaying ? '#3b82f6' : '#94a3b8'
              }}
            />
            <div 
              className="absolute h-3 w-3 rounded-full bg-white border border-blue-500 shadow-sm transition-all"
              style={{ 
                left: `${audioProgress}%`,
                transform: 'translateX(-50%)',
                display: isPlaying ? 'block' : 'none'
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const Message: React.FC<MessageProps> = ({ message, user, showActions = true }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { lightboxProps, openLightbox } = useLightbox(
    message.attachments?.images || []
  );

  const showCollapseButton = message.type === 'received';

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="d-flex chat-message">
      <div
        className={classNames('d-flex flex-1', {
          'justify-content-end': message.type === 'sent'
        })}
      >
        <div
          className={classNames('', {
            'w-xxl-75': showActions
          })}
          style={{ maxWidth: '100%' }}
        >
          {showCollapseButton && (
            <div className="d-flex justify-content-start mb-1">
              <button 
                onClick={toggleAccordion}
                className={classNames(
                  'border-0 bg-transparent p-1 rounded-circle d-flex align-items-center justify-content-center',
                  'hover:bg-light transition-colors',
                  'text-body-tertiary',
                  'cursor-pointer'
                )}
                style={{ 
                  width: '20px', 
                  height: '20px',
                  fontSize: '12px'
                }}
              >
                <FontAwesomeIcon 
                  icon={isExpanded ? faChevronUp : faChevronDown}
                  className="text-gray-500 hover:text-gray-700"
                />
              </button>
            </div>
          )}

          {(!showCollapseButton || isExpanded) && (
            <>
              <div
                className={classNames('d-flex hover-actions-trigger', {
                  'flex-end-center': message.type === 'sent'
                })}
              >
                {message.type === 'received' && (
                  <div className='me-2'></div>
                )}
                {message.type === 'sent' && showActions && (
                  <MessageActionButtons actions={actions} variant="sent" />
                )}
                <div
                  className={classNames('me-2', {
                    received: message.type === 'received'
                  })}
                  style={{ maxWidth: '100%', flex: '1 1 auto' }}
                >
                  <div
                    className={classNames('mb-0', {
                      'sent-message-content': message.type === 'sent',
                      'received-message-content border border-translucent':
                        message.type === 'received',
                      attachments:
                        Boolean(message.attachments?.images?.length) &&
                        !message.message
                    })}
                    style={{ maxWidth: '100%' }}
                  >
                    {message.message && (
                      <div className="mb-0 message-container" style={{
                        maxWidth: '100%',
                        overflow: 'hidden'
                      }}>
                        <MessageContent 
                          content={message.message} 
                          isReceived={message.type === 'received'}
                          isLoading={message.isTemporary}
                        />
                      </div>
                    )}
                    {message.attachments?.images && (
                      <MessageAttachments
                        attachments={message.attachments.images}
                        openLightbox={openLightbox}
                      />
                    )}
                    {message.attachments?.file && (
                      <AttachmentPreview
                        attachment={message.attachments.file}
                        variant={
                          message.type === 'received' ? 'primary' : 'secondary'
                        }
                      />
                    )}
                  </div>
                </div>
                {message.type === 'received' && showActions && (
                  <MessageActionButtons
                    actions={actions.slice(1)}
                    variant="received"
                  />
                )}
              </div>
              <div
                className={classNames('d-flex gap-1 fs-10', {
                  'ms-7': message.type === 'received',
                  'justify-content-end': message.type === 'sent'
                })}
              >
                <p className="mb-0 text-body-tertiary text-opacity-85 fw-semibold">
                  {message.time}
                </p>
                {message.readAt && (
                  <FontAwesomeIcon icon={faCheckDouble} className="text-success" />
                )}
              </div>
              <Lightbox {...lightboxProps} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;


/* @v3.4 - {autoplay: hi anubis, hello anubis, anubis } - buscar detección de idioma para contestar audio  y barra de progreso play
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Avatar from 'components/base/Avatar';
import Lightbox from 'components/base/LightBox';
import { Message as MessageType, User, actions } from 'data/hazbot/chat';
import useLightbox from 'hooks/useLightbox';
import MessageActionButtons from './MessageActionButtons';
import MessageAttachments from './MessageAttachments';
import AttachmentPreview from 'components/common/AttachmentPreview';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { KatexOptions } from 'katex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCheckDouble, 
  faChevronDown, 
  faChevronUp,
  faVolumeUp,
  faPause
} from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';

const VOICE_CONFIGS = {
  spanish: {
    name: 'Microsoft Helena - Spanish (Spain)',
    backupNames: [
      'Helena',
      'Microsoft Helena',
      'Google español',
      'Paulina',
      'Monica'
    ],
    lang: 'es-MX',
    pitch: 1.1,
    rate: 0.9,
    volume: 0.95
  },
  english: {
    name: 'Microsoft Samantha',
    backupNames: [
      'Samantha',
      'Alex',
      'Karen',
      'Victoria',
      'Google US English Female'
    ],
    lang: 'en-US',
    pitch: 1.05,
    rate: 0.9,
    volume: 0.95
  },
  portuguese: {
    name: 'Microsoft Maria - Portuguese (Brazil)',
    backupNames: [
      'Maria',
      'Microsoft Maria',
      'Google português',
      'Luciana',
      'Joana'
    ],
    lang: 'pt-BR',
    pitch: 1.1,
    rate: 0.9,
    volume: 0.95
  }
};

interface CodeProps {
  inline?: boolean;
  className?: string;
  children: React.ReactNode;
  node?: any;
}

interface PreProps {
  children: React.ReactNode;
}

interface MessageProps {
  message: MessageType;
  user: User;
  showActions?: boolean;
}

const MessageContent: React.FC<{ content: string; isReceived: boolean }> = ({ content, isReceived }) => {
  const [displayedContent, setDisplayedContent] = useState('');
  const [isAnimating, setIsAnimating] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState<'spanish' | 'english' | 'portuguese'>('english');
  const containerRef = useRef<HTMLDivElement>(null);
  const speechRef = useRef<SpeechSynthesisUtterance | null>(null);
  const shouldAutoplay = useRef(false);

  const processBlockMath = (text: string): string => {
    if (!text || typeof text !== 'string') return '';
    return text.replace(/\\\[([\s\S]*?)\\\]/g, (_, math) => `\n$$${math}$$\n`);
  };

  const processInlineMath = (text: string): string => {
    if (!text || typeof text !== 'string') return '';
    return text.replace(/\\\((.*?)\\\)/g, (_, math) => `$${math}$`);
  };

  const katexOptions: KatexOptions = {
    strict: false,
    throwOnError: false,
    displayMode: true,
    trust: true,
  };

  useEffect(() => {
    if (!content || typeof content !== 'string') {
      setDisplayedContent('');
      setIsAnimating(false);
      return;
    }

    if (!isReceived) {
      const processedContent = processInlineMath(processBlockMath(content));
      setDisplayedContent(processedContent);
      setIsAnimating(false);
      return;
    }

    const processedContent = processInlineMath(processBlockMath(content));
    const cleanContent = processedContent.replace(/undefined/g, '');
    const words = cleanContent.split(/(\s+)/).filter(word => 
      word !== undefined && 
      word !== null && 
      word !== 'undefined' && 
      typeof word === 'string'
    );

    let currentIndex = 0;
    let timeoutId: NodeJS.Timeout;

    const animateText = () => {
      if (currentIndex < words.length) {
        const word = words[currentIndex];
        if (word && typeof word === 'string') {
          setDisplayedContent(prev => prev + word);
        }
        currentIndex++;
        timeoutId = setTimeout(animateText, 70);

        if (containerRef.current) {
          const messageContainer = containerRef.current.closest('.message-container');
          if (messageContainer) {
            requestAnimationFrame(() => {
              messageContainer.scrollTop = messageContainer.scrollHeight;
            });
          }
        }
      } else {
        setIsAnimating(false);
        shouldAutoplay.current = true;
      }
    };

    setDisplayedContent('');
    setIsAnimating(true);
    currentIndex = 0;
    animateText();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      setIsAnimating(false);
    };
  }, [content, isReceived]);

  useEffect(() => {
    if (!isAnimating && shouldAutoplay.current && isReceived && displayedContent) {
      const lowercaseContent = displayedContent.toLowerCase();
      if (
        ['hi anubis', 'hello anubis', process.env.REACT_APP_NAME].some(term => 
          term && lowercaseContent.includes(term.toLowerCase())
        )
      ) {
        handleSpeak();
      }
      shouldAutoplay.current = false;
    }
  }, [isAnimating, displayedContent, isReceived]); //funcional

  useEffect(() => {
    const initVoices = () => {
      const synth = window.speechSynthesis;
      synth.getVoices();
    };

    if (typeof window !== 'undefined') {
      initVoices();
      window.speechSynthesis.onvoiceschanged = initVoices;
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.speechSynthesis.onvoiceschanged = null;
        window.speechSynthesis.cancel();
      }
    };
  }, []);

  const findBestVoiceMatch = (voiceConfig: typeof VOICE_CONFIGS.spanish | typeof VOICE_CONFIGS.english) => {
    const availableVoices = window.speechSynthesis.getVoices();
    
    let voice = availableVoices.find(v => v.name === voiceConfig.name);
    
    if (!voice) {
      for (const backupName of voiceConfig.backupNames) {
        voice = availableVoices.find(v => 
          v.name.toLowerCase().includes(backupName.toLowerCase()) && 
          v.lang.startsWith(voiceConfig.lang.split('-')[0])
        );
        if (voice) break;
      }
    }
    
    if (!voice) {
      voice = availableVoices.find(v => 
        v.lang.startsWith(voiceConfig.lang.split('-')[0]) && 
        v.name.toLowerCase().includes('female')
      );
    }
    
    if (!voice) {
      voice = availableVoices.find(v => 
        v.lang.startsWith(voiceConfig.lang.split('-')[0])
      );
    }
    
    return voice;
  };

  const handleSpeak = () => {
    if (!displayedContent) return;
    
    if (isPlaying) {
      window.speechSynthesis.cancel();
      setIsPlaying(false);
      return;
    }

    const voiceConfig = VOICE_CONFIGS[selectedVoice];
    speechRef.current = new SpeechSynthesisUtterance(displayedContent);
    
    const voice = findBestVoiceMatch(voiceConfig);
    if (voice) {
      speechRef.current.voice = voice;
      speechRef.current.lang = voiceConfig.lang;
      speechRef.current.pitch = voiceConfig.pitch;
      speechRef.current.rate = voiceConfig.rate;
      speechRef.current.volume = voiceConfig.volume;
    }

    speechRef.current.onend = () => setIsPlaying(false);
    
    window.speechSynthesis.speak(speechRef.current);
    setIsPlaying(true);
  };

  const toggleVoice = () => {
    setSelectedVoice(prev => prev === 'spanish' ? 'english' : prev === 'english' ? 'portuguese' : 'spanish');
    if (isPlaying) {
      window.speechSynthesis.cancel();
      setIsPlaying(false);
    }
  };

  const CustomCode: React.FC<CodeProps> = ({ 
    inline = false, 
    className, 
    children,
    ...props 
  }) => {
    const style = {
      display: 'block',
      maxWidth: '100%',
      overflow: 'auto',
      wordWrap: 'break-word' as const,
      whiteSpace: inline ? 'normal' as const : 'pre-wrap' as const
    };
    return (
      <code className={className} style={style} {...props}>
        {children}
      </code>
    );
  };

  const CustomPre: React.FC<PreProps> = ({ children }) => (
    <pre style={{ 
      maxWidth: '100%', 
      overflow: 'auto',
      marginBottom: '1rem'
    }}>
      {children}
    </pre>
  );

  return (
    <div className="relative">
      <div 
        ref={containerRef} 
        className="prose max-w-none message-scroll-container"
        style={{ 
          display: 'block',
          maxWidth: '100%',
          overflow: 'hidden',
          wordWrap: 'break-word',
          overflowWrap: 'break-word'
        }}
      >
        <ReactMarkdown
          remarkPlugins={[remarkMath]}
          rehypePlugins={[[rehypeKatex, katexOptions]]}
          components={{
            code: CustomCode as any,
            pre: CustomPre as any
          }}
        >
          {displayedContent || ''}
        </ReactMarkdown>
        {isAnimating && isReceived && displayedContent && (
          <div className="d-flex align-items-center gap-2">
            <Spinner animation="border" size="sm" />
          </div>
        )}
      </div>
      {isReceived && displayedContent && !isAnimating && (
        <div className="absolute top-2 right-2 flex gap-2">
          <button
            onClick={toggleVoice}
            className="p-1 rounded-full hover:bg-gray-100 transition-colors"
            style={{ 
              border: 'none',
              background: 'transparent',
              fontSize: '15px',
              cursor: 'pointer'
            }}
            title={`Current: ${selectedVoice === 'spanish' ? 'Español' : selectedVoice === 'english' ? 'English' : 'Português'}`}
          >
            {selectedVoice === 'spanish' ? '🇪🇸' : selectedVoice === 'english' ? '🇺🇸' : '🇧🇷'}
          </button>
          <button
            onClick={handleSpeak}
            className="p-1 rounded-full hover:bg-gray-100 transition-colors"
            style={{ 
              border: 'none',
              background: 'transparent',
              fontSize: '14px',
              cursor: 'pointer'
            }}
            title={isPlaying ? "Pause audio" : "Play audio"}
          >
            <FontAwesomeIcon 
              icon={isPlaying ? faPause : faVolumeUp} 
              className="text-gray-500 hover:text-gray-700"
            />
          </button>
        </div>
      )}
    </div>
  );
};

const Message: React.FC<MessageProps> = ({ message, user, showActions = true }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { lightboxProps, openLightbox } = useLightbox(
    message.attachments?.images || []
  );

  const showCollapseButton = message.type === 'received';

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="d-flex chat-message">
      <div
        className={classNames('d-flex flex-1', {
          'justify-content-end': message.type === 'sent'
        })}
      >
        <div
          className={classNames('', {
            'w-xxl-75': showActions
          })}
          style={{ maxWidth: '100%' }}
        >
          {showCollapseButton && (
            <div className="d-flex justify-content-start mb-1">
              <button 
                onClick={toggleAccordion}
                className={classNames(
                  'border-0 bg-transparent p-1 rounded-circle d-flex align-items-center justify-content-center',
                  'hover:bg-light transition-colors',
                  'text-body-tertiary',
                  'cursor-pointer'
                )}
                style={{ 
                  width: '20px', 
                  height: '20px',
                  fontSize: '12px'
                }}
              >
                <FontAwesomeIcon 
                  icon={isExpanded ? faChevronUp : faChevronDown}
                  className="text-gray-500 hover:text-gray-700"
                />
              </button>
            </div>
          )}

          {(!showCollapseButton || isExpanded) && (
            <>
              <div
                className={classNames('d-flex hover-actions-trigger', {
                  'flex-end-center': message.type === 'sent'
                })}
              >
                {message.type === 'received' && (
                  <div className='me-2'> </div>
                )}
                {message.type === 'sent' && showActions && (
                  <MessageActionButtons actions={actions} variant="sent" />
                )}
                <div
                  className={classNames('me-2', {
                    received: message.type === 'received'
                  })}
                  style={{ maxWidth: '100%', flex: '1 1 auto' }}
                >
                  <div
                    className={classNames('mb-0', {
                      'sent-message-content': message.type === 'sent',
                      'received-message-content border border-translucent':
                        message.type === 'received',
                      attachments:
                        Boolean(message.attachments?.images?.length) &&
                        !message.message
                    })}
                    style={{ maxWidth: '100%' }}
                  >
                    {message.message && (
                      <div className="mb-0 message-container" style={{
                        maxWidth: '100%',
                        overflow: 'hidden'
                      }}>
                        <MessageContent 
                          content={message.message} 
                          isReceived={message.type === 'received'}
                        />
                      </div>
                    )}
                    {message.attachments?.images && (
                      <MessageAttachments
                        attachments={message.attachments.images}
                        openLightbox={openLightbox}
                      />
                    )}
                    {message.attachments?.file && (
                      <AttachmentPreview
                        attachment={message.attachments.file}
                        variant={
                          message.type === 'received' ? 'primary' : 'secondary'
                        }
                      />
                    )}
                  </div>
                </div>
                {message.type === 'received' && showActions && (
                  <MessageActionButtons
                    actions={actions.slice(1)}
                    variant="received"
                  />
                )}
              </div>
              <div
                className={classNames('d-flex gap-1 fs-10', {
                  'ms-7': message.type === 'received',
                  'justify-content-end': message.type === 'sent'
                })}
              >
                <p className="mb-0 text-body-tertiary text-opacity-85 fw-semibold">
                  {message.time}
                </p>
                {message.readAt && (
                  <FontAwesomeIcon icon={faCheckDouble} className="text-success" />
                )}
              </div>
              <Lightbox {...lightboxProps} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;



/* @v3.3 - {autoplay: hola }
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Avatar from 'components/base/Avatar';
import Lightbox from 'components/base/LightBox';
import { Message as MessageType, User, actions } from 'data/hazbot/chat';
import useLightbox from 'hooks/useLightbox';
import MessageActionButtons from './MessageActionButtons';
import MessageAttachments from './MessageAttachments';
import AttachmentPreview from 'components/common/AttachmentPreview';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { KatexOptions } from 'katex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCheckDouble, 
  faChevronDown, 
  faChevronUp,
  faVolumeUp,
  faPause
} from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';

const VOICE_CONFIGS = {
  spanish: {
    name: 'Microsoft Helena - Spanish (Spain)',
    backupNames: [
      'Helena',
      'Microsoft Helena',
      'Google español',
      'Paulina',
      'Monica'
    ],
    lang: 'es-MX',
    pitch: 1.1,
    rate: 0.9,
    volume: 0.95
  },
  english: {
    name: 'Microsoft Samantha',
    backupNames: [
      'Samantha',
      'Alex',
      'Karen',
      'Victoria',
      'Google US English Female'
    ],
    lang: 'en-US',
    pitch: 1.05,
    rate: 0.9,
    volume: 0.95
  },
  portuguese: {
    name: 'Microsoft Maria - Portuguese (Brazil)',
    backupNames: [
      'Maria',
      'Microsoft Maria',
      'Google português',
      'Luciana',
      'Joana'
    ],
    lang: 'pt-BR',
    pitch: 1.1,
    rate: 0.9,
    volume: 0.95
  }
};

interface CodeProps {
  inline?: boolean;
  className?: string;
  children: React.ReactNode;
  node?: any;
}

interface PreProps {
  children: React.ReactNode;
}

interface MessageProps {
  message: MessageType;
  user: User;
  showActions?: boolean;
}

const MessageContent: React.FC<{ content: string; isReceived: boolean }> = ({ content, isReceived }) => {
  const [displayedContent, setDisplayedContent] = useState('');
  const [isAnimating, setIsAnimating] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState<'spanish' | 'english' | 'portuguese'>('spanish');
  const containerRef = useRef<HTMLDivElement>(null);
  const speechRef = useRef<SpeechSynthesisUtterance | null>(null);
  const shouldAutoplay = useRef(false);

  const processBlockMath = (text: string): string => {
    if (!text || typeof text !== 'string') return '';
    return text.replace(/\\\[([\s\S]*?)\\\]/g, (_, math) => `\n$$${math}$$\n`);
  };

  const processInlineMath = (text: string): string => {
    if (!text || typeof text !== 'string') return '';
    return text.replace(/\\\((.*?)\\\)/g, (_, math) => `$${math}$`);
  };

  const katexOptions: KatexOptions = {
    strict: false,
    throwOnError: false,
    displayMode: true,
    trust: true,
  };

  useEffect(() => {
    if (!content || typeof content !== 'string') {
      setDisplayedContent('');
      setIsAnimating(false);
      return;
    }

    if (!isReceived) {
      const processedContent = processInlineMath(processBlockMath(content));
      setDisplayedContent(processedContent);
      setIsAnimating(false);
      return;
    }

    const processedContent = processInlineMath(processBlockMath(content));
    const cleanContent = processedContent.replace(/undefined/g, '');
    const words = cleanContent.split(/(\s+)/).filter(word => 
      word !== undefined && 
      word !== null && 
      word !== 'undefined' && 
      typeof word === 'string'
    );

    let currentIndex = 0;
    let timeoutId: NodeJS.Timeout;

    const animateText = () => {
      if (currentIndex < words.length) {
        const word = words[currentIndex];
        if (word && typeof word === 'string') {
          setDisplayedContent(prev => prev + word);
        }
        currentIndex++;
        timeoutId = setTimeout(animateText, 70);

        if (containerRef.current) {
          const messageContainer = containerRef.current.closest('.message-container');
          if (messageContainer) {
            requestAnimationFrame(() => {
              messageContainer.scrollTop = messageContainer.scrollHeight;
            });
          }
        }
      } else {
        setIsAnimating(false);
        shouldAutoplay.current = true;
      }
    };

    setDisplayedContent('');
    setIsAnimating(true);
    currentIndex = 0;
    animateText();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      setIsAnimating(false);
    };
  }, [content, isReceived]);

  useEffect(() => {
    if (!isAnimating && shouldAutoplay.current && isReceived && displayedContent) {
      if (displayedContent.toLowerCase().includes('hola')) {
        handleSpeak();
      }
      shouldAutoplay.current = false;
    }
  }, [isAnimating, displayedContent, isReceived]);

  useEffect(() => {
    const initVoices = () => {
      const synth = window.speechSynthesis;
      synth.getVoices();
    };

    if (typeof window !== 'undefined') {
      initVoices();
      window.speechSynthesis.onvoiceschanged = initVoices;
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.speechSynthesis.onvoiceschanged = null;
        window.speechSynthesis.cancel();
      }
    };
  }, []);

  const findBestVoiceMatch = (voiceConfig: typeof VOICE_CONFIGS.spanish | typeof VOICE_CONFIGS.english) => {
    const availableVoices = window.speechSynthesis.getVoices();
    
    let voice = availableVoices.find(v => v.name === voiceConfig.name);
    
    if (!voice) {
      for (const backupName of voiceConfig.backupNames) {
        voice = availableVoices.find(v => 
          v.name.toLowerCase().includes(backupName.toLowerCase()) && 
          v.lang.startsWith(voiceConfig.lang.split('-')[0])
        );
        if (voice) break;
      }
    }
    
    if (!voice) {
      voice = availableVoices.find(v => 
        v.lang.startsWith(voiceConfig.lang.split('-')[0]) && 
        v.name.toLowerCase().includes('female')
      );
    }
    
    if (!voice) {
      voice = availableVoices.find(v => 
        v.lang.startsWith(voiceConfig.lang.split('-')[0])
      );
    }
    
    return voice;
  };

  const handleSpeak = () => {
    if (!displayedContent) return;
    
    if (isPlaying) {
      window.speechSynthesis.cancel();
      setIsPlaying(false);
      return;
    }

    const voiceConfig = VOICE_CONFIGS[selectedVoice];
    speechRef.current = new SpeechSynthesisUtterance(displayedContent);
    
    const voice = findBestVoiceMatch(voiceConfig);
    if (voice) {
      speechRef.current.voice = voice;
      speechRef.current.lang = voiceConfig.lang;
      speechRef.current.pitch = voiceConfig.pitch;
      speechRef.current.rate = voiceConfig.rate;
      speechRef.current.volume = voiceConfig.volume;
    }

    speechRef.current.onend = () => setIsPlaying(false);
    
    window.speechSynthesis.speak(speechRef.current);
    setIsPlaying(true);
  };

  const toggleVoice = () => {
    setSelectedVoice(prev => prev === 'spanish' ? 'english' : prev === 'english' ? 'portuguese' : 'spanish');
    if (isPlaying) {
      window.speechSynthesis.cancel();
      setIsPlaying(false);
    }
  };

  const CustomCode: React.FC<CodeProps> = ({ 
    inline = false, 
    className, 
    children,
    ...props 
  }) => {
    const style = {
      display: 'block',
      maxWidth: '100%',
      overflow: 'auto',
      wordWrap: 'break-word' as const,
      whiteSpace: inline ? 'normal' as const : 'pre-wrap' as const
    };
    return (
      <code className={className} style={style} {...props}>
        {children}
      </code>
    );
  };

  const CustomPre: React.FC<PreProps> = ({ children }) => (
    <pre style={{ 
      maxWidth: '100%', 
      overflow: 'auto',
      marginBottom: '1rem'
    }}>
      {children}
    </pre>
  );

  return (
    <div className="relative">
      <div 
        ref={containerRef} 
        className="prose max-w-none message-scroll-container"
        style={{ 
          display: 'block',
          maxWidth: '100%',
          overflow: 'hidden',
          wordWrap: 'break-word',
          overflowWrap: 'break-word'
        }}
      >
        <ReactMarkdown
          remarkPlugins={[remarkMath]}
          rehypePlugins={[[rehypeKatex, katexOptions]]}
          components={{
            code: CustomCode as any,
            pre: CustomPre as any
          }}
        >
          {displayedContent || ''}
        </ReactMarkdown>
        {isAnimating && isReceived && displayedContent && (
          <div className="d-flex align-items-center gap-2">
            <Spinner animation="border" size="sm" />
          </div>
        )}
      </div>
      {isReceived && displayedContent && !isAnimating && (
        <div className="absolute top-2 right-2 flex gap-2">
          <button
            onClick={toggleVoice}
            className="p-1 rounded-full hover:bg-gray-100 transition-colors"
            style={{ 
              border: 'none',
              background: 'transparent',
              fontSize: '15px',
              cursor: 'pointer'
            }}
            title={`Current: ${selectedVoice === 'spanish' ? 'Español' : selectedVoice === 'english' ? 'English' : 'Português'}`}
          >
            {selectedVoice === 'spanish' ? '🇪🇸' : selectedVoice === 'english' ? '🇺🇸' : '🇧🇷'}
          </button>
          <button
            onClick={handleSpeak}
            className="p-1 rounded-full hover:bg-gray-100 transition-colors"
            style={{ 
              border: 'none',
              background: 'transparent',
              fontSize: '14px',
              cursor: 'pointer'
            }}
            title={isPlaying ? "Pause audio" : "Play audio"}
          >
            <FontAwesomeIcon 
              icon={isPlaying ? faPause : faVolumeUp} 
              className="text-gray-500 hover:text-gray-700"
            />
          </button>
        </div>
      )}
    </div>
  );
};

const Message: React.FC<MessageProps> = ({ message, user, showActions = true }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { lightboxProps, openLightbox } = useLightbox(
    message.attachments?.images || []
  );

  const showCollapseButton = message.type === 'received';

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="d-flex chat-message">
      <div
        className={classNames('d-flex flex-1', {
          'justify-content-end': message.type === 'sent'
        })}
      >
        <div
          className={classNames('', {
            'w-xxl-75': showActions
          })}
          style={{ maxWidth: '100%' }}
        >
          {showCollapseButton && (
            <div className="d-flex justify-content-start mb-1">
              <button 
                onClick={toggleAccordion}
                className={classNames(
                  'border-0 bg-transparent p-1 rounded-circle d-flex align-items-center justify-content-center',
                  'hover:bg-light transition-colors',
                  'text-body-tertiary',
                  'cursor-pointer'
                )}
                style={{ 
                  width: '20px', 
                  height: '20px',
                  fontSize: '12px'
                }}
              >
                <FontAwesomeIcon 
                  icon={isExpanded ? faChevronUp : faChevronDown}
                  className="text-gray-500 hover:text-gray-700"
                />
              </button>
            </div>
          )}

          {(!showCollapseButton || isExpanded) && (
            <>
              <div
                className={classNames('d-flex hover-actions-trigger', {
                  'flex-end-center': message.type === 'sent'
                })}
              >
                {message.type === 'received' && (
                  <div className='me-2'> </div>
                )}
                {message.type === 'sent' && showActions && (
                  <MessageActionButtons actions={actions} variant="sent" />
                )}
                <div
                  className={classNames('me-2', {
                    received: message.type === 'received'
                  })}
                  style={{ maxWidth: '100%', flex: '1 1 auto' }}
                >
                  <div
                    className={classNames('mb-0', {
                      'sent-message-content': message.type === 'sent',
                      'received-message-content border border-translucent':
                        message.type === 'received',
                      attachments:
                        Boolean(message.attachments?.images?.length) &&
                        !message.message
                    })}
                    style={{ maxWidth: '100%' }}
                  >
                    {message.message && (
                      <div className="mb-0 message-container" style={{
                        maxWidth: '100%',
                        overflow: 'hidden'
                      }}>
                        <MessageContent 
                          content={message.message} 
                          isReceived={message.type === 'received'}
                        />
                      </div>
                    )}
                    {message.attachments?.images && (
                      <MessageAttachments
                        attachments={message.attachments.images}
                        openLightbox={openLightbox}
                      />
                    )}
                    {message.attachments?.file && (
                      <AttachmentPreview
                        attachment={message.attachments.file}
                        variant={
                          message.type === 'received' ? 'primary' : 'secondary'
                        }
                      />
                    )}
                  </div>
                </div>
                {message.type === 'received' && showActions && (
                  <MessageActionButtons
                    actions={actions.slice(1)}
                    variant="received"
                  />
                )}
              </div>
              <div
                className={classNames('d-flex gap-1 fs-10', {
                  'ms-7': message.type === 'received',
                  'justify-content-end': message.type === 'sent'
                })}
              >
                <p className="mb-0 text-body-tertiary text-opacity-85 fw-semibold">
                  {message.time}
                </p>
                {message.readAt && (
                  <FontAwesomeIcon icon={faCheckDouble} className="text-success" />
                )}
              </div>
              <Lightbox {...lightboxProps} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;



/* @v3.2 - speech completo default ******************************************
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Avatar from 'components/base/Avatar';
import Lightbox from 'components/base/LightBox';
import { Message as MessageType, User, actions } from 'data/hazbot/chat';
import useLightbox from 'hooks/useLightbox';
import MessageActionButtons from './MessageActionButtons';
import MessageAttachments from './MessageAttachments';
import AttachmentPreview from 'components/common/AttachmentPreview';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { KatexOptions } from 'katex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCheckDouble, 
  faChevronDown, 
  faChevronUp,
  faVolumeUp,
  faPause
} from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';

const VOICE_CONFIGS = {
  spanish: {
    name: 'Microsoft Helena - Spanish (Spain)',
    backupNames: [
      'Helena',
      'Microsoft Helena',
      'Google español',
      'Paulina',
      'Monica'
    ],
    lang: 'es-MX',
    pitch: 1.1,
    rate: 0.9,
    volume: 0.95
  },
  english: {
    name: 'Microsoft Samantha',
    backupNames: [
      'Samantha',
      'Alex',
      'Karen',
      'Victoria',
      'Google US English Female'
    ],
    lang: 'en-US',
    pitch: 1.05,
    rate: 0.9,
    volume: 0.95
  },
  portuguese: {
    name: 'Microsoft Maria - Portuguese (Brazil)',
    backupNames: [
      'Maria',
      'Microsoft Maria',
      'Google português',
      'Luciana',
      'Joana'
    ],
    lang: 'pt-BR',
    pitch: 1.1,
    rate: 0.9,
    volume: 0.95
  }
};

interface CodeProps {
  inline?: boolean;
  className?: string;
  children: React.ReactNode;
  node?: any;
}

interface PreProps {
  children: React.ReactNode;
}

interface MessageProps {
  message: MessageType;
  user: User;
  showActions?: boolean;
}

const MessageContent: React.FC<{ content: string; isReceived: boolean }> = ({ content, isReceived }) => {
  const [displayedContent, setDisplayedContent] = useState('');
  const [isAnimating, setIsAnimating] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState<'spanish' | 'english' | 'portuguese'>('spanish');
  const containerRef = useRef<HTMLDivElement>(null);
  const speechRef = useRef<SpeechSynthesisUtterance | null>(null);
  const shouldAutoplay = useRef(false);

  const processBlockMath = (text: string): string => {
    if (!text || typeof text !== 'string') return '';
    return text.replace(/\\\[([\s\S]*?)\\\]/g, (_, math) => `\n$$${math}$$\n`);
  };

  const processInlineMath = (text: string): string => {
    if (!text || typeof text !== 'string') return '';
    return text.replace(/\\\((.*?)\\\)/g, (_, math) => `$${math}$`);
  };

  const katexOptions: KatexOptions = {
    strict: false,
    throwOnError: false,
    displayMode: true,
    trust: true,
  };

  useEffect(() => {
    if (!content || typeof content !== 'string') {
      setDisplayedContent('');
      setIsAnimating(false);
      return;
    }

    if (!isReceived) {
      const processedContent = processInlineMath(processBlockMath(content));
      setDisplayedContent(processedContent);
      setIsAnimating(false);
      return;
    }

    const processedContent = processInlineMath(processBlockMath(content));
    const cleanContent = processedContent.replace(/undefined/g, '');
    const words = cleanContent.split(/(\s+)/).filter(word => 
      word !== undefined && 
      word !== null && 
      word !== 'undefined' && 
      typeof word === 'string'
    );

    let currentIndex = 0;
    let timeoutId: NodeJS.Timeout;

    const animateText = () => {
      if (currentIndex < words.length) {
        const word = words[currentIndex];
        if (word && typeof word === 'string') {
          setDisplayedContent(prev => prev + word);
        }
        currentIndex++;
        timeoutId = setTimeout(animateText, 70);

        if (containerRef.current) {
          const messageContainer = containerRef.current.closest('.message-container');
          if (messageContainer) {
            requestAnimationFrame(() => {
              messageContainer.scrollTop = messageContainer.scrollHeight;
            });
          }
        }
      } else {
        setIsAnimating(false);
        shouldAutoplay.current = true;
      }
    };

    setDisplayedContent('');
    setIsAnimating(true);
    currentIndex = 0;
    animateText();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      setIsAnimating(false);
    };
  }, [content, isReceived]);

  useEffect(() => {
    if (!isAnimating && shouldAutoplay.current && isReceived && displayedContent) {
      handleSpeak();
      shouldAutoplay.current = false;
    }
  }, [isAnimating, displayedContent, isReceived]);

  useEffect(() => {
    const initVoices = () => {
      const synth = window.speechSynthesis;
      synth.getVoices();
    };

    if (typeof window !== 'undefined') {
      initVoices();
      window.speechSynthesis.onvoiceschanged = initVoices;
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.speechSynthesis.onvoiceschanged = null;
        window.speechSynthesis.cancel();
      }
    };
  }, []);

  const findBestVoiceMatch = (voiceConfig: typeof VOICE_CONFIGS.spanish | typeof VOICE_CONFIGS.english) => {
    const availableVoices = window.speechSynthesis.getVoices();
    
    // First try exact match
    let voice = availableVoices.find(v => v.name === voiceConfig.name);
    
    // Then try backup names
    if (!voice) {
      for (const backupName of voiceConfig.backupNames) {
        voice = availableVoices.find(v => 
          v.name.toLowerCase().includes(backupName.toLowerCase()) && 
          v.lang.startsWith(voiceConfig.lang.split('-')[0])
        );
        if (voice) break;
      }
    }
    
    // Fallback to any female voice in the correct language
    if (!voice) {
      voice = availableVoices.find(v => 
        v.lang.startsWith(voiceConfig.lang.split('-')[0]) && 
        v.name.toLowerCase().includes('female')
      );
    }
    
    // Last resort: any voice in the correct language
    if (!voice) {
      voice = availableVoices.find(v => 
        v.lang.startsWith(voiceConfig.lang.split('-')[0])
      );
    }
    
    return voice;
  };

  const handleSpeak = () => {
    if (!displayedContent) return;
    
    if (isPlaying) {
      window.speechSynthesis.cancel();
      setIsPlaying(false);
      return;
    }

    const voiceConfig = VOICE_CONFIGS[selectedVoice];
    speechRef.current = new SpeechSynthesisUtterance(displayedContent);
    
    const voice = findBestVoiceMatch(voiceConfig);
    if (voice) {
      speechRef.current.voice = voice;
      speechRef.current.lang = voiceConfig.lang;
      speechRef.current.pitch = voiceConfig.pitch;
      speechRef.current.rate = voiceConfig.rate;
      speechRef.current.volume = voiceConfig.volume;
    }

    speechRef.current.onend = () => setIsPlaying(false);
    
    window.speechSynthesis.speak(speechRef.current);
    setIsPlaying(true);
  };

  const toggleVoice = () => {
    setSelectedVoice(prev => prev === 'spanish' ? 'english' : prev === 'english' ? 'portuguese' : 'spanish');
    if (isPlaying) {
      window.speechSynthesis.cancel();
      setIsPlaying(false);
    }
  };

  const CustomCode: React.FC<CodeProps> = ({ 
    inline = false, 
    className, 
    children,
    ...props 
  }) => {
    const style = {
      display: 'block',
      maxWidth: '100%',
      overflow: 'auto',
      wordWrap: 'break-word' as const,
      whiteSpace: inline ? 'normal' as const : 'pre-wrap' as const
    };
    return (
      <code className={className} style={style} {...props}>
        {children}
      </code>
    );
  };

  const CustomPre: React.FC<PreProps> = ({ children }) => (
    <pre style={{ 
      maxWidth: '100%', 
      overflow: 'auto',
      marginBottom: '1rem'
    }}>
      {children}
    </pre>
  );

  return (
    <div className="relative">
      <div 
        ref={containerRef} 
        className="prose max-w-none message-scroll-container"
        style={{ 
          display: 'block',
          maxWidth: '100%',
          overflow: 'hidden',
          wordWrap: 'break-word',
          overflowWrap: 'break-word'
        }}
      >
        <ReactMarkdown
          remarkPlugins={[remarkMath]}
          rehypePlugins={[[rehypeKatex, katexOptions]]}
          components={{
            code: CustomCode as any,
            pre: CustomPre as any
          }}
        >
          {displayedContent || ''}
        </ReactMarkdown>
        {isAnimating && isReceived && displayedContent && (
          <div className="d-flex align-items-center gap-2">
            <Spinner animation="border" size="sm" />
          </div>
        )}
      </div>
      {isReceived && displayedContent && !isAnimating && (
        <div className="absolute top-2 right-2 flex gap-2">
          <button
            onClick={toggleVoice}
            className="p-1 rounded-full hover:bg-gray-100 transition-colors"
            style={{ 
              border: 'none',
              background: 'transparent',
              fontSize: '15px',
              cursor: 'pointer'
            }}
            title={`Current: ${selectedVoice === 'spanish' ? 'Español' : selectedVoice === 'english' ? 'English' : 'Português'}`}
          >
            {selectedVoice === 'spanish' ? '🇪🇸' : selectedVoice === 'english' ? '🇺🇸' : '🇧🇷'}
          </button>
          <button
            onClick={handleSpeak}
            className="p-1 rounded-full hover:bg-gray-100 transition-colors"
            style={{ 
              border: 'none',
              background: 'transparent',
              fontSize: '14px',
              cursor: 'pointer'
            }}
            title={isPlaying ? "Pause audio" : "Play audio"}
          >
            <FontAwesomeIcon 
              icon={isPlaying ? faPause : faVolumeUp} 
              className="text-gray-500 hover:text-gray-700"
            />
          </button>
        </div>
      )}
    </div>
  );
};

const Message: React.FC<MessageProps> = ({ message, user, showActions = true }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { lightboxProps, openLightbox } = useLightbox(
    message.attachments?.images || []
  );

  const showCollapseButton = message.type === 'received';

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="d-flex chat-message">
      <div
        className={classNames('d-flex flex-1', {
          'justify-content-end': message.type === 'sent'
        })}
      >
        <div
          className={classNames('', {
            'w-xxl-75': showActions
          })}
          style={{ maxWidth: '100%' }}
        >
          {showCollapseButton && (
            <div className="d-flex justify-content-start mb-1">
              <button 
                onClick={toggleAccordion}
                className={classNames(
                  'border-0 bg-transparent p-1 rounded-circle d-flex align-items-center justify-content-center',
                  'hover:bg-light transition-colors',
                  'text-body-tertiary',
                  'cursor-pointer'
                )}
                style={{ 
                  width: '20px', 
                  height: '20px',
                  fontSize: '12px'
                }}
              >
                <FontAwesomeIcon 
                  icon={isExpanded ? faChevronUp : faChevronDown}
                  className="text-gray-500 hover:text-gray-700"
                />
              </button>
            </div>
          )}

          {(!showCollapseButton || isExpanded) && (
            <>
              <div
                className={classNames('d-flex hover-actions-trigger', {
                  'flex-end-center': message.type === 'sent'
                })}
              >
                {message.type === 'received' && (
                  <div className='me-2'> </div>
                )}
                {message.type === 'sent' && showActions && (
                  <MessageActionButtons actions={actions} variant="sent" />
                )}
                <div
                  className={classNames('me-2', {
                    received: message.type === 'received'
                  })}
                  style={{ maxWidth: '100%', flex: '1 1 auto' }}
                >
                  <div
                    className={classNames('mb-0', {
                      'sent-message-content': message.type === 'sent',
                      'received-message-content border border-translucent':
                        message.type === 'received',
                      attachments:
                        Boolean(message.attachments?.images?.length) &&
                        !message.message
                    })}
                    style={{ maxWidth: '100%' }}
                  >
                    {message.message && (
                      <div className="mb-0 message-container" style={{
                        maxWidth: '100%',
                        overflow: 'hidden'
                      }}>
                        <MessageContent 
                          content={message.message} 
                          isReceived={message.type === 'received'}
                        />
                      </div>
                    )}
                    {message.attachments?.images && (
                      <MessageAttachments
                        attachments={message.attachments.images}
                        openLightbox={openLightbox}
                      />
                    )}
                    {message.attachments?.file && (
                      <AttachmentPreview
                        attachment={message.attachments.file}
                        variant={
                          message.type === 'received' ? 'primary' : 'secondary'
                        }
                      />
                    )}
                  </div>
                </div>
                {message.type === 'received' && showActions && (
                  <MessageActionButtons
                    actions={actions.slice(1)}
                    variant="received"
                  />
                )}
              </div>
              <div
                className={classNames('d-flex gap-1 fs-10', {
                  'ms-7': message.type === 'received',
                  'justify-content-end': message.type === 'sent'
                })}
              >
                <p className="mb-0 text-body-tertiary text-opacity-85 fw-semibold">
                  {message.time}
                </p>
                {message.readAt && (
                  <FontAwesomeIcon icon={faCheckDouble} className="text-success" />
                )}
              </div>
              <Lightbox {...lightboxProps} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;



/* @v3.1 - se agrega audio en respuestas del bot (icono play) 3 langs {esp/eng/ptbr} 
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Avatar from 'components/base/Avatar';
import Lightbox from 'components/base/LightBox';
import { Message as MessageType, User, actions } from 'data/hazbot/chat';
import useLightbox from 'hooks/useLightbox';
import MessageActionButtons from './MessageActionButtons';
import MessageAttachments from './MessageAttachments';
import AttachmentPreview from 'components/common/AttachmentPreview';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { KatexOptions } from 'katex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCheckDouble, 
  faChevronDown, 
  faChevronUp,
  faVolumeUp,
  faPause
} from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';

const VOICE_CONFIGS = {
  spanish: {
    name: 'Microsoft Helena - Spanish (Spain)',
    backupNames: [
      'Helena',
      'Microsoft Helena',
      'Google español',
      'Paulina',
      'Monica'
    ],
    lang: 'es-MX',
    pitch: 1.1,
    rate: 0.9,
    volume: 0.95
  },
  english: {
    name: 'Microsoft Samantha',
    backupNames: [
      'Samantha',
      'Alex',
      'Karen',
      'Victoria',
      'Google US English Female'
    ],
    lang: 'en-US',
    pitch: 1.05,
    rate: 0.9,
    volume: 0.95
  },
  portuguese: {
    name: 'Microsoft Maria - Portuguese (Brazil)',
    backupNames: [
      'Maria',
      'Microsoft Maria',
      'Google português',
      'Luciana',
      'Joana'
    ],
    lang: 'pt-BR',
    pitch: 1.1,
    rate: 0.9,
    volume: 0.95
  }
};

interface CodeProps {
  inline?: boolean;
  className?: string;
  children: React.ReactNode;
  node?: any;
}

interface PreProps {
  children: React.ReactNode;
}

interface MessageProps {
  message: MessageType;
  user: User;
  showActions?: boolean;
}

const MessageContent: React.FC<{ content: string; isReceived: boolean }> = ({ content, isReceived }) => {
  const [displayedContent, setDisplayedContent] = useState('');
  const [isAnimating, setIsAnimating] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState<'spanish' | 'english' | 'portuguese'>('spanish');
  const containerRef = useRef<HTMLDivElement>(null);
  const speechRef = useRef<SpeechSynthesisUtterance | null>(null);

  const processBlockMath = (text: string): string => {
    if (!text || typeof text !== 'string') return '';
    return text.replace(/\\\[([\s\S]*?)\\\]/g, (_, math) => `\n$$${math}$$\n`);
  };

  const processInlineMath = (text: string): string => {
    if (!text || typeof text !== 'string') return '';
    return text.replace(/\\\((.*?)\\\)/g, (_, math) => `$${math}$`);
  };

  const katexOptions: KatexOptions = {
    strict: false,
    throwOnError: false,
    displayMode: true,
    trust: true,
  };

  useEffect(() => {
    if (!content || typeof content !== 'string') {
      setDisplayedContent('');
      setIsAnimating(false);
      return;
    }

    if (!isReceived) {
      const processedContent = processInlineMath(processBlockMath(content));
      setDisplayedContent(processedContent);
      setIsAnimating(false);
      return;
    }

    const processedContent = processInlineMath(processBlockMath(content));
    const cleanContent = processedContent.replace(/undefined/g, '');
    const words = cleanContent.split(/(\s+)/).filter(word => 
      word !== undefined && 
      word !== null && 
      word !== 'undefined' && 
      typeof word === 'string'
    );

    let currentIndex = 0;
    let timeoutId: NodeJS.Timeout;

    const animateText = () => {
      if (currentIndex < words.length) {
        const word = words[currentIndex];
        if (word && typeof word === 'string') {
          setDisplayedContent(prev => prev + word);
        }
        currentIndex++;
        timeoutId = setTimeout(animateText, 70);

        if (containerRef.current) {
          const messageContainer = containerRef.current.closest('.message-container');
          if (messageContainer) {
            requestAnimationFrame(() => {
              messageContainer.scrollTop = messageContainer.scrollHeight;
            });
          }
        }
      } else {
        setIsAnimating(false);
      }
    };

    setDisplayedContent('');
    setIsAnimating(true);
    currentIndex = 0;
    animateText();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      setIsAnimating(false);
    };
  }, [content, isReceived]);

  useEffect(() => {
    const initVoices = () => {
      const synth = window.speechSynthesis;
      synth.getVoices();
    };

    if (typeof window !== 'undefined') {
      initVoices();
      window.speechSynthesis.onvoiceschanged = initVoices;
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.speechSynthesis.onvoiceschanged = null;
      }
    };
  }, []);

  const findBestVoiceMatch = (voiceConfig: typeof VOICE_CONFIGS.spanish | typeof VOICE_CONFIGS.english) => {
    const availableVoices = window.speechSynthesis.getVoices();
    
    // First try exact match
    let voice = availableVoices.find(v => v.name === voiceConfig.name);
    
    // Then try backup names
    if (!voice) {
      for (const backupName of voiceConfig.backupNames) {
        voice = availableVoices.find(v => 
          v.name.toLowerCase().includes(backupName.toLowerCase()) && 
          v.lang.startsWith(voiceConfig.lang.split('-')[0])
        );
        if (voice) break;
      }
    }
    
    // Fallback to any female voice in the correct language
    if (!voice) {
      voice = availableVoices.find(v => 
        v.lang.startsWith(voiceConfig.lang.split('-')[0]) && 
        v.name.toLowerCase().includes('female')
      );
    }
    
    // Last resort: any voice in the correct language
    if (!voice) {
      voice = availableVoices.find(v => 
        v.lang.startsWith(voiceConfig.lang.split('-')[0])
      );
    }
    
    return voice;
  };

  const handleSpeak = () => {
    if (!displayedContent) return;
    
    if (isPlaying) {
      window.speechSynthesis.cancel();
      setIsPlaying(false);
      return;
    }

    const voiceConfig = VOICE_CONFIGS[selectedVoice];
    speechRef.current = new SpeechSynthesisUtterance(displayedContent);
    
    const voice = findBestVoiceMatch(voiceConfig);
    if (voice) {
      speechRef.current.voice = voice;
      speechRef.current.lang = voiceConfig.lang;
      speechRef.current.pitch = voiceConfig.pitch;
      speechRef.current.rate = voiceConfig.rate;
      speechRef.current.volume = voiceConfig.volume;
    }

    speechRef.current.onend = () => setIsPlaying(false);
    
    window.speechSynthesis.speak(speechRef.current);
    setIsPlaying(true);
  };

  const toggleVoice = () => {
    setSelectedVoice(prev => prev === 'spanish' ? 'english' : prev === 'english' ? 'portuguese' : 'spanish');
    if (isPlaying) {
      window.speechSynthesis.cancel();
      setIsPlaying(false);
    }
  };

  const CustomCode: React.FC<CodeProps> = ({ 
    inline = false, 
    className, 
    children,
    ...props 
  }) => {
    const style = {
      display: 'block',
      maxWidth: '100%',
      overflow: 'auto',
      wordWrap: 'break-word' as const,
      whiteSpace: inline ? 'normal' as const : 'pre-wrap' as const
    };
    return (
      <code className={className} style={style} {...props}>
        {children}
      </code>
    );
  };

  const CustomPre: React.FC<PreProps> = ({ children }) => (
    <pre style={{ 
      maxWidth: '100%', 
      overflow: 'auto',
      marginBottom: '1rem'
    }}>
      {children}
    </pre>
  );

  return (
    <div className="relative">
      <div 
        ref={containerRef} 
        className="prose max-w-none message-scroll-container"
        style={{ 
          display: 'block',
          maxWidth: '100%',
          overflow: 'hidden',
          wordWrap: 'break-word',
          overflowWrap: 'break-word'
        }}
      >
        <ReactMarkdown
          remarkPlugins={[remarkMath]}
          rehypePlugins={[[rehypeKatex, katexOptions]]}
          components={{
            code: CustomCode as any,
            pre: CustomPre as any
          }}
        >
          {displayedContent || ''}
        </ReactMarkdown>
        {isAnimating && isReceived && displayedContent && (
          <div className="d-flex align-items-center gap-2">
            <Spinner animation="border" size="sm" />
          </div>
        )}
      </div>
      {isReceived && displayedContent && !isAnimating && (
        <div className="absolute top-2 right-2 flex gap-2">
          <button
            onClick={toggleVoice}
            className="p-1 rounded-full hover:bg-gray-100 transition-colors"
            style={{ 
              border: 'none',
              background: 'transparent',
              fontSize: '15px',
              cursor: 'pointer'
            }}
            title={`Current: ${selectedVoice === 'spanish' ? 'Español' : selectedVoice === 'english' ? 'English' : 'Português'}`}
          >
            {selectedVoice === 'spanish' ? '🇪🇸' : selectedVoice === 'english' ? '🇺🇸' : '🇧🇷'}
          </button>
          <button
            onClick={handleSpeak}
            className="p-1 rounded-full hover:bg-gray-100 transition-colors"
            style={{ 
              border: 'none',
              background: 'transparent',
              fontSize: '14px',
              cursor: 'pointer'
            }}
            title={isPlaying ? "Pause audio" : "Play audio"}
          >
            <FontAwesomeIcon 
              icon={isPlaying ? faPause : faVolumeUp} 
              className="text-gray-500 hover:text-gray-700"
            />
          </button>
        </div>
      )}
    </div>
  );
};

const Message: React.FC<MessageProps> = ({ message, user, showActions = true }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { lightboxProps, openLightbox } = useLightbox(
    message.attachments?.images || []
  );

  const showCollapseButton = message.type === 'received';

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="d-flex chat-message">
      <div
        className={classNames('d-flex flex-1', {
          'justify-content-end': message.type === 'sent'
        })}
      >
        <div
          className={classNames('', {
            'w-xxl-75': showActions
          })}
          style={{ maxWidth: '100%' }}
        >
          {showCollapseButton && (
            <div className="d-flex justify-content-start mb-1">
              <button 
                onClick={toggleAccordion}
                className={classNames(
                  'border-0 bg-transparent p-1 rounded-circle d-flex align-items-center justify-content-center',
                  'hover:bg-light transition-colors',
                  'text-body-tertiary',
                  'cursor-pointer'
                )}
                style={{ 
                  width: '20px', 
                  height: '20px',
                  fontSize: '12px'
                }}
              >
                <FontAwesomeIcon 
                  icon={isExpanded ? faChevronUp : faChevronDown}
                  className="text-gray-500 hover:text-gray-700"
                />
              </button>
            </div>
          )}

          {(!showCollapseButton || isExpanded) && (
            <>
              <div
                className={classNames('d-flex hover-actions-trigger', {
                  'flex-end-center': message.type === 'sent'
                })}
              >
                {message.type === 'received' && (
                  <div className='me-2'> </div>
                )}
                {message.type === 'sent' && showActions && (
                  <MessageActionButtons actions={actions} variant="sent" />
                )}
                <div
                  className={classNames('me-2', {
                    received: message.type === 'received'
                  })}
                  style={{ maxWidth: '100%', flex: '1 1 auto' }}
                >
                  <div
                    className={classNames('mb-0', {
                      'sent-message-content': message.type === 'sent',
                      'received-message-content border border-translucent':
                        message.type === 'received',
                      attachments:
                        Boolean(message.attachments?.images?.length) &&
                        !message.message
                    })}
                    style={{ maxWidth: '100%' }}
                  >
                    {message.message && (
                      <div className="mb-0 message-container" style={{
                        maxWidth: '100%',
                        overflow: 'hidden'
                      }}>
                        <MessageContent 
                          content={message.message} 
                          isReceived={message.type === 'received'}
                        />
                      </div>
                    )}
                    {message.attachments?.images && (
                      <MessageAttachments
                        attachments={message.attachments.images}
                        openLightbox={openLightbox}
                      />
                    )}
                    {message.attachments?.file && (
                      <AttachmentPreview
                        attachment={message.attachments.file}
                        variant={
                          message.type === 'received' ? 'primary' : 'secondary'
                        }
                      />
                    )}
                  </div>
                </div>
                {message.type === 'received' && showActions && (
                  <MessageActionButtons
                    actions={actions.slice(1)}
                    variant="received"
                  />
                )}
              </div>
              <div
                className={classNames('d-flex gap-1 fs-10', {
                  'ms-7': message.type === 'received',
                  'justify-content-end': message.type === 'sent'
                })}
              >
                <p className="mb-0 text-body-tertiary text-opacity-85 fw-semibold">
                  {message.time}
                </p>
                {message.readAt && (
                  <FontAwesomeIcon icon={faCheckDouble} className="text-success" />
                )}
              </div>
              <Lightbox {...lightboxProps} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;



/* @v3.0 - modificación burbujas texto y ajustes de contenedores div 
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Avatar from 'components/base/Avatar';
import Lightbox from 'components/base/LightBox';
import { Message as MessageType, User, actions } from 'data/hazbot/chat';
import useLightbox from 'hooks/useLightbox';
import MessageActionButtons from './MessageActionButtons';
import MessageAttachments from './MessageAttachments';
import AttachmentPreview from 'components/common/AttachmentPreview';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { KatexOptions } from 'katex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCheckDouble, 
  faChevronDown, 
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';

interface CodeProps {
  inline?: boolean;
  className?: string;
  children: React.ReactNode;
  node?: any;
}

interface PreProps {
  children: React.ReactNode;
}

interface MessageProps {
  message: MessageType;
  user: User;
  showActions?: boolean;
}

const MessageContent: React.FC<{ content: string; isReceived: boolean }> = ({ content, isReceived }) => {
  const [displayedContent, setDisplayedContent] = useState('');
  const [isAnimating, setIsAnimating] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);

  const processBlockMath = (text: string): string => {
    if (!text || typeof text !== 'string') return '';
    return text.replace(/\\\[([\s\S]*?)\\\]/g, (_, math) => `\n$$${math}$$\n`);
  };

  const processInlineMath = (text: string): string => {
    if (!text || typeof text !== 'string') return '';
    return text.replace(/\\\((.*?)\\\)/g, (_, math) => `$${math}$`);
  };

  const katexOptions: KatexOptions = {
    strict: false,
    throwOnError: false,
    displayMode: true,
    trust: true,
  };

  useEffect(() => {
    if (!content || typeof content !== 'string') {
      setDisplayedContent('');
      setIsAnimating(false);
      return;
    }

    if (!isReceived) {
      const processedContent = processInlineMath(processBlockMath(content));
      setDisplayedContent(processedContent);
      setIsAnimating(false);
      return;
    }

    const processedContent = processInlineMath(processBlockMath(content));
    const cleanContent = processedContent.replace(/undefined/g, '');
    const words = cleanContent.split(/(\s+)/).filter(word => 
      word !== undefined && 
      word !== null && 
      word !== 'undefined' && 
      typeof word === 'string'
    );

    let currentIndex = 0;
    let timeoutId: NodeJS.Timeout;

    const animateText = () => {
      if (currentIndex < words.length) {
        const word = words[currentIndex];
        if (word && typeof word === 'string') {
          setDisplayedContent(prev => prev + word);
        }
        currentIndex++;
        timeoutId = setTimeout(animateText, 70);

        if (containerRef.current) {
          const messageContainer = containerRef.current.closest('.message-container');
          if (messageContainer) {
            requestAnimationFrame(() => {
              messageContainer.scrollTop = messageContainer.scrollHeight;
            });
          }
        }
      } else {
        setIsAnimating(false);
      }
    };

    setDisplayedContent('');
    setIsAnimating(true);
    currentIndex = 0;
    animateText();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      setIsAnimating(false);
    };
  }, [content, isReceived]);

  if (!displayedContent && !isAnimating) return null;

  const CustomCode: React.FC<CodeProps> = ({ 
    inline = false, 
    className, 
    children,
    ...props 
  }) => {
    const style = {
      display: 'block',
      maxWidth: '100%',
      overflow: 'auto',
      wordWrap: 'break-word' as const,
      whiteSpace: inline ? 'normal' as const : 'pre-wrap' as const
    };
    return (
      <code className={className} style={style} {...props}>
        {children}
      </code>
    );
  };

  const CustomPre: React.FC<PreProps> = ({ children }) => (
    <pre style={{ 
      maxWidth: '100%', 
      overflow: 'auto',
      marginBottom: '1rem'
    }}>
      {children}
    </pre>
  );

  return (
    <div 
      ref={containerRef} 
      className="prose max-w-none message-scroll-container"
      style={{ 
        display: 'block',
        maxWidth: '100%',
        overflow: 'hidden',
        wordWrap: 'break-word',
        overflowWrap: 'break-word'
      }}
    >
      <ReactMarkdown
        remarkPlugins={[remarkMath]}
        rehypePlugins={[[rehypeKatex, katexOptions]]}
        components={{
          code: CustomCode as any,
          pre: CustomPre as any
        }}
      >
        {displayedContent || ''}
      </ReactMarkdown>
      {isAnimating && isReceived && displayedContent && (
        <div className="d-flex align-items-center gap-2">
          <Spinner animation="border" size="sm" />
        </div>
      )}
    </div>
  );
};

const Message: React.FC<MessageProps> = ({ message, user, showActions = true }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { lightboxProps, openLightbox } = useLightbox(
    message.attachments?.images || []
  );

  const showCollapseButton = message.type === 'received';

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="d-flex chat-message">
      <div
        className={classNames('d-flex flex-1', {
          'justify-content-end': message.type === 'sent'
        })}
      >
        <div
          className={classNames('', { //w-100
            'w-xxl-75': showActions
          })}
          style={{ maxWidth: '100%' }}
        >
          {showCollapseButton && (
            <div className="d-flex justify-content-start mb-1">
              <button 
                onClick={toggleAccordion}
                className={classNames(
                  'border-0 bg-transparent p-1 rounded-circle d-flex align-items-center justify-content-center',
                  'hover:bg-light transition-colors',
                  'text-body-tertiary',
                  'cursor-pointer'
                )}
                style={{ 
                  width: '20px', 
                  height: '20px',
                  fontSize: '12px'
                }}
              >
                <FontAwesomeIcon 
                  icon={isExpanded ? faChevronUp : faChevronDown}
                  className="text-gray-500 hover:text-gray-700"
                />
              </button>
            </div>
          )}

          {(!showCollapseButton || isExpanded) && (
            <>
              <div
                className={classNames('d-flex hover-actions-trigger', {
                  'flex-end-center': message.type === 'sent'
                })}
              >
                {message.type === 'received' && (
                  <div className='me-2'> </div>
                )}
                {message.type === 'sent' && showActions && (
                  <MessageActionButtons actions={actions} variant="sent" />
                )}
                <div
                  className={classNames('me-2', {
                    received: message.type === 'received'
                  })}
                  style={{ maxWidth: '100%', flex: '1 1 auto' }}
                >
                  <div
                    className={classNames('mb-0', {
                      'sent-message-content': message.type === 'sent', //color caja enviados - after:: <-- revisar
                      'received-message-content border border-translucent':
                        message.type === 'received',
                      attachments:
                        Boolean(message.attachments?.images?.length) &&
                        !message.message
                    })}
                    style={{ maxWidth: '100%' }}
                  >
                    {message.message && (
                      <div className="mb-0 message-container" style={{
                        maxWidth: '100%',
                        overflow: 'hidden'
                      }}>
                        <MessageContent 
                          content={message.message} 
                          isReceived={message.type === 'received'}
                        />
                      </div>
                    )}
                    {message.attachments?.images && (
                      <MessageAttachments
                        attachments={message.attachments.images}
                        openLightbox={openLightbox}
                      />
                    )}
                    {message.attachments?.file && (
                      <AttachmentPreview
                        attachment={message.attachments.file}
                        variant={
                          message.type === 'received' ? 'primary' : 'secondary'
                        }
                      />
                    )}
                  </div>
                </div>
                {message.type === 'received' && showActions && (
                  <MessageActionButtons
                    actions={actions.slice(1)}
                    variant="received"
                  />
                )}
              </div>
              <div
                className={classNames('d-flex gap-1 fs-10', { //fondo fecha/hora
                  'ms-7': message.type === 'received',
                  'justify-content-end': message.type === 'sent'
                })}
              >
                <p className="mb-0 text-body-tertiary text-opacity-85 fw-semibold">
                  {message.time}
                </p>
                {message.readAt && (
                  <FontAwesomeIcon icon={faCheckDouble} className="text-success" />
                )}
              </div>
              <Lightbox {...lightboxProps} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;



/* @v2.0 
import React, { useState } from 'react';
import classNames from 'classnames';
import Avatar from 'components/base/Avatar';
import Lightbox from 'components/base/LightBox';
import { Message as MessageType, User, actions } from 'data/hazbot/chat';
import useLightbox from 'hooks/useLightbox';
import MessageActionButtons from './MessageActionButtons';
import MessageAttachments from './MessageAttachments';
import AttachmentPreview from 'components/common/AttachmentPreview';
import { ChevronDown, ChevronUp } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { KatexOptions } from 'katex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCheckDouble, 
  faChevronDown, 
  faChevronUp,
  // faCaretDown,
  // faCaretUp,
  // faAngleDown,
  // faAngleUp,
  // faCircleChevronDown,
  // faCircleChevronUp
} from '@fortawesome/free-solid-svg-icons';

interface MessageProps {
  message: MessageType;
  user: User;
  showActions?: boolean;
}

const MessageContent: React.FC<{ content: string }> = ({ content }) => {
  const processBlockMath = (text: string): string => {
    return text.replace(/\\\[([\s\S]*?)\\\]/g, (_, math) => {
      return `\n$$${math}$$\n`;
    });
  };

  const processInlineMath = (text: string): string => {
    return text.replace(/\\\((.*?)\\\)/g, (_, math) => {
      return `$${math}$`;
    });
  };

  const katexOptions: KatexOptions = {
    strict: false,
    throwOnError: false,
    displayMode: true,
    trust: true,
  };

  const processedContent = processInlineMath(processBlockMath(content));

  return (
    <div className="prose max-w-none">
      <ReactMarkdown
        remarkPlugins={[remarkMath]}
        rehypePlugins={[[rehypeKatex, katexOptions]]}
      >
        {processedContent}
      </ReactMarkdown>
    </div>
  );
};

const Message = ({ message, user, showActions = true }: MessageProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { lightboxProps, openLightbox } = useLightbox(
    message.attachments?.images || []
  );

  const showCollapseButton = message.type === 'received';

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="d-flex chat-message">
      <div
        className={classNames('d-flex flex-1', {
          'justify-content-end': message.type === 'sent'
        })}
      >
        <div
          className={classNames('w-100', {
            'w-xxl-75': showActions
          })}
        >
          {showCollapseButton && (
            <div className="d-flex justify-content-end mb-1">
              <button 
                onClick={toggleAccordion}
                className={classNames(
                  'border-0 bg-transparent p-1 rounded-circle d-flex align-items-center justify-content-center',
                  'hover:bg-light transition-colors',
                  'text-body-tertiary',
                  'cursor-pointer'
                )}
                style={{ 
                  width: '20px', 
                  height: '20px',
                  fontSize: '12px'
                }}
              >
                <FontAwesomeIcon 
                  icon={isExpanded ? faChevronUp : faChevronDown}
                  className="text-gray-500 hover:text-gray-700"
                />
              </button>
            </div>
          )}

          {(!showCollapseButton || isExpanded) && (
            <>
              <div
                className={classNames('d-flex hover-actions-trigger', {
                  'flex-end-center': message.type === 'sent'
                })}
              >
                {message.type === 'received' && (
                  <div className='me-2'></div>
                )}
                {message.type === 'sent' && showActions && (
                  <MessageActionButtons actions={actions} variant="sent" />
                )}
                <div
                  className={classNames('me-2', {
                    received: message.type === 'received'
                  })}
                >
                  <div
                    className={classNames('mb-1', {
                      'sent-message-content': message.type === 'sent',
                      'received-message-content border border-translucent':
                        message.type === 'received',
                      attachments:
                        Number(message.attachments?.images?.length) > 0 &&
                        !message.message
                    })}
                  >
                    {message.message && (
                      <div className="mb-0 message-container">
                        <MessageContent content={message.message} />
                      </div>
                    )}
                    {message.attachments?.images && (
                      <MessageAttachments
                        attachments={message.attachments.images}
                        openLightbox={openLightbox}
                      />
                    )}
                    {message.attachments?.file && (
                      <AttachmentPreview
                        attachment={message.attachments.file}
                        variant={
                          message.type === 'received' ? 'primary' : 'secondary'
                        }
                      />
                    )}
                  </div>
                </div>
                {message.type === 'received' && showActions && (
                  <MessageActionButtons
                    actions={actions.slice(1)}
                    variant="received"
                  />
                )}
              </div>
              <div
                className={classNames('d-flex gap-1 fs-10', {
                  'ms-7': message.type === 'received',
                  'justify-content-end': message.type === 'sent'
                })}
              >
                <p className="mb-0 text-body-tertiary text-opacity-85 fw-semibold">
                  {message.time}
                </p>
                {message.readAt && (
                  <FontAwesomeIcon icon={faCheckDouble} className="text-success" />
                )}
              </div>
              <Lightbox {...lightboxProps} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;
/* @v1.0
import React from 'react';
import classNames from 'classnames';
import Avatar from 'components/base/Avatar';
import Lightbox from 'components/base/LightBox';
import { Message as MessageType, User, actions } from 'data/hazbot/chat';
import useLightbox from 'hooks/useLightbox';
import MessageActionButtons from './MessageActionButtons';
import MessageAttachments from './MessageAttachments';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import AttachmentPreview from 'components/common/AttachmentPreview';

import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';

import 'katex/dist/katex.min.css';
import { KatexOptions } from 'katex';

interface MessageProps {
  message: MessageType;
  user: User;
  showActions?: boolean;
}

const MessageContent: React.FC<{ content: string }> = ({ content }) => {
  // Process block math with proper spacing
  const processBlockMath = (text: string): string => {
    return text.replace(/\\\[([\s\S]*?)\\\]/g, (_, math) => {
      return `\n$$${math}$$\n`;
    });
  };

  // Process inline math
  const processInlineMath = (text: string): string => {
    return text.replace(/\\\((.*?)\\\)/g, (_, math) => {
      return `$${math}$`;
    });
  };

  // Configure KaTeX options
  const katexOptions: KatexOptions = {
    strict: false,
    throwOnError: false,
    displayMode: true,
    trust: true,
  };

  // Process the content
  const processedContent = processInlineMath(processBlockMath(content));

  return (
    <div className="prose max-w-none">
      <ReactMarkdown
        remarkPlugins={[remarkMath]}
        rehypePlugins={[[rehypeKatex, katexOptions]]}
      >
        {processedContent}
      </ReactMarkdown>
    </div>
  );
};


const Message = ({ message, user, showActions = true }: MessageProps) => {
  const { lightboxProps, openLightbox } = useLightbox(
    message.attachments?.images || []
  );

  return (
    <div className="d-flex chat-message">
      <div
        className={classNames('d-flex flex-1', {
          'justify-content-end': message.type === 'sent'
        })}
      >
        <div
          className={classNames('w-100', {
            'w-xxl-75': showActions
          })}
        >
          <div
            className={classNames('d-flex hover-actions-trigger', {
              'flex-end-center': message.type === 'sent'
            })}
          >
            {message.type === 'received' && (
              /*<Avatar
                src={user.avatar}
                size="s"
                className="hide-mobile-avatar me-3 flex-shrink-0"
              />||||||<><div className='me-2'></div></>
            )}
            {message.type === 'sent' && showActions && (
              <MessageActionButtons actions={actions} variant="sent" />
            )}
            <div
              className={classNames('me-2', { // chat-message-content
                received: message.type === 'received'
              })}
            >
              <div
                className={classNames('mb-1', {
                  'sent-message-content': message.type === 'sent',
                  'received-message-content border border-translucent':
                    message.type === 'received',
                  attachments:
                    Number(message.attachments?.images?.length) > 0 &&
                    !message.message
                })}
              >
                {message.message && (
                  <div className="mb-0 message-container">
                    <MessageContent content={message.message} />
                  </div>
                )}
                {message.attachments?.images && (
                  <MessageAttachments
                    attachments={message.attachments.images}
                    openLightbox={openLightbox}
                  />
                )}
                {message.attachments?.file && (
                  <AttachmentPreview
                    attachment={message.attachments.file}
                    variant={
                      message.type === 'received' ? 'primary' : 'secondary'
                    }
                  />
                )}
              </div>
            </div>
            {message.type === 'received' && showActions && (
              <MessageActionButtons
                actions={actions.slice(1)}
                variant="received"
              />
            )}
          </div>
          <div
            className={classNames('d-flex gap-1 fs-10', {
              'ms-7': message.type === 'received',
              'justify-content-end': message.type === 'sent'
            })}
          >
            <p className="mb-0 text-body-tertiary text-opacity-85 fw-semibold">
              {message.time}
            </p>
            {message.readAt && (
              <FontAwesomeIcon icon={faCheckDouble} className="text-success" />
            )}
          </div>
          <Lightbox {...lightboxProps} />
        </div>
      </div>
    </div>
  );
};

export default Message;
*/