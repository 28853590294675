import React, { createContext, useContext } from 'react';
import { IUser } from 'data/models/user/IUser';

interface UserContextType {
  user: IUser | null;
  setUser: (user: IUser | null) => void;  // Añade esta línea
  isLoading: boolean;
  error: string | null;
  refetchUser: () => Promise<void>;
  isAuthenticated: boolean;
}

export const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserContext.Provider');
  }
  return context;
};

/* @v0.1 init 
import React, { createContext, useContext } from 'react';
import { IUser } from 'data/models/user/IUser';

interface UserContextType {
  user: IUser | null;
  isLoading: boolean;
  error: string | null;
  refetchUser: () => Promise<void>;
  isAuthenticated: boolean;
}

export const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserContext.Provider');
  }
  return context;
};
/* */